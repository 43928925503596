import { Auth } from "../../auth";
import { _t } from "../../localization";
import { Scene, Scene_Manager } from "../../scenes";
import { Friends_Menu } from "../../ui/friends_menu";
import { print_global_messages } from "../../ui/global_message";
import { User_Info_Popup } from "../../ui/popups/user_info_popup";
import Updater from "../../updater";
import { User, Users } from "../../users";

export class Users_Info_Scene extends Scene
{
    user?: User;

    constructor()
    {
        super('user');

        this.user_state = 'active';

        this.element.className = 'page-section';
        this.element.innerHTML = `
            <div class="personal-info-header" style="display: flex; margin-bottom: 20px">
                <img class="profile-picture" src=""/>
                <span class="name text-ellipsis"></span>
            </div>
            <hr/>
            <div class="actions-buttons flex-equal horizontal-rounded-content"></div>`;

        this._profile_picture_element = this.element.querySelector('.profile-picture') as HTMLImageElement;
        this._name_element = this.element.querySelector('.name') as HTMLSpanElement;

        this._init_tool_element();
    }

    async open(args?: {[key: string]: string})
    {
        await super.open(args);
        
        if( args && args.uid ){

            if( Auth.current_user && args.uid === Auth.current_user.uid ){
                await Scene_Manager.open('profile');
                await Updater.run();
                return;
            }

            this.user = await Users.get(args.uid);
        }else{
            this.user = undefined;
        }
    }

    async update()
    {
        if( !this.user )
            return;
        
        this._profile_picture_element.src = Users.get_image_url(this.user.image, 'info_item');
        this._name_element.textContent = this.user.first_name + ' ' + this.user.last_name;
        
        this._friends_menu.update();
        this._print_action_buttons();
    }

    private _friends_menu!: Friends_Menu;
    private _name_element: HTMLSpanElement;
    private _profile_picture_element: HTMLImageElement;

    private _init_tool_element()
    {
        this.tools_element = document.createElement('div');
        this._friends_menu = new Friends_Menu(this.tools_element, this.path);
    }

    private async _on_accept_friend_request_click()
    {
        if( !this.user || !Auth.current_user )
            return;

        const messages = {};
        const user = await Users.befriend(Auth.current_user, {uid: this.user.uid}, messages);
        print_global_messages(messages);
        if( !user )
            return;
        
        this.user = user;

        await Updater.run();
    }

    private async _on_block_click()
    {
        if( !Auth.current_user || !this.user )
            return;

        if( !confirm(_t('users/block_confirm')) )
            return;

        const messages = {};
        const user = await Users.block(Auth.current_user, {uid: this.user.uid}, messages);
        print_global_messages(messages);
        if( !user )
            return;

        this.user = user;

        await Updater.run();
    }

    private async _on_decline_friend_request_click()
    {
        if( !this.user || !Auth.current_user )
            return;

        const messages = {};
        const user = await Users.unfriend(Auth.current_user, {uid: this.user.uid}, messages);
        print_global_messages(messages);
        if( !user )
            return;

        this.user = user;

        await Updater.run();
    }

    private async _on_send_friend_request_click()
    {
        if( !this.user || !Auth.current_user )
            return;

        const messages = {};
        const user = await Users.befriend(Auth.current_user, {uid: this.user.uid}, messages);
        print_global_messages(messages);
        if( !user )
            return;
        
        this.user = user;

        await Updater.run();
    }

    private async _on_unblock_click()
    {
        if( !this.user || !Auth.current_user )
            return;

        const messages = {};
        const user = await Users.unblock(Auth.current_user, {uid: this.user.uid}, messages);
        print_global_messages(messages);
        if( !user )
            return;
        
        this.user = user;

        await Updater.run();
    }

    private async _on_unfriend_click()
    {
        if( !Auth.current_user || !this.user )
            return;

        if( !confirm(_t('users/unfriend_confirm')) )
            return;

        const messages = {};
        const user = await Users.unfriend(Auth.current_user, {uid: this.user.uid}, messages);
        print_global_messages(messages);
        if( !user )
            return;

        this.user = user;

        await Updater.run();
    }

    private async _print_action_buttons()
    {
        if( !Auth.current_user || !this.user )
            return;

        const parent_element = this.element.querySelector('.actions-buttons')!;
        parent_element.innerHTML = '';

        if( this.user.relation.status === 'blocked' ){
            const unblock_button = document.createElement('button');
            unblock_button.className = 'full-width';
            unblock_button.innerHTML = _t('users/unblock');
            unblock_button.addEventListener('click', this._on_unblock_click.bind(this));
            parent_element.append(unblock_button);
        }else if( this.user.relation.status === 'friend_request_sent' ){
            const friend_button = document.createElement('button');
            friend_button.className = 'full-width';
            friend_button.innerHTML = _t('users/friend_request_sent');
            friend_button.disabled = true;
            parent_element.append(friend_button);
        }else if( this.user.relation.status === 'friend_request_received' ){
            const accept_button = document.createElement('button');
            accept_button.className = 'full-width primary';
            accept_button.innerHTML = _t('users/accept_friendship');
            accept_button.addEventListener('click', this._on_accept_friend_request_click.bind(this));
            parent_element.append(accept_button);

            const decline_button = document.createElement('button');
            decline_button.className = 'full-width danger';
            decline_button.innerHTML = _t('users/decline_friendship');
            decline_button.addEventListener('click', this._on_decline_friend_request_click.bind(this));
            parent_element.append(decline_button);
        }else if( this.user.relation.status === 'friend_request_declined' ){
            const accept_button = document.createElement('button');
            accept_button.className = 'full-width primary';
            accept_button.innerHTML = _t('users/accept_declined_friendship');
            accept_button.addEventListener('click', this._on_accept_friend_request_click.bind(this));
            parent_element.append(accept_button);
        }else if( this.user.relation.status !== 'friend' ){
            const friend_button = document.createElement('button');
            friend_button.className = 'full-width primary';
            friend_button.innerHTML = _t('users/send_friend_request');
            friend_button.addEventListener('click', this._on_send_friend_request_click.bind(this));
            parent_element.append(friend_button);
        }

        const message_button = document.createElement('button');
        message_button.className = 'full-width';
        message_button.innerHTML = '<img src="/assets/images/nav-chats.png">';
        message_button.addEventListener('click', async () => {
            await Scene_Manager.open('chat', {'user': this.user?.uid ?? ''});
            await Updater.run();
        });
        parent_element.append(message_button);

        const options_button = document.createElement('button');
        options_button.className = 'full-width';
        options_button.innerHTML = '<span style="font-size: 2em; margin: 0 20px">&vellip;</span>';
        options_button.setAttribute('style', 'flex-grow: 0 !important');
        options_button.addEventListener('click', async () => {
            if( !Auth.current_user || !this.user )
                return;

            const popup = new User_Info_Popup(this.user);
            popup.block_cb = this._on_block_click.bind(this);
            popup.unfriend_cb = this._on_unfriend_click.bind(this);
            popup.open();
        });
        parent_element.append(options_button);
    }
}
