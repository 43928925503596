import { Auth } from "../auth";
import { Chat, Chats, Chat_Manager, Incomming_Message_Data } from "../chats";
import { Scene, Scene_Manager } from "../scenes";
import { print_global_message, print_global_messages } from "../ui/global_message";
import { Friends_Popup } from "../ui/popups/friends_popup";
import { Input_Popup } from "../ui/popups/input_popup";
import { Updater } from "../updater";
import { User } from "../users";
import { _t } from "../localization";
import { esc_attr, esc_html } from "../ui/DOM";

export class Chats_Scene extends Scene
{
    list_element!: HTMLDivElement;

    constructor(updater: Updater)
    {
        super(updater, 'chats');

        this.user_state = 'active';

        this._init_element();
        this._init_nothing_found_element();

        addEventListener('update_chats', (() => {
            if( this.is_active )
                this._on_update_chat();
        }) as EventListener);

        addEventListener('receive_messages', ((event: CustomEvent) => {
            if( this.is_active )
                this._on_receive_messages(event);
        }) as EventListener);
    }

    async open()
    {
        await super.open();
        await this._print_chats();
    }

    private _new_chat_name = '';
    private _nothing_found_element!: HTMLDivElement;

    private _init_element()
    {
        this.element.innerHTML = `
        <div class="page-section">
            <button class="create-chat add-button button round primary" style="float: right; margin-top: 15px;">+</button>
            <h1>${_t('chats/messages')}</h1>
            <div class="chats-container"></div>
        </div>`;

        this.list_element = this.element.querySelector('.chats-container') as HTMLDivElement;

        const create_button = this.element.querySelector('.create-chat') as HTMLButtonElement;
        create_button.addEventListener('click', this._on_create_chat_button_click.bind(this));
    }

    private _init_nothing_found_element()
    {
        this._nothing_found_element = document.createElement('div');
        this._nothing_found_element.innerHTML = `
            <p class="text-center">${_t('general/nothing_found')}</p>
            <button class="discover-button full-width" style="margin-bottom: 20px">${_t('users/meet_new')}</button>
            <button class="friend-request-button full-width">${_t('users/invite_existing')}</button>`;

        this._nothing_found_element.querySelector('.discover-button')!.addEventListener('click', async () => {
            await Scene_Manager.open('activities/discover');
            this._updater.run();
        });

        this._nothing_found_element.querySelector('.friend-request-button')!.addEventListener('click', async () => {
            await Scene_Manager.open('users/requests/in');
            this._updater.run();
        });
    }

    private _on_create_chat_button_click()
    {
        const input_popup = new Input_Popup('group_name', _t('chats/group_name'), 'text', _t('chats/new_chat'), async (value) => {

            if( !value ){
                alert(_t('chats/empty_name_error'));
                return;
            }
            
            this._new_chat_name = value;

            const friends_popup = new Friends_Popup(_t('general/add'), this._on_create_chat_friends_selected.bind(this));
            friends_popup.open();

            input_popup.close();
        });

        input_popup.open();
    }

    private async _on_create_chat_friends_selected(friends: User[])
    {
        if( !Auth.current_user )
            return;

        const log = {};

        const chat = await Chat_Manager.create_group_chat(this._new_chat_name, log);
        if( !chat ){
            print_global_messages(log);
            return;
        }

        for(const friend of friends)
            Chat_Manager.add_member(chat, friend.uid);

        await Scene_Manager.open('chat', {'uid': chat.uid});
        this._updater.run();
    }

    private async _on_receive_messages(event: CustomEvent<{ chats: Record<string, Incomming_Message_Data>, context: string }>)
    {
        if( !Auth.current_user )
            return;

        const { chats } = event.detail;
        for( const uid in chats ){
            const chat = await Chat_Manager.get(uid);
            if( !chat )
                continue;

            const chat_el = this.list_element.querySelector(`[data-uid="${chat.uid}"]`) as HTMLDivElement;
            this._set_unread_count(chat_el, chat.member_unread_count);
            chat_el.parentElement!.prepend(chat_el);
        }
    }

    private _on_update_chat()
    {
        this._print_chats();
    }

    private async _print_chat(chat: Chat)
    {
        let item_element = this.list_element.querySelector(`[data-uid="${chat.uid}"]`) as HTMLDivElement | null;
        if( !item_element ){
            item_element = document.createElement('div');
            item_element.addEventListener('click', async () => {
                await Scene_Manager.open('chat', {'uid': chat.uid});
                this._updater.run();
            });
        }

        item_element.className = 'main-list-item chat-list-item button';
        item_element.dataset.uid = chat.uid;
        item_element.innerHTML = `
            <div class="image-container">
                <img src="${esc_attr(Chats.get_image_url(chat.image, 'main_list_item'))}"/>
                <div class="unread-counter" ${chat.member_unread_count ? '' : 'style="display: none"'}>+${esc_html(chat.member_unread_count.toString())}</div>
            </div>
            <div class="info-container">
                <div class="name">${esc_html(chat.name)}</div>
            </div>`;

        this.list_element.append(item_element);
    }

    private async _print_chats()
    {
        if( !Auth.current_user ){
            this.list_element.innerHTML = '';
            print_global_message('error', _t('general/loading_error'));
            return;
        }
        
        const chats = Chat_Manager.get_all();
        if( !chats ){
            this.list_element.innerHTML = '';
            print_global_message('error', _t('general/loading_error'));
            return;
        }

        if( chats.size === 0 ){
            this.list_element.innerHTML = '';
            this.list_element.append(this._nothing_found_element);
            return;
        }
        
        this._nothing_found_element.remove();

        const sorted_chats = this._sort_chats(Array.from(chats.values()));
        for(const chat of sorted_chats)
            await this._print_chat(chat);
    }

    private _set_unread_count(chat_element: HTMLElement, count: number)
    {
        const unread_counter = chat_element.querySelector('.unread-counter') as HTMLDivElement;
        if( !unread_counter )
            return;

        unread_counter.textContent = '+' + count;
        unread_counter.style.display = '';
    }

    private _sort_chats(chats: Chat[]): Chat[]
    {
        return chats.sort((a, b) => {
            if( !b.last_message_datetime )
                return -1;
            else if( !a.last_message_datetime )
                return 1;
            else
                return b.last_message_datetime.getTime() - a.last_message_datetime.getTime();
        });
    }
}
