import globals from "./.env";
import { App } from "./app";
import { Dash } from "./dash";

addEventListener('load', () => {
    if( globals.IS_DASH )
        (new Dash).run();
    else
        (new App).run();
}, false);
