import { _t } from "../../localization";
import { Image } from "../../media";
import { Activity_Image_Picker } from "../activity_image_list";
import { Popup } from "./popup";

export class Activity_Images_Popup extends Popup
{
    constructor(pick_cb: (image: Image) => void)
    {
        super('activity_images', { title: _t('media/select_image') });

        this._picker = new Activity_Image_Picker(image => {
            this.close();
            pick_cb(image);
        });
        this._body.append(this._picker.element);

        this._element.style.height = '1000000px'; // Full screen. Let 'max-height' figure it out.
    }

    async open()
    {
        await super.open();
        this._picker.print();
    }

    private _picker: Activity_Image_Picker;
}