import { Clipboard } from '@capacitor/clipboard';
import { Share } from '@capacitor/share';

import { Auth } from "../../auth";
import { is_mobile } from "../../core";
import { _t } from "../../localization";
import { Users } from "../../users";
import { Popup } from "./popup";
import { print_global_message } from "../global_message";

export class Add_Friend_Popup extends Popup
{
    block_cb?: () => void;
    unfriend_cb?: () => void;

    constructor()
    {
        super('add_friend', { title: _t('users/share_friend_link') });

        const info_element = document.createElement('p');
        info_element.textContent = _t('users/add_friend_description');
        this._body.append(info_element);

        if( is_mobile() ){
            const share_button = document.createElement('button');
            share_button.addEventListener('click', this._on_share_link.bind(this));
            share_button.className = 'primary share full-width';
            share_button.style.marginBottom = '10px';
            share_button.textContent = _t('users/share');
            this._body.append(share_button);
        }

        const copy_button = document.createElement('button')!;
        copy_button.className = 'copy full-width';
        copy_button.textContent = _t('users/copy_link');
        copy_button.addEventListener('click', this._on_copy_link.bind(this));
        this._body.append(copy_button);
    }

    private async _on_copy_link()
    {
        if( !Auth.current_user )
            return;

        await Clipboard.write({
            string: Users.generate_friend_link(Auth.current_user)
        });

        print_global_message('success', _t('users/link_copied'));
    }
    
    private async _on_share_link()
    {
        if( !Auth.current_user )
            return;

        await Share.share({
            title: _t('users/share_message/title', Auth.current_user.first_name),
            text: _t('users/share_message/text'),
            url: Users.generate_friend_link(Auth.current_user),
            dialogTitle: _t('users/share_message/dialog_title'),
        });
    }
}
