import { Auth } from "../auth";
import { Chat, Chats, Chat_Manager } from "../chats";
import { _t } from "../localization";
import { Scene, Scene_Manager } from "../scenes";
import { print_global_message, print_global_messages } from "../ui/global_message";
import { Chat_Member_Popup } from "../ui/popups/chat_member_popup";
import { Friends_Popup } from "../ui/popups/friends_popup";
import { Input_Popup } from "../ui/popups/input_popup";
import { Updater } from "../updater";
import { User, User_Summary_Data, Users } from "../users";
import { Activities } from "../activities";
import { UTC } from "../core";
import { Chat_Add_Activity_Member_Popup } from "../ui/popups/chat_add_activity_member_popup";
import { esc_attr, esc_html } from "../ui/DOM";

export class Chat_Info_Scene extends Scene
{
    list_element!: HTMLDivElement;

    constructor(updater: Updater)
    {
        super(updater, 'chat/info');

        this.user_state = 'active';

        this._init_tool_element();

        addEventListener('update_chats', async () => {
            if( this.is_active && this._chat ){
                const chat = await Chat_Manager.get(this._chat!.uid);
                if( chat )
                    this._chat = chat;
                this._print();
            }
        });
    }

    close()
    {
        super.close();
    }

    async open(args?: {[key: string]: string})
    {
        await super.open(args);
        
        if( args && args.uid ){
            const chat = await Chat_Manager.get(args.uid);
            if( !chat ){
                print_global_message('error', _t('chats/chat_not_found'));
                return;
            }

            this._chat = chat;
        }else{
            this._chat = undefined;
        }

        if( this._chat && ['active', 'removed', 'left'].includes(this._chat.member_status) ){
            this.tools_element?.append(this._chat_link);
            if( this._chat.activity )
                this.tools_element?.append(this._activity_link);
            else
                this._activity_link.remove();
        }else{
            this._chat_link.remove();
            this._activity_link.remove();
        }

        this._print();
    }

    private _activity_link!: HTMLAnchorElement;
    private _chat?: Chat;
    private _chat_link!: HTMLAnchorElement;
    private _name_element: HTMLHeadingElement | null = null;

    private _init_tool_element()
    {
        this.tools_element = document.createElement('div');

        this.tools_element.className = "bottom-menu flex-equal page-center-content";

        this._chat_link = document.createElement('a');
        this._chat_link.className = 'chat-button button with-text';
        this._chat_link.innerHTML = `<img src="/assets/images/nav-chats.png">${_t('chats/messages')}</span>`;
        this._chat_link.addEventListener('click', async () => {
            await Scene_Manager.open('chat', {'uid': this._chat?.uid || ''});
            this._updater.run();
        });
        this.tools_element.append(this._chat_link);

        this._activity_link = document.createElement('a');
        this._activity_link.className = 'activity-button button with-text';
        this._activity_link.innerHTML = `<img src="/assets/images/nav-activities.png">${_t('chats/activity')}</span>`;
        this._activity_link.addEventListener('click', async () => {
            await Scene_Manager.open('activity', {'uid': this._chat?.activity.toString() || ''});
            this._updater.run();
        });

        this.tools_element.append(this._activity_link);
    }

    private _on_accept_click(chat: Chat, user: User_Summary_Data)
    {
        Chat_Manager.add_member(chat, user.uid);
    }

    private async _on_add_member_go_to_activity_click(activity_uid: string)
    {
        await Scene_Manager.open('activity', {'uid': activity_uid});
        this._updater.run();
    }

    private async _on_add_member_click()
    {
        if( !this._chat )
            return;

        // If there is an activity, perhaps the user actually wants to invite someone to the activity.
        if( this._chat.activity ){

            const log = {};
            const activity = await Activities.get(this._chat.activity);
            print_global_messages(log);

            if( activity && activity.status === 'active' ){
                if( activity.deadline && UTC() < UTC(activity.deadline) ){
                    // You can invite before the deadline
                    new Chat_Add_Activity_Member_Popup(
                        this._on_add_member_go_to_activity_click.bind(this, activity.uid),
                        this._open_friend_popup.bind(this)
                    ).open();
                    return;
                }else if( activity.datetime && UTC() < UTC(activity.datetime) ){
                    // You can't invite after the deadline. Let the user know before the datetime. Just add to chat.
                    alert(_t('chats/activity_deadline_passed'));
                } // Else just add to the chat.
            }
        }

        this._open_friend_popup();
    }

    private _on_decline_click(chat: Chat, user: User_Summary_Data)
    {
        if( !confirm(_t('chats/decline_confirm')) )
            return;

        Chat_Manager.remove_member(chat, user.uid);
    }

    private _on_friends_to_add_selected(friends: User[])
    {
        if( !this._chat )
            return;

        for(const friend of friends)
            Chat_Manager.add_member(this._chat, friend.uid);
    }

    private _on_image_clicked()
    {
        if( !this._chat )
            return;

        Scene_Manager.open('chat/image', {'uid': this._chat.uid});
        this._updater.run();
    }

    private _on_name_clicked()
    {
        if( !this._name_element )
            return;

        const input_popup = new Input_Popup('group_name', _t('chats/group_name'), 'text', this._name_element.innerHTML, async (value) => {

            if( !this._chat || !this._name_element )
                return;

            if( !value ){
                alert(_t('chats/empty_name_error'));
                return;
            }

            this._name_element.textContent = value;

            Chat_Manager.update_chat(this._chat.uid, {'name': value});

            input_popup.close();
        });

        input_popup.open();
    }

    private _on_options_member_clicked(member: User_Summary_Data)
    {
        if( !Auth.current_user || !this._chat )
            return;

        const popup = new Chat_Member_Popup(this._chat, member);
        popup.add_cb = (chat: Chat, user: User_Summary_Data) => Chat_Manager.add_member(chat, user.uid);
        popup.leave_cb = this._on_popup_leave_click.bind(this);
        popup.make_admin_cb = this._on_popup_make_admin_click.bind(this);
        popup.remove_cb = this._on_popup_remove_click.bind(this);
        popup.view_info_cb = this._on_popup_view_info_click.bind(this);
        popup.open();
    }

    private _on_popup_leave_click(chat: Chat, target_user: User_Summary_Data)
    {
        if( chat.member_role === 'admin' ){
            alert(_t('chats/admin_leave_error'));
            return;
        }

        if( !confirm(_t('chats/leave_confirm')) )
            return;

        Chat_Manager.remove_member(chat, target_user.uid);
    }

    private _on_popup_make_admin_click(chat: Chat, target_user: User_Summary_Data)
    {
        if( !confirm(_t('chats/admin_assign_confirm')) )
            return;

        Chat_Manager.update_admin(chat.uid, target_user.uid);
    }

    private _on_popup_remove_click(chat: Chat, target_user: User_Summary_Data)
    {
        if( !confirm(_t('chats/remove_member_confirm')) )
            return;

        Chat_Manager.remove_member(chat, target_user.uid);
    }

    private async _on_popup_view_info_click(target_user: User_Summary_Data)
    {
        await Scene_Manager.open('user', {'uid': target_user.uid});
        this._updater.run();
    }

    private _open_friend_popup()
    {
        if( !this._chat )
            return;

        const exclude_uids = this._chat.members
        .filter(member => member.chat_status === 'active')
        .map(member => member.uid);
    
        const popup_name = this._chat.member_role === 'admin' ? 'general/add' : 'general/invite';
        const friends_popup = new Friends_Popup(_t(popup_name), this._on_friends_to_add_selected.bind(this), {'exclude': exclude_uids});
        friends_popup.open();
    }

    private _print()
    {
        this.element.innerHTML = '';

        if( !Auth.current_user || !this._chat )
            return;

        if( this._chat.type !== 'group' ){
            this.element.innerHTML = `<p class="center-abs">${_t('chats/not_a_group_error')}</p>`;
            return;
        }

        let image_code = ``;
        if( this._chat.member_role === 'admin' ){
            image_code = `
                <div class="image-button flex-fill-container clickable button flush no-padding">
                    <div class="fill" style="overflow: hidden">
                        <img
                            class="chat-image round"
                            src="${esc_attr(Chats.get_image_url(this._chat.image, 'info_item'))}"
                            style="display: block; margin: 10px auto; width: 100px"
                        />
                    </div>
                    <div style="position: relative; width: 50px">
                        <div class="center-abs edit-icon">&#9998;</div>
                    </div>
                </div>`;
        }else{
            image_code = `<img class="chat-image round" src="${esc_attr(Chats.get_image_url(this._chat.image, 'info_item'))}" style="width: 100px"/>`;
        }

        let name_code = `<h1 class="text-center text-ellipsis">${esc_html(this._chat.name)}</h1>`;
        if( this._chat.member_role === 'admin' ){
            name_code = `
                <div class="name-button flex-fill-container clickable button flush no-padding">
                    <div class="fill" style="overflow: hidden">
                        ${name_code}
                    </div>
                    <div style="position: relative; width: 50px">
                        <div class="center-abs edit-icon">&#9998;</div>
                    </div>
                </div>`;
        }

        this.element.innerHTML = `
            <div style="padding: 20px">
                <div class="text-center">
                    ${image_code}
                </div>
                
                ${name_code}
                <hr/>
                
                <div class="members"></div>
            </div>`;

        const name_button = this.element.querySelector('.name-button') as HTMLDivElement | null;
        if( name_button ){
            this._name_element = name_button.querySelector('h1') as HTMLHeadingElement;
            name_button?.addEventListener('click', this._on_name_clicked.bind(this));
        }

        const image_button = this.element.querySelector('.image-button') as HTMLDivElement | null;
        if( image_button && this._chat.member_role === 'admin' )
            image_button.addEventListener('click', this._on_image_clicked.bind(this));
        
        this._print_member_section();
    }

    private _print_member_section()
    {
        if( !Auth.current_user || !this._chat )
            return;

        const members_element = this.element.querySelector('.members') as HTMLDivElement;

        if( this._chat.member_status !== 'active' ){
            members_element.innerHTML = `<p class="text-center">${_t('chats/not_a_member_error')}</p>`;
            return;
        }

        members_element.innerHTML = `
            <button class="add-button button round primary" style="float: right; margin-bottom: 20px;">+</button>

            <h2>${_t('chats/active_members')}</h2>
            <div class="active-member-list clear"></div>
            <hr/>`;

        if( this._chat.member_role === 'admin' ){
            members_element.innerHTML += `
                <h2>${_t('chats/pending_members')}</h2>
                <div class="pending-member-list clear"></div>
                <hr/>
                
                <h2>${_t('chats/former_members')}</h2>
                <div class="former-member-list clear"></div>`;
        }

        const add_member_button = this.element.querySelector('.add-button') as HTMLButtonElement;
        add_member_button.addEventListener('click', this._on_add_member_click.bind(this));

        let has_pending_members = false;
        let has_former_members = false;
        const active_members_element = this.element.querySelector('.active-member-list')!;
        const pending_members_element = this.element.querySelector('.pending-member-list');
        const former_members_element = this.element.querySelector('.former-member-list');
        for(const member of this._chat.members){

            let name = member.first_name + ' ' + member.last_name;
            if( member.uid === Auth.current_user.uid )
                name = '(You)';

            let code = `
                <div class="main-list-item button" style="display: flex; flex-grow: 1">
                    <div class="image-container">
                        <img class="round" src="${esc_attr(Users.get_image_url(member.image, 'main_list_item'))}"/>
                    </div>
                    <div class="info-container" style="margin: 15px">
                        ${esc_html(name)}
                    </div>`;
            if( member.chat_status === 'pending' ){
                code += `
                    <div class="image-container">
                        <a><img class="accept-button" src="/assets/images/accept.png"></a>
                    </div>
                    <div class="image-container">
                        <a><img class="decline-button" src="/assets/images/decline.png"></a>
                    </div>`;
            }
            code += `
                </div>
                <!--div class="options button flush round" style="flex-shrink: 0; margin-left: 10px; position: relative; width: 70px;">
                    <span class="center-abs" style="font-size: 1.5em">&vellip;</span>
                </div-->`;

            const member_element = document.createElement('div');
            member_element.style.display = 'flex';
            member_element.innerHTML = code;
            
            if( member.chat_status === 'active' && active_members_element ){
                active_members_element.append(member_element);
            }else if( member.chat_status === 'pending' && pending_members_element ){
                pending_members_element.append(member_element);
                has_pending_members = true;
            }else if( (member.chat_status === 'left' || member.chat_status === 'removed') && former_members_element ){
                former_members_element?.append(member_element);
                has_former_members = true;
            }else{
                continue;
            }

            const user_button = member_element.querySelector('.main-list-item') as HTMLDivElement;
            user_button.addEventListener('click', () =>  this._on_options_member_clicked(member) );

            const accept_button = member_element.querySelector('.accept-button') as HTMLImageElement;
            if( accept_button ){
                accept_button.addEventListener('click', (event) => {
                    event.stopPropagation();
                    this._on_accept_click(this._chat!, member);
                });
            }

            const decline_button = member_element.querySelector('.decline-button') as HTMLImageElement;
            if( decline_button ){
                decline_button.addEventListener('click', (event) => {
                    event.stopPropagation();
                    this._on_decline_click(this._chat!, member);
                });
            }
        }

        if( !has_pending_members && pending_members_element )
            pending_members_element.innerHTML = `<p class="text-center">${_t('chats/no_pending_members')}</p>`;

        if( !has_former_members && former_members_element )
            former_members_element.innerHTML = `<p class="text-center">${_t('chats/no_former_members')}</p>`;
    }
}
