export default class Updater
{
    handles = new Array<() => Promise<void>>

    async run()
    {
        await this._callback();
    }

    static init(callback: () => Promise<void>)
    {
        this._instance = new Updater(callback);
    }

    static async run()
    {
        if( !this._instance)
            throw 'Updater is not initialized';
        await this._instance.run();
    }

    private _callback: () => Promise<void>;

    private constructor(update_callback: () => Promise<void>)
    {
        this._callback = update_callback
    }

    static _instance?: Updater;
}
