import { Auth } from "../../auth";
import { Message, Chat, Chat_Manager, Chats, Incomming_Message_Data } from "../../chats";
import { _t } from "../../localization";
import { Scene, Scene_Manager } from "../../scenes";
import * as DOM from "../../ui/DOM";
import { print_global_message, print_global_messages } from "../../ui/global_message";
import { Message_Options_Popup } from "../../ui/popups/message_options_popup";
import Updater from "../../updater";
import { User_Summary_Data, Users } from "../../users";
import { Network } from "../../network";
import globals from "../../.env";
import { Push_Notification, Push_Notifications } from "../../push_notifications";
import { esc_attr, esc_html } from "../../ui/DOM";

const TEXTAREA_BORDER_WIDTH = 2;
const TEXTAREA_MIN_HEIGHT = 42; // 1 line.
const TEXTAREA_MAX_HEIGHT = 160; // 6 lines.

export class Chat_Scene extends Scene
{
    constructor()
    {
        super('chat');

        this.user_state = 'active';

        this._init_element();

        addEventListener('network_connection', () => {
            setTimeout(async () => {
                if( this.is_active && this._chat && Network.is_connected() ){
                    await this._load_recent_messages();
                    await this._update_read_status();
                    this.update();
                }
            }, 1000);
        });

        addEventListener('receive_messages', ((event: CustomEvent) => {
            if( this.is_active )
                this._on_receive_messages(event);
        }) as EventListener);

        addEventListener('update_chats', () => {
            if( this.is_active )
                this._on_update_chat();
        });

        addEventListener('resume', () => {
            if( this.is_active )
                this._remove_push_notifications();
        });

        Push_Notifications.register_callback((notifications: Push_Notification[]) => {
            if( this.is_active )
                this._remove_push_notifications(notifications);
        });
    }

    async open(args?: {[key: string]: string})
    {
        await super.open(args);

        this._chat = undefined;
        this._messages_element.innerHTML = '';
        this._oldest_loaded_message = undefined;
        this._reply_to_message = undefined;
        this._selected_message_element = undefined;
        this._is_scrolled_to_bottom = true;
        
        if( args ){
            if( args.uid ){
                
                this._chat = await Chat_Manager.get(args.uid);
                if( !this._chat ){
                    print_global_message('error', _t('chats/chat_not_found'));
                    return;
                }

            }else if( args.user ){

                const user = await Users.get(args.user);
                if( !user ){
                    print_global_message('error', _t('chats/user_not_found'));
                    return;
                }
                
                this._chat = Chat_Manager.get_private_chat_by_user(args.user);
                this._requested_user = args.user;
                if( !this._chat )
                    this._chat = this._create_default_private_chat(user);
            }
        }else{
            this._chat = undefined
        }

        this._title_element.textContent = this._chat?.name || _t('chats/no_name');
        this._image_element.src = Chats.get_image_url(this._chat?.image, 'main_list_item');
        this._loading_element.style.display = 'block';

        if( this._chat ){
            this._last_read_message_on_opening = this._chat.member_last_read_message;

            await this._load_recent_messages();

            this._remove_push_notifications();
        }

        setTimeout(this._check_load_area.bind(this), 10);
    }

    select_message(message: Message)
    {
        if( this._selected_message_element )
            this._selected_message_element.classList.remove('selected');
        
        const element = this._messages_element.querySelector(`[data-id="${message.id}"]`) as HTMLElement | undefined;
        if( element ){
            element.classList.add('selected');
            this._selected_message_element = element;
        }
    }

    async scroll_to_message(message: Message)
    {
        if( !this._chat || !this._chat.uid )
            return;
        
        if( this._oldest_loaded_message && message.id < this._oldest_loaded_message.id )
            await this._load_previous_messages(message);

        const element = this._messages_element.querySelector(`[data-id="${message.id}"]`) as HTMLElement;
        if( !element )
            return;

        element.scrollIntoView();

        this.select_message(message);
    }

    async update()
    {
        this._update_chat_info();
        this._update_scroll_down_element();
        this._update_writer();
    }

    private _chat: Chat | undefined;
    private _image_element!: HTMLImageElement;
    private _input_element!: HTMLTextAreaElement;
    private _is_retrieving_messages = false;
    private _is_scrolled_to_bottom = false;
    private _last_read_message_on_opening = 0;
    private _loading_element!: HTMLDivElement;
    private _messages_element!: HTMLDivElement;
    private _oldest_loaded_message?: Message;
    private _reply_to_message?: Message;
    private _requested_user = '';
    private _scroll_to_bottom_button!: HTMLButtonElement;
    private _send_button!: HTMLButtonElement;
    private _selected_message_element?: HTMLElement;
    private _title_element!: HTMLDivElement;
    private _writer_element!: HTMLDivElement;

    private async _check_load_area()
    {
        if( !this.is_active || this._is_retrieving_messages || !DOM.is_in_view(this._loading_element, this._loading_element.parentElement!) )
            return;

        this._is_retrieving_messages = true;
        await this._load_previous_messages();
        this._is_retrieving_messages = false;

        setTimeout(this._check_load_area.bind(this), 1000);
    }

    private _create_default_private_chat(user: User_Summary_Data): Chat
    {
        this._chat = new Chat;
        this._chat.type = 'private';
        this._chat.name = user.first_name + ' ' + user.last_name;
        this._chat.image = user.image;
        this._chat.members = [Auth.current_user!, user];
        this._chat.member_status = 'active';
        this._chat.member_role = 'user';
        this._chat.group_size = 2;

        return this._chat;
    }

    private _generate_message_name(message: Message): string
    {
        const writer = this._chat?.member_map.get(message.user);

        if( !writer )
            return _t('general/unknown');

        if( writer.uid === Auth.current_user?.uid )
            return _t('general/you');
        else
            return writer.first_name + ' ' + writer.last_name;
    }

    private _get_private_recipient(): User_Summary_Data | undefined
    {
        if( !this._chat || !Auth.current_user || this._chat.type !== 'private' )
            return;

        for(const member of this._chat.members){
            if( member.uid !== Auth.current_user.uid )
                return member;
        };
        
        return;   
    }

    private _init_element()
    {
        this._chat = new Chat;
        this._chat.name = _t('chats/no_name');

        this.element.style.display = 'flex';
        this.element.style.flexDirection = 'column';
        this.element.style.height = '100%';
        this.element.style.position = 'absolute';
        this.element.style.width = '100%';
        this.element.innerHTML = `
            <header style="border-bottom: 1px solid var(--light-detail-color); display: flex">
                <div class="relative" style="flex-shrink: 0; width: 70px">
                    <img class="center-abs round group-image" src="${DOM.esc_attr(Chats.get_image_url(this._chat.image, 'main_list_item'))}" style="width: var(--main-list-item-image-width)"/>
                </div>
                <div class="name-container" style="flex-grow: 1; overflow: hidden">
                    <h2 class="title text-ellipsis">${esc_html(this._chat.name)}</h2>
                </div>
                <div class="info-button relative button flush" style="border-left: 1px solid var(--light-detail-color); flex-shrink: 0; width: 70px">
                    <img class="center-abs" src="/assets/images/settings.png"/>
                </div>
            </header>
            <div style="flex-grow: 1; overflow-x: hidden; overflow-y: auto;">
                <div class="loading-area text-center" style="padding: 20px;">
                    ${_t('general/loading')}
                </div>
                <div class="chat-messages"></div>
            </div>
            <div class="writer-container">
                <div class="reply-section"></div>
                <div style="display: flex">
                    <textarea class="full-width" name="content" placeholder="${_t('chats/write_placeholder')}" style="
                        flex-grow: 1;
                        height: ${TEXTAREA_MIN_HEIGHT + 2 * TEXTAREA_BORDER_WIDTH}px;
                        max-height: ${TEXTAREA_MAX_HEIGHT}px;
                        margin: 0 5px 0 0
                    "></textarea>
                    <button class="primary" name="send" style="padding: 0 15px">&gt;&gt;&gt;</button>
                    <button class="scroll-to-bottom"></button>
                </div>
            </div>`;

        this._title_element = this.element.querySelector('.title') as HTMLDivElement;
        this._image_element = this.element.querySelector('.group-image') as HTMLImageElement;
        const info_button = this.element.querySelector('.info-button') as HTMLDivElement;
        info_button.addEventListener('click', this._on_chat_info_click.bind(this));

        const header = this.element.querySelector('header') as HTMLElement;
        if( globals.IS_DEV ){
            const load_button = document.createElement('div');
            load_button.className = 'relative button flush';
            load_button.style.borderLeft = '1px solid var(--light-detail-color)';
            load_button.style.flexShrink = '0';
            load_button.style.width = '70px';
            load_button.innerHTML = '<span class="center-abs" style="font-size: 2em; font-weight: bold">&#x1F847;</span>';
            load_button.addEventListener('click', async () => {
                if( this._chat ){
                    await this._load_recent_messages();
                    await this._update_read_status();
                    this.update();
                }
            });
            header.appendChild(load_button);
        }

        this._messages_element = this.element.querySelector('.chat-messages') as HTMLDivElement;

        this._loading_element = this.element.querySelector('.loading-area') as HTMLDivElement;
        this._loading_element.parentElement!.addEventListener('scroll', async () => {
            this._on_scroll();
            await Updater.run();
        });

        addEventListener('resize', () => {
            if( this._is_scrolled_to_bottom )
                this._scroll_to_bottom();
        });

        this._writer_element = this.element.querySelector('.writer-container') as HTMLDivElement;

        this._input_element = this.element.querySelector('[name="content"]') as HTMLTextAreaElement;
        this._input_element.addEventListener('input', this._update_writer.bind(this));
        this._input_element.addEventListener('keydown', async (event) => {
            if( event.key === 'Enter' && !event.shiftKey ){
                event.preventDefault();
                this._send();
                await Updater.run();
            }
        });

        this._send_button = this.element.querySelector('[name="send"]') as HTMLButtonElement;
        this._send_button.addEventListener('click', async () => {
            this._send();
            await Updater.run();
        });

        this._scroll_to_bottom_button = this.element.querySelector('.scroll-to-bottom') as HTMLButtonElement;
        this._scroll_to_bottom_button.style.display = 'none';
        this._scroll_to_bottom_button.addEventListener('click', this._scroll_to_bottom.bind(this));
    }

    private async _load_previous_messages(first?: Message)
    {
        if( !this._chat || !this._chat.uid ){
            this._loading_element.style.display = 'none';
            return;
        }

        const last = this._oldest_loaded_message ? this._oldest_loaded_message.id - 1 : undefined;
        const messages = await Chat_Manager.get_message_history(this._chat, first?.id, last);
        if( !messages )
            return;

        if( this._chat.message_cache.is_first_loaded && messages.length === 0 ){
            this._loading_element.style.display = 'none';
            return;
        }

        messages.forEach(message => {
            if( !this._oldest_loaded_message || message.id < this._oldest_loaded_message.id )
                this._oldest_loaded_message = message;
        });

        const old_scroll_height = this._messages_element.parentElement!.scrollHeight;

        this._print_messages(messages);
        this._sort_message_elements();

        // Wait for DOM.
        setTimeout(async () => {
            this._update_new_messages_marker();
            await this._update_read_status();

            if( this._is_scrolled_to_bottom )
                this._scroll_to_bottom();
            else
                this._messages_element.parentElement!.scrollTop += this._messages_element.parentElement!.scrollHeight - old_scroll_height;

            await Updater.run();
        }, 10);
    }

    private async _load_recent_messages()
    {
        if( !this._chat || !this._chat.uid ){
            this._loading_element.style.display = 'none';
            return;
        }

        const messages = await Chat_Manager.get_recent_messages(this._chat);
        if( !messages )
            return;

        messages.forEach(message => {
            if( !this._oldest_loaded_message || message.id < this._oldest_loaded_message.id )
                this._oldest_loaded_message = message;
        });

        this._print_messages(messages);
        this._sort_message_elements();
        this._update_new_messages_marker();

        if( this._is_scrolled_to_bottom )
            this._scroll_to_bottom();
    }

    private async _on_chat_info_click()
    {
        if( !this._chat )
            return;
        
        if( this._chat.type === 'private' ){
            const recipient = this._get_private_recipient();
            if( recipient )
                await Scene_Manager.open('user', {'uid': recipient.uid});
        }else if( this._chat.type === 'group' ){
            await Scene_Manager.open('chat/info', {'uid': this._chat.uid});
        }

        await Updater.run();
    }

    private _on_hold_message(message: Message)
    {
        const popup = new Message_Options_Popup(message, this._on_reply.bind(this));
        popup.open();
    }

    private _on_receive_messages(event: CustomEvent<{ chats: Record<string, Incomming_Message_Data> }>)
    {
        if( !Auth.current_user || !this._chat )
            return;

        const { chats } = event.detail;
        const chat_data = chats[this._chat.uid];
        if( !chat_data )
            return;

        const messages = chat_data.messages;

        messages.forEach(message => {
            if( !this._oldest_loaded_message || message.id < this._oldest_loaded_message.id )
                this._oldest_loaded_message = message;
        });

        this._print_messages(messages);
        this._sort_message_elements();
        this._update_new_messages_marker();

        // If we were at the bottom, we stay at the bottom.
        if( this._is_scrolled_to_bottom )
            this._scroll_to_bottom();
        
        if( this.is_active ){
            // Requires updated DOM.
            setTimeout(async () => {
                await this._update_read_status();
                await Updater.run();
            }, 10);
        }

        this.update();
    }

    private _on_reply(message: Message)
    {
        if( !Auth.current_user || !this._chat )
            return;

        this._reply_to_message = message;
        this._update_writer();
    }

    private _on_reply_message_click(event: MouseEvent, message: Message)
    {
        if( DOM.is_mouse_over(event.target as HTMLElement, {x: event.clientX, y: event.clientY}) ){
            this.scroll_to_message(message.reply_to!);
        }
    }

    private async _on_scroll()
    {
        await this._check_load_area();
        await this._update_read_status();
        this._is_scrolled_to_bottom = DOM.is_scrolled_to_bottom(this._messages_element.parentElement!);
        await Updater.run();
    }

    private async _on_update_chat()
    {
        if( !this._chat )
            return;

        const chat = await Chat_Manager.get(this._chat.uid);
        if( !chat )
            return;

        this._chat = chat;

        // Messages might be missing that are between other messages. For simplicity, we just reload the page.
        if( this._chat.member_status !== 'active' && chat.member_status === 'active' ){
            chat.reset();
            await Scene_Manager.open('chat', {'uid': chat.uid});
            await Updater.run();
            return;
        }

        this.update();
    }

    private _print_message(message: Message)
    {
        if( message.type === 'normal' )
            this._print_user_message(message);
        else if( message.type === 'system' )
            this._print_system_message(message);
    }

    private _print_messages(messages: Message[])
    {
        const date_elements_array = Array.from(this._messages_element.querySelectorAll('.chat-date')) as HTMLElement[];
        const date_elements = new Map(date_elements_array.map(obj => [obj.dataset.date, obj]));
        
        messages.forEach(message => {
            if( !this._chat)
                return;

            if( message.chat !== this._chat.uid || !message.created_at )
                return;
            
            const date = message.created_at.getFullYear() + '-' + (message.created_at.getMonth() + 1) + '-' + message.created_at.getDate();
            let date_element = date_elements.get(date);
            if( date_element ){
                if( message.id < Number(date_element.dataset.id) )
                    date_element.dataset.id = (message.id - 0.5).toString(); // Right on top of the first message of the day.
            }else{

                date_element = document.createElement('div');
                date_element.className = 'chat-date text-center';
                date_element.dataset.id = (message.id - 0.5).toString(); // Right on top of the first message of the day.
                date_element.dataset.date = date;
                date_element.textContent = message.created_at.toLocaleString(undefined, {dateStyle: 'full'});
                date_elements.set(date, date_element);
                this._messages_element.append(date_element);
            }
            
            this._print_message(message);
        });
    }

    private _print_system_message(message: Message)
    {
        if( !Auth.current_user || !this._chat )
            return;

        const old_element = this._messages_element.querySelector(`[data-id="${message.id}"]`);
        if( old_element )
            old_element.remove();

        const message_element = document.createElement('div');
        message_element.className = 'chat-message text-center';
        message_element.dataset.id = message.id.toString();
        message_element.style.padding = '10px 0';
        message_element.innerHTML = `
        <div class="bubble">
            <div class="content" data-id="${esc_attr(message.id.toString())}">${esc_html(message.content.toString())}</div>
        </div>`;
        this._messages_element.append(message_element);
    }

    private _print_user_message(message: Message)
    {
        if( !Auth.current_user || !this._chat )
            return;

        const user_data = this._chat.member_map.get(message.user);
        const is_this_user = user_data?.uid === Auth.current_user.uid;

        const old_element = this._messages_element.querySelector(`[data-id="${message.id}"]`);
        if( old_element )
            old_element.remove();

        const message_element = document.createElement('div');
        message_element.className = 'chat-message' + (is_this_user ? ' self' : '');
        message_element.dataset.id = message.id.toString();
        this._messages_element.append(message_element);

        const bubble_element = document.createElement('div');
        bubble_element.className = 'bubble';
        message_element.appendChild(bubble_element);
        DOM.add_hold_listener(bubble_element, this._on_hold_message.bind(this, message));
        DOM.add_drag_listener(bubble_element,
            'horizontal',
            'touch',
            undefined,
            (_, start, end) => bubble_element.style.left = `${end.x - start.x}px`,
            () => {
                bubble_element.style.left = '0';
                this._reply_to_message = message;
                this._update_writer();
            }
        );
        
        if( message.reply_to ){

            const name = this._generate_message_name(message.reply_to);
            let content = message.reply_to.content;
            if( message.reply_to.status === 'removed' )
                content = _t('chats/message_removed_status');
            else if( message.reply_to.status === 'suspended' )
                content = _t('chats/message_suspended_status');

            let color_code = '';
            const reply_to_user_data = this._chat.member_map.get(message.reply_to.user);
            if( reply_to_user_data && reply_to_user_data.uid !== Auth.current_user.uid )
                color_code = 'color: ' + reply_to_user_data.chat_data.color;

            const reply_element = document.createElement('div');
            reply_element.className = 'reply clickable';
            reply_element.innerHTML = `
                <div><strong style="${color_code}">${esc_html(name)}</strong></div>
                <div style="white-space: pre-line; word-wrap: break-word">${esc_html(content)}</div>`;
            bubble_element.appendChild(reply_element);
            reply_element.addEventListener('click', event => this._on_reply_message_click(event, message));
        }

        if( !is_this_user && this._chat.type === 'group' ){
            const name_element = document.createElement('div');
            name_element.className = 'name';
            name_element.textContent = this._generate_message_name(message);
            if( user_data?.chat_data.color )
                name_element.style.color = user_data.chat_data.color;
            bubble_element.appendChild(name_element);
        }

        const content_element = document.createElement('div');
        content_element.className = 'content';
        content_element.dataset.id = message.id.toString();
        content_element.style.whiteSpace = 'pre-line';
        content_element.style.wordBreak = 'break-word';
        bubble_element.appendChild(content_element);

        let note = '';
        if( message.status === 'removed' )
            note = _t('chats/message_removed_status');
        else if( message.status === 'suspended' )
            note = _t('chats/message_suspended_status');
        else
            // Manager accounts still receive content from the API.
            // This way it's at least hidden in the UI.
            content_element.innerHTML = DOM.build_link(esc_html(message.content));

        if( message.created_at )
            note += (note ? ' - ' : '') + message.created_at.toLocaleString(undefined, {timeStyle: 'short'});

        const note_placeholder_element = document.createElement('span');
        note_placeholder_element.className = 'note-placeholder';
        note_placeholder_element.textContent = note;
        content_element.appendChild(note_placeholder_element);

        const note_element = document.createElement('div');
        note_element.className = 'note';
        note_element.textContent = note;
        bubble_element.appendChild(note_element);
    }

    private async _remove_push_notifications(notifications?: Push_Notification[])
    {
        if( !this._chat || !this._chat.uid )
            return;

        if( !notifications )
            notifications = await Push_Notifications.get_all();

        Push_Notifications.remove(notifications.filter(
            notification => notification.tag.startsWith('chat=' + this._chat?.uid)
        ));
    }

    private async _scroll_to_bottom()
    {
        this._messages_element.parentElement!.scrollTop = this._messages_element.parentElement!.scrollHeight;
    }

    private async _send()
    {
        if( !this._chat || !this._input_element.value )
            return;
        
        // No UID means the chat is not created yet.
        if( !this._chat.uid && this._chat.type === 'private' && this._requested_user ){
            const log = {}
            this._chat = await Chat_Manager.create_private_chat(this._requested_user, log);
            if( !this._chat ){
                print_global_messages(log);
                return;
            }
        }
        
        const log = {}
        if( !Chat_Manager.send_message(this._chat, this._input_element.value, this._reply_to_message, log) ){
            print_global_messages(log);
            return;
        }
        
        this._input_element.value = '';
        this._update_writer();
        this._reply_to_message = undefined;

        this._scroll_to_bottom();
    }

    private _sort_message_elements()
    {
        const elements = Array.from(this._messages_element.querySelectorAll('.chat-message, .chat-date') as NodeListOf<HTMLElement>);
        elements.sort((a, b) => {
            const id_a = Number(a.dataset.id);
            const id_b = Number(b.dataset.id);
            return id_a - id_b;
        });

        for(const element of elements)
            this._messages_element.appendChild(element);
    }

    private _update_chat_info()
    {
        if( !this._chat )
            return;

        this._title_element.innerHTML = this._chat.name;
        this._image_element.src = Chats.get_image_url(this._chat.image, 'main_list_item');   
    }

    private _update_new_messages_marker()
    {
        const message_elements = Array.from(this._messages_element.querySelectorAll('.chat-message')) as HTMLElement[];
        const marker_element = this._messages_element.querySelector('.new-message-marker') as HTMLElement | null;
        if( marker_element )
            marker_element.remove();

        let is_first = true;
        message_elements.forEach(element => {
            // Check the class as well to ignore the date elements.
            const id = Number(element.dataset.id);
            if( id <= this._last_read_message_on_opening )
                return;

            const message = this._chat?.message_cache.message_map.get(id);
            if( !message )
                return;

            if( message.user === Auth.current_user!.uid )
                return;
            
            if( is_first ){
                const marker_element = document.createElement('div');
                marker_element.className = 'new-message-marker text-center';
                marker_element.style.backgroundColor = 'var(--light-detail-color)';
                marker_element.style.color = 'var(--active-color)';
                marker_element.style.fontWeight = 'bold';
                marker_element.style.padding = '10px';
                marker_element.textContent = _t('chats/new_messages');
                this._messages_element.insertBefore(marker_element, element);
            }

            element.style.backgroundColor = 'var(--light-detail-color)';
            is_first = false;
        });
    }

    private async _update_read_status()
    {
        if( !this._chat )
            return;

        const message_elements = Array.from(this._messages_element.querySelectorAll('.chat-message .content')) as HTMLElement[];
        message_elements.reverse();

        let new_last_read_message = this._chat.member_last_read_message;
        for(const message_element of message_elements){
            
            const id = Number(message_element.dataset.id);
            if( id <= new_last_read_message )
                continue;

            if( !DOM.is_in_view(message_element, this._messages_element.parentElement!) )
                continue;

            new_last_read_message = id;
        }

        if( new_last_read_message > this._chat.member_last_read_message )
            await Chat_Manager.update_last_read_message(this._chat, new_last_read_message);
    }

    private _update_scroll_down_element()
    {
        if( !this._chat )
            return;

        this._scroll_to_bottom_button.innerHTML = '+' + this._chat.member_unread_count;
        if( this._chat.member_unread_count === 0 ){
            this._scroll_to_bottom_button.style.display = 'none'
        }else{
            // Avoid the icon showing up when a new message is received and it's still scrolling down.
            setTimeout(() => {
                if( this._chat!.member_unread_count > 0 )
                    this._scroll_to_bottom_button.style.display = 'block'; }
            , 1000);
        }
    }

    private _update_writer()
    {
        if( !Auth.current_user )
            return;

        if( this._chat?.member_status !== 'active' ){
            this._writer_element.style.display = 'none';
            return;
        }

        this._writer_element.style.display = 'block';

        this._input_element.style.height = (TEXTAREA_MIN_HEIGHT + 2 * TEXTAREA_BORDER_WIDTH) + 'px';
        this._input_element.style.height = (this._input_element.scrollHeight + 2 * TEXTAREA_BORDER_WIDTH) + "px";

        this._send_button.disabled = this._input_element.value === '';

        const reply_element = this.element.querySelector('.reply-section') as HTMLDivElement;
        if( this._reply_to_message ){

            const name = this._generate_message_name(this._reply_to_message);

            reply_element.style.display = 'block';
            reply_element.innerHTML = `
                <div><strong>${esc_html(name)}</strong></div>
                <div class="reply-text">${esc_html(this._reply_to_message.content)}</div>
                <button class="cancel">&#10005;</button>`;

            const cancel_button = reply_element.querySelector('.cancel') as HTMLButtonElement;
            cancel_button.addEventListener('click', () => {
                this._reply_to_message = undefined;
                reply_element.style.display = 'none';
            });
        }else{
            reply_element.style.display = 'none';
            reply_element.innerHTML = '';
        }
    }
}
