import { _t } from "../../localization";
import { print_global_message } from "../global_message";
import { Image_Uploader } from "../image_uploader";
import { Popup } from "./popup";

export class Image_Uploader_Popup extends Popup
{   
    constructor(callback: (image: Blob) => void)
    {
        super('image_uploader', { title: _t('media/select_image') });

        this._callback = callback;

        this._image_uploader = new Image_Uploader;
        this._body.append(this._image_uploader.element);

        this._confirm_button = document.createElement('button');
        this._confirm_button.className = 'primary full-width';
        this._confirm_button.innerHTML = _t('general/confirm');
        this._confirm_button.style.marginTop = '10px';
        this._confirm_button.addEventListener('click', this._on_confirm.bind(this));
        this._body.appendChild(this._confirm_button);
    }

    private _callback: (image: Blob) => void;
    private _confirm_button: HTMLButtonElement;
    private _image_uploader!: Image_Uploader;

    private async _on_confirm()
    {
        if( !this._image_uploader.image ){
            print_global_message('error', _t('users/no_image_selected'));
            return;
        }

        this._callback(this._image_uploader.image);
        
        this.close();
    }
}
