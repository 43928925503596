import * as Capacitor									from '@capacitor/app';
import { ScreenOrientation }							from '@capacitor/screen-orientation';

import * as io											from "socket.io-client";

import globals											from './.env';
import { API, parse_API_messages }						from "./api";
import { Auth }    										from "./auth";
import { Chat_Manager }									from "./chats";
import { Locale, _t }									from "./localization";
import { Network }										from './network';
import { Notification_Manager }							from './notifications';
import { Scene, Scene_Manager, Scene_Request }			from "./scenes";
import { No_Connection_Scene }							from "./scenes/app/no_connection_scene";
import { Not_Found_Scene }								from "./scenes/app/not_found_scene";
import { Activities_Image_Scene }						from "./scenes/dash/activities_image_scene";
import { Activities_Images_Scene }						from "./scenes/dash/activities_images_scene";
import { print_global_messages }						from "./ui/global_message";
import { Nav_Menu }       								from "./ui/nav_menu";
import Updater											from "./updater";

export class Dash
{
	async run()
	{
		Updater.init(this.update.bind(this));
		Capacitor.App.addListener('resume', async () => {
			await Updater.run();
		});

		await Locale.init();

		this._init_template();
		this._init_screen();

		document.getElementById('dash-content')!.innerHTML = '<div class="center-abs text-center">' + _t('general/loading') + '</div>';
		
		// this._nav_menu = new Nav_Menu();
		this._init_scenes();
		await this._init_auth();
		
		// Calls update, so must be initialized after _nav_menu.
		// Sets scene, so must be after _init_auth to avoid redirecting for authentication.
		this._init_deeplinks();

		if( Auth.current_user && Auth.current_user.status === 'active' )
			await this._run_with_socket();
		else
			await this._run_without_socket();
	}

	async update()
	{
		await Scene_Manager.update();

		// await this._nav_menu.update();
	}
	
	private _is_opened = false;
	private _nav_menu!: Nav_Menu;
	private _socket?: io.Socket;

	private async _check_connection_redirect()
	{
		if( !this._is_opened ){

			this._is_opened = true; // Do this first avoid an exception bypass.
			try{
				if( !Network.is_connected() )
					throw 'no_internet';

				const result = await API.GET('/ping');
				if( !result || result.status !== 200 )
					throw 'no_server';

			}catch(error){
				return {path: 'no_connection', args: {'reason': error as string}};
			}
		}
	}

	private async _check_verification_redirect()
	{
		if( !Auth.current_user || Auth.current_user.status === 'unverified' )
			Scene_Manager.login_redirect();
	}

	private async _init_auth(): Promise<void>
	{
		Auth.init();

		const messages: Record<string, any> = {};

		await Auth.resume_session(messages);
		print_global_messages(messages);
	}

	private _init_deeplinks()
	{
		Capacitor.App.addListener('appUrlOpen', async (event: Capacitor.URLOpenListenerEvent) => {
			
			// If we login we need to remember the redirect.
			const old_query = new URLSearchParams(location.search);
			if( old_query.has('redirect') )
				event.url += (event.url.includes('?') ? '&' : '?') + 'redirect=' + old_query.get('redirect');

			await Scene_Manager.open_url(event.url);
			await Updater.run();
		});
	}

	private _init_scenes()
	{
		Scene_Manager.init(
			document.getElementById('dash-content')!,
			globals.HOME_PATH,
			() => Auth.current_user,
			{
				login_path: globals.LOGIN_PATH,
				redirect_cb: this._on_scene_redirect.bind(this)
			}
		);

		Scene_Manager.register(new No_Connection_Scene());
		Scene_Manager.register(new Not_Found_Scene());

		Scene_Manager.register(new Activities_Image_Scene());
		Scene_Manager.register(new Activities_Images_Scene());
	}

	private async _init_screen()
	{
		try{
			await ScreenOrientation.lock({ orientation: 'portrait' });
		}catch(e){
			console.log('Screen orientation not supported.');
		}
	}

	private async _init_template()
	{
		document.title = _t('general/title');

		document.body.innerHTML = `<div id="dash">
            <div id="page-top"></div>
            <div id="page-middle">
				<div id="page-side">
					<div id="page-sidebar"></div>
				</div>
				<div id="page-content">
					<div id="dash-content"></div>
				</div>
            </div>
        </div>`;
	}

	private async _on_scene_redirect(scene: Scene): Promise<Scene_Request | undefined>
	{
		{
			const result = await this._check_connection_redirect();
			if( result )
				return result;
		}

		await this._check_verification_redirect();
	}

	private async _run_with_socket()
	{
		this._socket = io.connect(API.url, {
			withCredentials: true,
		});

		await Network.init(this._socket);

		this._socket!.on('log', (data: any) => {
			const log = {};
			parse_API_messages(log, data);
			print_global_messages(log);	
		});

		let first_connection = true;

		this._socket!.on('connect', async () => {
			if( !first_connection )
				return;
			
			await Chat_Manager.init(this._socket!);
			await Notification_Manager.init(this._socket!);
			
			if( !Scene_Manager.active_scene )
				await Scene_Manager.open_url();

			await Updater.run();
			
			first_connection = false;
		});
	}

	private async _run_without_socket()
	{
		await Network.init();
		await Scene_Manager.open_url('');
		await Updater.run();
	}
}
