import { _t } from "../localization";
import { Scene_Manager } from "../scenes";
import { Updater } from "../updater";
import { Users } from "../users";

export class Friends_Menu
{
    constructor(element: HTMLDivElement, path: string, updater: Updater)
    {
        element.className = "bottom-menu flex-equal page-center-content";
        element.innerHTML = '';
        
        element.innerHTML += `
            <a class="friends-button button with-text relative">
                <img src="/assets/images/person.png">
                ${_t('users/friends')}
            </a>
            <a class="requests-out-button button with-text relative">
                <img src="/assets/images/arrow-up.png">
                ${_t('users/sent_requests')}
            </a>
            <a class="requests-in-button button with-text relative">
                <img src="/assets/images/arrow-down.png">
                ${_t('users/received_requests')}
            </a>`;
        
        const friends_button = element.querySelector('.friends-button') as HTMLElement;
        friends_button.style.backgroundColor = path === 'users/friends' ? 'var(--light-detail-color)' : '';
        friends_button.addEventListener('click', async () => {
            await Scene_Manager.open('users/friends');
            updater.run();
        });

        const requests_out_button = element.querySelector('.requests-out-button') as HTMLElement;
        requests_out_button.style.backgroundColor = path === 'users/requests/out' ? 'var(--light-detail-color)' : '';
        requests_out_button.addEventListener('click', async () => {
            await Scene_Manager.open('users/requests/out');
            updater.run();
        });

        this._requests_in_button = element.querySelector('.requests-in-button') as HTMLElement;
        this._requests_in_button.style.backgroundColor = path === 'users/requests/in' ? 'var(--light-detail-color)' : '';
        this._requests_in_button.addEventListener('click', async () => {
            await Scene_Manager.open('users/requests/in');
            updater.run();
        });
    }

    async update()
    {
        const requests = await Users.get_received_friend_requests();
        if( requests && requests.length > 0 )
            this._requests_in_button.classList.add('with-notification');
        else
            this._requests_in_button.classList.remove('with-notification');
    }

    private _requests_in_button: HTMLElement;
}
