import globals from "../../.env";
import { Storage } from "../../core";
import { _t } from "../../localization";
import { Pagination } from "../pagination";
import { Popup } from "./popup";

export class Activity_Info_Tips_Popup extends Popup
{
    constructor()
    {
        super('activity_info_tips', { title: _t('general/info') });

        const page_1 = document.createElement('div');
        page_1.className = 'text-center';
        page_1.innerHTML = `
            <button class="add-button button round primary" style="cursor: default">+</button>
            <p>${_t('tips/activity_info/add_member')}</p>`;
        this._pagination.add_page(page_1);

        const page_2 = document.createElement('div');
        page_2.className = 'text-center';
        page_2.innerHTML = `<p>${_t('tips/activity_info/cancel_or_leave')}</p>`;
        this._pagination.add_page(page_2);

        const page_3 = document.createElement('div');
        page_3.className = 'text-center';
        page_3.innerHTML = `
                <img src="/assets/images/nav-chats.png">
                <p>${_t('tips/activity_info/chat')}</p>`;
        this._pagination.add_page(page_3);

        this._body.append(this._pagination.element);
    }

    async open()
    {
        if( !globals.FORCE_TIPS && await Storage.get('is_shown/' + this.name) )
            return;
        Storage.set('is_shown/' + this.name, 'true');

        await super.open();

        this._pagination.open();
    }

    private _pagination = new Pagination;
}
