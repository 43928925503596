import { Auth } from "../auth"
import * as DOM from "../ui/DOM"
import { Scene, Scene_Manager } from "../scenes"
import { Country_Input } from "../ui/country_input";
import { print_global_message, print_global_messages } from "../ui/global_message"
import { Updater } from "../updater"
import { Users } from "../users"
import { _t } from "../localization";

export class Profile_Preferences_Scene extends Scene
{
    form: HTMLFormElement

    constructor(updater: Updater)
    {
        super(updater, 'profile/preferences')

        this.user_state = 'logged_in'
        
        this.element.className = 'page-section';
        this.element.innerHTML = `
        <form>

            <h1>${_t('users/preferences')}</h1>

            <label class="block full-width">
                <span data-lang="users/max_age_diff">Maximum age difference:</span>
                <input class="full-width" name="max_age_diff" type="number" required>
            </label>
            <hr/>

            <p data-lang="users/address_info"></p>
            <label class="block full-width">
                <span data-lang="users/address">Address:</span>
                <input class="full-width" name="address" type="text" required>
            </label>
            <label class="block full-width">
                <span data-lang="users/city">City:</span>
                <input class="full-width" name="city" type="text" required>
            </label>
            <label class="block full-width country-section">
                <span data-lang="users/country">Country:</span>
            </label>
            <label class="block full-width">
                <span data-lang="users/search_radius">Search radius (km):</span>
                <input class="full-width" name="radius" type="number" required>
            </label>

            <hr/>

            <label class="block full-width">
                <span data-lang="users/gender_preference">Gender preference:</span>
                <select class="full-width" name="gender_preference" required>
                    <option value="same" data-lang="users/gender_preference/same">Same</option>
                    <option value="all" data-lang="users/gender_preference/all">All</option>
                </select>
            </label>

            <button class="full-width blocked-button" name="blocked" type="button" data-lang="users/blocked_users">
                Blocked Users
            </button>
            
            <div class="flex-equal horizontal-rounded-content" style="margin-top: 40px">
                <button class="primary" name="submit" data-lang="general/save">
                    Save
                </button>
                <button name="cancel" type="button" data-lang="general/cancel">
                    Cancel
                </button>
            </div>
        </form>`

        this.element.querySelector('.blocked-button')!.addEventListener('click', async () => {
            await Scene_Manager.open('users/blocked')
            this._updater.run()
        })

        this.form = this.element.querySelector('form')!
        this.form.addEventListener('submit', this._on_submit.bind(this))

        this._country_element = new Country_Input;
        this._country_element.element.classList.add('full-width');
        this.form.querySelector('.country-section')!.append(this._country_element.element);

        this.element.querySelector("[name='submit']")!.addEventListener('click', () => { this.form.classList.add('submitted') })
        this.element.querySelector("[name='cancel']")!.addEventListener('click', () => {
            Scene_Manager.go_back()
            this._updater.run()
        })
    }

    load_form()
    {
        if( !Auth.current_user )
            return false

        const user = Auth.current_user
        DOM.set_form_values(this.form, {
            'max_age_diff': user.max_age_diff,
            'address': user.address,
            'city': user.city,
            'country': user.country,
            'radius': user.radius,
            'gender_preference': user.gender_preference,
        })

        const gender_input = this.form.querySelector('[name="gender_preference"]') as HTMLSelectElement;
        if( user.gender){
            gender_input.disabled = false;
        }else{
            gender_input.disabled = true;
            gender_input.value = 'all';
        }
    }

    async open()
    {
        await super.open()
        this.load_form()
    }

    private _country_element: Country_Input;

    async _on_submit(event: Event)
    {
        event.preventDefault()

        if( !Auth.current_user )
            return false

        const messages = {}
        const result = await Users.update(Auth.current_user, DOM.parse_form(this.form), messages)
        if( !result ){
            print_global_messages(messages)
            return
        }

        await Scene_Manager.open('profile')
        print_global_message('success', 'Your info has been updated.')
        this._updater.run()
    }
}
