import * as io from "socket.io-client";

import { API, API_Data, parse_API_messages } from "./api";
import * as core from "./core";
import { _t } from "./localization";
import { Network } from "./network";

const MAX_NOTIFICATIONS_PER_REQUEST = 20;

export class Notification
{
    id = 0;
    name = '';
    content = '';
    image = '';
    url = '';
    status = '';
    created_at?: Date;
}

class Notification_Cache
{
    is_first_loaded = false;
    notifications = new Array<Notification>;
    notification_map = new Map<number, Notification>;
}

export class Notifications
{
    static get_image_url(image?: string, context='')
    {
        if( image )
            return image + '?context=' + encodeURIComponent(context);
        else
            return '/assets/images/default-notification-image.png';
    }

    static async mark_as_seen(log: core.Log_Messages = {}): Promise<boolean>
    {
        const response = await API.PUT('/notifications/mark_as_seen');
        if( !response )
            return false;

        const result = await response.json();
        parse_API_messages(log, result);

        return response.status === 200;
    }

    static parse(data: any, notification: Notification | false = false): Notification | undefined
    {
        if( !core.is_object(data) )
            return;

        if( !notification )
            notification = new Notification;

        notification.id = data.id || '';
        notification.name = data.name || '';
        notification.content = data.content || '';
        notification.image = data.image || '';
        notification.url = data.url || '';
        notification.status = data.status || '';
        notification.created_at = core.UTC(data.created_at);

        return notification;
    }

    static async update(notification: Notification, data: API_Data, log: core.Log_Messages = {}): Promise<Notification | undefined>
    {
        const response = await API.PUT('/notifications/' + encodeURIComponent(notification.id), data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        return this.parse(result.data.notification);
    }
}

export class Notification_Manager
{
    static async get(data: API_Data, log: core.Log_Messages = {}): Promise<Notification[] | undefined>
    {
        const response = await API.GET(`/notifications`, data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 || !result.data || !result.data.notifications )    
            return;

        const notifications = new Array<Notification>;
        for(const idx in result.data.notifications){
            const notification = Notifications.parse(result.data.notifications[idx]);
            if( notification )
                notifications.push(notification);
        }

        return notifications;
    }

    static async get_history(first: number | undefined, last: number | undefined): Promise<Notification[] | undefined>
    {
        const new_notifications = new Array<Notification>;
        while(true){

            let notifications = this._get_cache(first, last, 'desc');
            if( !notifications ){

                notifications = await this.get({
                    'first': first ? first : '',
                    'last': last ? last : '',
                    'order': 'desc',
                });
                if( !notifications )
                    return;

                this._add_multiple_to_cache(notifications);
            }

            if( notifications.length === 0 ){
                if( first === undefined )
                    this._cache.is_first_loaded = true;
                break;
            }

            last = notifications.reduce((id, notification) => id === undefined || notification.id < id ? notification.id : id, last)! - 1;
            new_notifications.push(...notifications);

            // Without an upper boundry just one batch in enough.
            if( first === undefined )
                break;
        }

        this._sort(new_notifications);

        return new_notifications;
    }

    // Check for new data since last request.
    static async get_recent(): Promise<Notification[] | undefined>
    {
        // If nothing has been loaded at all, we don't need to check as they will be loaded later anyway.
        if( this._cache.notifications.length === 0 && !this._cache.is_first_loaded )
            return [];

        const new_notification = new Array<Notification>;
        
        while(true){
            const last_notification = this._cache.notifications[this._cache.notifications.length - 1];
            const notifications = await this.get({
                'first': last_notification ? last_notification.id + 1 : '', // +1: load after this notification.
            });
            if( !notifications )
                return;

            if( notifications.length === 0 )
                break;

            new_notification.push(...notifications);
            this._add_multiple_to_cache(notifications);
        }

        this._sort(new_notification);

        return new_notification;
    }

    static has_unseen(): boolean
    {
        for(const notification of this._cache.notifications){
            if( notification.status === 'new' )
                return true;
        }

        return false;
    }

    static async init(socket: io.Socket): Promise<void>
    {
        addEventListener('network_connection', async () => {
            if( !Network.is_connected() )
                return;

            const notifications = await this.get_recent();
            if( !notifications )
                return;

            dispatchEvent(
                new CustomEvent('receive_notifications', {
                    detail: {
                        'notifications': notifications,
                        'is_first_loaded': this._cache.is_first_loaded,
                    }
                })
            );
        });
        
        socket.on('notifications/receive', this._on_receive.bind(this));

        // Make sure some notifications are pre-loaded.
        // Some components need to know if there are any new notifications.
        await this.get_history(undefined, undefined);
    }

    static is_first_loaded()
    {
        return this._cache.is_first_loaded;
    }

    static async mark_as_read(notification: Notification, log: core.Log_Messages = {}): Promise<boolean>
    {
        if( !await Notifications.update(notification, {status: 'read'}) )
            return false;

        const notification_cache = this._cache.notifications.find(n => n.id === notification.id);
        if( notification_cache )
            notification_cache.status = 'read';

        return true;
    }

    static async mark_as_seen(log: core.Log_Messages = {}): Promise<boolean>
    {
        if( !await Notifications.mark_as_seen(log) )
            return false;

        this._cache.notifications.forEach(notification => {
            if( notification.status === 'new' )
                notification.status = 'seen';
        });

        return true;
    }

    private static _cache = new Notification_Cache;

    private static _add_to_cache(notification: Notification)
    {
        if( this._cache.notification_map.has(notification.id) ){
            this._cache.notifications = this._cache.notifications.map(n => n.id === notification.id ? notification : n);
        }else{
            this._cache.notifications.push(notification);
            this._sort(this._cache.notifications);
        }
        this._cache.notification_map.set(notification.id, notification);
    }

    private static _add_multiple_to_cache(notifications: Notification[]): void
    {
        notifications.forEach(notification => this._add_to_cache(notification));
    }
    
    // Undefined if not cached yet. Empty array if requested from server, but nothing was found.
    private static _get_cache(first: number | undefined, last: number | undefined, order: 'asc' | 'desc'): Notification[] | undefined
    {
        let notifications = new Array<Notification>;

        if( first !== undefined || last !== undefined ){
            for(let i = 0; i < this._cache.notifications.length; i++){

                let idx = i;
                // If in reverse order, we can save cycles by starting at the bottom.
                if( order === 'desc' )
                    idx = this._cache.notifications.length - i - 1;

                const notification = this._cache.notifications[idx];

                if( first !== undefined && notification.id < first )
                    continue;

                if( last !== undefined && notification.id > last )
                    continue;
                
                notifications.push(notification);
                
                // If only one limit is set, we can break early.
                if( (first !== last) && notifications.length >= MAX_NOTIFICATIONS_PER_REQUEST )
                    break;
            }
        }else{
            notifications = this._cache.notifications.slice(-MAX_NOTIFICATIONS_PER_REQUEST, this._cache.notifications.length);
        }

        // Check if no cached notifications were found, but not all notifications were yet cached.
        // We return 'undefined' so the returning function knows they need to request the server.
        if( notifications.length === 0 && !this._cache.is_first_loaded )
            return;
        
        return notifications;
    }

    private static _on_receive(data: any)
    {
        if( typeof data !== 'object' )
            return;

        const all_notification_data = data['notifications'];
        if( !Array.isArray(all_notification_data) )
            return;

        const new_notifications = new Array<Notification>;
        for(const notification_data of all_notification_data){

            const notification = Notifications.parse(notification_data);
            if( !notification )
                continue;

            this._add_to_cache(notification)
            new_notifications.push(notification);
        }
        
        this._sort(new_notifications);

        dispatchEvent(
            new CustomEvent('receive_notifications', {
                detail: {
                    'notifications': new_notifications,
                    'is_first_loaded': this._cache.is_first_loaded,
                }
            })
        );
    }

    private static _sort(notifications: Notification[]): Notification[]
    {
        return notifications.sort((a, b) => a.id - b.id);
    }
}
