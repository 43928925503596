import { Activities } from "../activities";
import { Log_Messages } from "../core";
import { _t } from "../localization";
import { Image } from "../media";
import { _, is_in_view } from "./DOM";
import { print_global_messages } from "./global_message";

const IMAGES_PER_PAGE = 16;

export class Activity_Image_Picker
{
    element: HTMLDivElement;

    constructor(pick_cb: (image: Image) => void)
    {
        this._on_picked = pick_cb;

        const parent_style = `
            bottom: 20px;
            left: 20px;
            overflow: none;
            position: absolute;
            right: 20px;
            top: 20px;
        `;

        this.element = _('div', {class: 'flex column grow-last image-picker', style: parent_style}, [
            _('label', [
                _('p', {style: 'margin: 0 0 10px'}, _t('activities/form/image_keywords')),
                this._search_input = _('input', {
                    class: 'full-width',
                    oninput: this._on_search.bind(this),
                    placeholder: _t('activities/form/image_keywords/placeholder'),
                    style: 'margin: 0px',
                    type: 'search'
                }),
            ]),
            _('div', {onscroll: this._check_loading_area.bind(this), style: 'overflow-x: hidden; overflow-y: auto'}, [
                this._image_container = _('div', {class: 'image-list flex wrap', style: 'overflow: auto'}),
            ]),
        ]);

        this._loading_el = _('p', {class: 'text-center'}, [_t('general/loading')]);
    }

    async print()
    {
        await this._reset();
    }

    private _default_image?: Image;
    private _image_container: HTMLDivElement;
    private _is_retrieving = false;
    private _on_picked: (image: Image) => void;
    private _loading_el: HTMLDivElement;
    private _page = 1;
    private _search_input: HTMLInputElement;

    private async _check_loading_area()
    {
        if( this._is_retrieving || !is_in_view(this._loading_el, this._image_container.parentElement!) )
            return;

        this._is_retrieving = true;
        await this._load_page();
        this._is_retrieving = false;

        setTimeout(this._check_loading_area.bind(this), 1000);
    }

    private async _load_default_image()
    {
        const images = await Activities.get_images({'keywords': 'default'});
        if( images && images.length > 0 )
            this._default_image = images[0];
    }

    private async _load_page()
    {
        const keywords = this._search_input.value;

        const messages: Log_Messages = {};
        
        let items_per_page = IMAGES_PER_PAGE;
        // Space for default image on first page.
        if( this._page === 1 )
            items_per_page = IMAGES_PER_PAGE - 1;

        const images = await Activities.get_images({'keywords': keywords, 'items_per_page': items_per_page, 'page': this._page}, messages);
        if( !images ){
            this._loading_el.remove();
            print_global_messages(messages);
            return;
        }

        if( this._page === 1 && this._default_image )
            this._print_image(this._default_image);

        if( images.length === 0 ){
            this._loading_el.remove();
            return;
        }
        
        for(const image of images){
            if( !this._default_image || image.uid !== this._default_image.uid )
                this._print_image(image);
        }

        this._page++;
    }

    private async _on_image_click(image: Image)
    {
       this._on_picked(image);
    }

    private async _on_search()
    {
        const keywords = this._search_input.value;
        
        setTimeout(async () => {

            // Make sure the input hasn't changed since the last interval.
            if( this._search_input.value !== keywords )
                return;

            await this._reset();
        }, 1000);
    }

    private _print_image(image: Image)
    {
        const url = Activities.get_image_url(image.url, 'activity_search_list_item');
        this._image_container.append(
            _('div', {style: 'width: 25%'}, [
                _('img', {
                    class: 'full-width clickable',
                    'data-image': image.uid,
                    onclick: this._on_image_click.bind(this, image),
                    src: url,
                    style: 'padding: 3px'
                }),
            ])
        );
    }

    private async _reset()
    {
        this._page = 1;

        this._image_container.innerHTML = '';
        this._image_container.parentElement!.append(this._loading_el);

        if( !this._default_image )
            await this._load_default_image();

        setTimeout(this._check_loading_area.bind(this), 10);
    }
}