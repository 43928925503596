import { countries, ICountry } from "countries-list";

export class Price_Input
{
    element: HTMLDivElement;
    currency_label: HTMLSpanElement;
    currency_input: HTMLSelectElement;
    price_input: HTMLInputElement;

    constructor(args: { id?: string, required?: boolean, country?: string })
    {
        this.element = document.createElement('div');
        this.element.style.display = 'flex';

        const currency_section = document.createElement('div');
        this.element.append(currency_section);

        this.currency_input = document.createElement('select');
        this.currency_input.name = 'currency';
        this.currency_input.required = args?.required || false;
        this.currency_input.style.marginRight = '20px';
        this.currency_input.name = 'currency';
        currency_section.append(this.currency_input);

        this.currency_label = document.createElement('span');
        this.currency_label.style.margin = '22px 20px 22px 0';
        currency_section.append(this.currency_label);

        const price_section = document.createElement('div');
        price_section.style.flexGrow = '1';
        this.element.append(price_section);

        this.price_input = document.createElement('input');
        this.price_input.className = 'full-width';
        this.price_input.name = 'price';
        this.price_input.required = args?.required || false;
        this.price_input.type = 'text';
        this.price_input.value = '0';
        this.price_input.addEventListener('input', this._format_price.bind(this));
        price_section.append(this.price_input);

        if( args.country )
            this.set_currency_by_country(args.country);

        if( args.id ){
            this.element.id = args.id;
            this.currency_input.id = args.id + '-currency';
            this.price_input.id = args.id + '-price';
        }
    }

    get_currency(): string
    {
        return this.currency_input.value;
    }

    get_price(): string
    {
        return this.price_input.value;
    }

    set_currency(currency: string): void
    {
        this.currency_input.value = currency;
    }

    set_currency_by_country(country: string): void
    {
        if( country === this._country )
            return;

        // @ts-expect-error
        const country_data = countries[country.toUpperCase()] as ICountry;
        if( !country_data || country_data.currency.length === 0 )
            return;

        this.currency_input.innerHTML = '';

        country_data.currency.forEach(c => {
            const option = document.createElement('option');
            option.textContent = c.toUpperCase();
            this.currency_input.append(option);
        });

        this.currency_label.innerHTML = country_data.currency[0];
        this.currency_input.value = country_data.currency[0];

        if( country_data.currency.length === 1 ){
            this.currency_label.style.display = 'inline-block';
            this.currency_input.style.display = 'none';
        }else{
            this.currency_label.style.display = 'none';
            this.currency_input.style.display = 'inline-block';
        }
    }

    set_price(price: string): void
    {
        this.price_input.value = price;
    }

    private _country = '';
    
    private _format_price(event: Event)
    {
        const el = event.target as HTMLInputElement;

        let value = el.value;
        value = value.replace(',', '.');
        value = value.replace(/[^0-9.]/g, '');
        el.value = value;
    }
}
