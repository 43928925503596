import { Auth } from "../auth";
import * as DOM from "../ui/DOM";
import { Scene, Scene_Manager } from "../scenes";
import { print_global_messages } from "../ui/global_message";
import { Updater } from "../updater";
import { _t } from "../localization";

export class Auth_Login_Scene extends Scene
{
    form!: HTMLFormElement;

    constructor(updater: Updater)
    {
        super(updater, 'auth/login');
        this.user_state = 'logged_out';
        this.menu_type = 'language';
        this._init_element();
    }

    clear_password_field()
    {
        (this.element.querySelector("[name='password']") as HTMLInputElement).value = '';
    }

    close()
    {
        super.close();
        
        this.clear_password_field();
    }

    async open()
    {
        await super.open();
        
        this.form.classList.remove('submitted');
    }

    private _submit_button!: HTMLButtonElement;

    private _init_element()
    {
        this.element.className = 'page-section';
        this.element.innerHTML = `
        <form>
            <h1>${_t('auth/login')}</h1>
            
            <label class="block full-width">
                ${_t('users/email')}
                <input class="full-width" name="email" type="text" required>
            </label>
            <label class="block full-width">
                ${_t('auth/password')}
                <input class="full-width" name="password" type="password" required>
            </label>
            <label class="block full-width">
                <input name="remember" type="checkbox">
                ${_t('auth/remember_me')}
            </label>

            <button class="primary full-width" name="submit">${_t('auth/login')}</button>
            <p class="text-center">
                ${_t('auth/or')}
            </p>
            <p class="text-center">
                <a class="singup text-center">${_t('auth/create_new_account')}</a>
            </p>
        </form>`;

        this.form = this.element.querySelector('form')!;
        this.form.addEventListener('submit', this._on_submit.bind(this));
        
        this._submit_button = this.element.querySelector("[name='submit']")!;
        this._submit_button.addEventListener('click', () => { this.form.classList.add('submitted') });

        this.element.querySelector('a.singup')!.addEventListener('click', async () => {
            await Scene_Manager.open('auth/signup');
            this._updater.run();
        });
    }

    private async _on_submit(event: Event)
    {
        event.preventDefault();
        this._submit_button.disabled = true;
        
        const messages = {};
        const result = await Auth.login(DOM.parse_form(this.form), messages)
        this._submit_button.disabled = false;
        if( !result ){
            print_global_messages(messages);
            return;
        }
    }
}
