import { Auth } from "../auth";
import { _t, Locale } from "../localization";
import * as DOM from "../ui/DOM";
import { Scene, Scene_Manager } from "../scenes";
import { Country_Input } from "../ui/country_input";
import { Language_Input } from "../ui/language_input";
import { print_global_messages } from "../ui/global_message";
import { Updater } from "../updater";
import { Users } from "../users";
import globals from "../.env";

export class Profile_Personal_Info_Scene extends Scene
{
    form: HTMLFormElement;

    constructor(updater: Updater)
    {
        super(updater, 'profile/personal_info');

        this.user_state = 'logged_in'

        this.element.className = 'page-section';
        this.element.innerHTML = `
        <form>
            <h1>${_t('users/personal_info')}</h1>
            <label class="block full-width">
                <span data-lang="users/email">Email:</span>
                <input class="full-width" name="email" type="email" disabled>
            </label>
            <label class="block full-width">
                <span data-lang="users/first_name">First Name:</span>
                <input class="full-width" name="first_name" type="text" required>
            </label>
            <label class="block full-width">
                <span data-lang="users/last_name">Last Name:</span>
                <input class="full-width" name="last_name" type="text" required>
            </label>

            <hr/>

            <span data-lang="users/gender">Gender:</span>
            <div style="margin: 10px 0 20px">
                <label>
                    <input name="gender" type="radio" value="male" required>
                    <span data-lang="users/male">Male</span>
                </label><br/>
                <label>
                    <input name="gender" type="radio" value="female" required>
                    <span data-lang="users/female">Female</span>
                </label><br/>
                <label>
                    <input name="gender" type="radio" value="">
                    (...)
                </label>
            </div>

            <label class="block full-width hidden-preference">
                <span data-lang="users/gender_preference">Gender preference:</span>
                <select class="full-width" name="gender_preference" required>
                    <option value="same" data-lang="users/gender_preference/same">Same</option>
                    <option value="all" data-lang="users/gender_preference/all">All</option>
                </select>
            </label>

            <label class="block full-width">
                <span data-lang="users/date_of_birth">Date of birth:</span>
                <input class="full-width" name="date_of_birth" type="date" required>
            </label>
            <label class="block full-width hidden-preference">
                <span data-lang="users/max_age_diff">Maximum age difference:</span>
                <input class="full-width" name="max_age_diff" type="number" required>
            </label>
            <hr/>
            
            <div class="hidden-preference">
                <p data-lang="users/address_info"></p>
                <label class="block full-width">
                    <span data-lang="users/address">Address:</span>
                    <input class="full-width" name="address" type="text" required>
                </label>
                <label class="block full-width">
                    <span data-lang="users/city">City:</span>
                    <input class="full-width" name="city" type="text" required>
                </label>
                <label class="block full-width country-section">
                    <span data-lang="users/country">Country:</span>
                </label>
                <label class="block full-width">
                    <span data-lang="users/search_radius">Search radius (km):</span>
                    <input class="full-width" name="radius" type="number" required>
                </label>
                <hr/>
            </div>

            <label class="block full-width lang-section">
                <span data-lang="users/lang">Language:</span>
            </label>
            <!-- label class="block full-width" style="display: none">
                <span data-lang="users/phone">Phone:</span>
                <input class="full-width" name="phone" type="text">
            </label -->
            
            <div class="flex-equal horizontal-rounded-content" style="margin-top: 40px">
                <button class="primary" name="submit" data-lang="general/save">
                    Save
                </button>
                <button name="cancel" type="button" data-lang="general/cancel">
                    Cancel
                </button>
            </div>
        </form>`;

        this.form = this.element.querySelector('form')!;
        this.form.addEventListener('submit', this._on_submit.bind(this));
        
        const gender_inputs = this.element.querySelectorAll('[name="gender"]') as NodeListOf<HTMLInputElement>;
        for(const input of gender_inputs)
            input.addEventListener('input', (event) => this._update_gender_preference_input((event.target as HTMLInputElement).value));

        this._country_element = new Country_Input;
        this._country_element.element.classList.add('full-width');
        this.form.querySelector('.country-section')!.append(this._country_element.element);
        
        this._lang_element = new Language_Input;
        this._lang_element.element.classList.add('full-width');
        this.form.querySelector('.lang-section')!.append(this._lang_element.element);

        this._save_button = this.element.querySelector("[name='submit']")!;
        this._save_button.addEventListener('click', () => { this.form.classList.add('submitted') });

        this._cancel_button = this.element.querySelector("[name='cancel']")!;
        this._cancel_button.addEventListener('click', () => {
            Scene_Manager.go_back()
            this._updater.run()
        });
    }

    load_form()
    {
        if( !Auth.current_user )
            return false;

        const user = Auth.current_user;
        DOM.set_form_values(this.form, {
            'email': user.email,
            'first_name': user.first_name,
            'last_name': user.last_name,
            'gender': user.gender,
            'gender_preference': user.gender_preference,
            'date_of_birth': user.date_of_birth,
            'max_age_diff': user.max_age_diff,
            'address': user.address,
            'city': user.city,
            'country': user.country,
            'radius': user.radius,
            'lang': user.lang,
            'phone': user.phone,
        });

        this._update_gender_preference_input(user.gender);
    }

    async open(args: {[key: string]: string} = {})
    {
        await super.open();

        this._is_setup = !!args['is_setup'];
        
        if( this._is_setup ){
            this._cancel_button.remove();
            this.element.classList.add('show-preferences');
        }else{
            this._save_button.parentElement?.append(this._cancel_button);
            this.element.classList.remove('show-preferences');
        }

        this.load_form();
    }

    private _cancel_button: HTMLButtonElement;
    private _country_element: Country_Input;
    private _lang_element: Language_Input;
    private _is_setup = false;
    private _save_button: HTMLButtonElement;

    private async _on_submit(event: Event)
    {
        event.preventDefault();

        if( !Auth.current_user )
            return false;

        const messages = {};
        const country = Auth.current_user.country;
        const lang = Auth.current_user.lang;
        const result = await Users.update(Auth.current_user, DOM.parse_form(this.form), messages);
        print_global_messages(messages);
        if( !result )
            return;

        if( country !== result.country )
            Locale.country = result.country;

        if( lang !== result.lang )
            Locale.set_lang(result.lang);

        if( this._is_setup ){
            await Scene_Manager.open(globals.HOME_PATH);
            location.reload();
            return;
        }

        await Scene_Manager.open('profile');

        this._updater.run();
    }

    private _update_gender_preference_input(value: string)
    {
        const gender_input = this.form.querySelector('[name="gender"]:checked') as HTMLSelectElement;
        const preference_input = this.form.querySelector('[name="gender_preference"]') as HTMLSelectElement;
        if( gender_input.value ){
            preference_input.disabled = false;
        }else{
            preference_input.disabled = true;
            preference_input.value = 'all';
        }
    }
}
