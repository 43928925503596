import { Chat, Chats } from "../chats";
import { _, esc_attr, esc_html } from "./DOM";

type Args = {
    has_unread_count?: true;
}

export class Chat_Picker
{
    element: HTMLDivElement;

    constructor(chat_click_cb: (chat: Chat) => void, args: Args = {})
    {
        this._has_unread_count = args.has_unread_count ?? true;
        this._on_chat_clicked = chat_click_cb;

        this.element = _('div', {class: 'chat-list'});
    }

    async print(chats: Chat[])
    {
        for(const chat of this._sort_chats(chats))
            this._print_chat(chat);
    }

    private _has_unread_count: boolean;

    private _on_chat_clicked: (chat: Chat) => void;

    private _print_chat(chat: Chat)
    {
        let item_element = this.element.querySelector(`[data-uid="${chat.uid}"]`) as HTMLDivElement | null;
        if( !item_element ){
            item_element = _('div', {
                class: 'main-list-item chat-list-item button',
                onclick: () => this._on_chat_clicked(chat),
                'data-uid': chat.uid
            });
        }

        item_element.innerHTML = '';
        item_element.append(
            _('div', {class: 'image-container'}, [
                _('img', {src: esc_attr(Chats.get_image_url(chat.image, 'main_list_item'))})
            ]),
            _('div', {class: 'info-container'}, [
                _('div', {class: 'name'}, esc_html(chat.name))
            ]),
        );

        if( this._has_unread_count )
            this._update_unread_count(chat, item_element, chat.member_unread_count);

        this.element.append(item_element);
    }

    private _sort_chats(chats: Chat[]): Chat[]
    {
        return chats.sort((a, b) => {
            if( !b.last_message_datetime )
                return -1;
            else if( !a.last_message_datetime )
                return 1;
            else
                return b.last_message_datetime.getTime() - a.last_message_datetime.getTime();
        });
    }

    private _update_unread_count(chat: Chat, chat_element: HTMLElement, count: number)
    {
        const image_el = chat_element.querySelector('.image-container')!;
        let counter_el = image_el.querySelector('.unread-counter');

        if( count === 0 ){
            if( counter_el )
                counter_el.remove();
        }else{
            if( !counter_el ){
                counter_el = document.createElement('div');
                counter_el.className = 'unread-counter';
                image_el.append(counter_el);
            }
                
            counter_el.innerHTML = '+' + esc_html(chat.member_unread_count.toString());
        }
    }
}