import { _t } from '../localization';
import { Scene } from "../scenes";
import { Updater } from "../updater";

export class No_Connection_Scene extends Scene
{
    constructor(updater: Updater)
    {
        super(updater, 'no_connection');
        this.user_state = 'logged_out';
        this.menu_type = '';

        const container = document.createElement('div');
        container.style.left = '0';
        container.style.position = 'absolute';
        container.style.right = '0';
        container.style.top = '50%';
        container.style.transform = 'translate(0, -50%)';
        this.element.appendChild(container);

        this._content_element = document.createElement('p');
        this._content_element.className = 'text-center';
        container.appendChild(this._content_element);

        const button = document.createElement('button');
        button.style.display = 'block';
        button.style.margin = '20px auto';
        button.textContent = _t('general/try_again');
        container.appendChild(button);

        button.addEventListener('click', () => {
            location.reload();
        });
    }

    async open(args?: Record<string, string>)
    {
        super.open();

        if( args ){
            if( args['reason'] === 'no_internet' )
                this._content_element.textContent = _t('general/no_internet');
            else if( args['reason'] === 'no_server' )
                this._content_element.textContent = _t('general/no_server');
        }
    }

    _content_element!: HTMLDivElement;
}
