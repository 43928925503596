import * as Capacitor									from '@capacitor/app';
import { ScreenOrientation }							from '@capacitor/screen-orientation';

import * as io											from "socket.io-client";

import { API, parse_API_messages }						from "./api";
import { Auth }    										from "./auth";
import { Chat_Manager }									from "./chats";
import { Locale, _t }									from "./localization";
import { Network }										from './network';
import { Notification_Manager }							from './notifications';
import { Push_Notifications }							from './push_notifications';
import { Scene_Manager }    							from "./scenes";
import { Auth_Login_Scene }								from "./scenes/auth_login_scene";
import { Auth_Scene }									from "./scenes/auth_scene";
import { Auth_Signup_Scene } 							from "./scenes/auth_signup_scene";
import { Auth_Verify_Email_Scene }						from "./scenes/auth_verify_email_scene";
import { Activities_Create_Scene }						from "./scenes/activities_create_scene";
import { Activities_Discover_Scene }					from "./scenes/activities_discover_scene";
import { Activities_Info_Scene }						from "./scenes/activities_info_scene";
import { Activities_Scene }								from "./scenes/activities_scene";
import { Chat_Scene }									from "./scenes/chat_scene";
import { Chat_Image_Scene }								from "./scenes/chat_image_scene";
import { Chat_Info_Scene }								from "./scenes/chat_info_scene";
import { Chats_Scene }									from "./scenes/chats_scene";
import { No_Connection_Scene }							from "./scenes/no_connection_scene";
import { Not_Found_Scene }								from "./scenes/not_found_scene";
import { Notifications_Scene }							from "./scenes/notifications_scene";
import { Profile_Personal_Info_Scene }					from "./scenes/profile_personal_info_scene";
import { Profile_Preferences_Scene } 					from "./scenes/profile_preferences_scene";
import { Profile_Picture_Scene }						from "./scenes/profile_picture_scene";
import { Profile_Scene }								from "./scenes/profile_scene";
import { Users_Blocked_Scene }							from "./scenes/users_blocked_scene";
import { Users_Friends_Scene }							from "./scenes/users_friends_scene";
import { Users_Info_Scene }								from "./scenes/users_info_scene";
import { Users_Requests_In_Scene }						from "./scenes/users_requests_in_scene";
import { Users_Requests_Out_Scene }						from "./scenes/users_requests_out_scene";
import { print_global_messages }						from "./ui/global_message";
import { Nav_Menu }       								from "./ui/nav_menu";
import { Updater }										from "./updater";
import { User }											from "./users";

export class App
{
	async run()
	{
		this._init_screen();

		this._updater = new Updater(this.update.bind(this));
		Capacitor.App.addListener('resume', () => {
			this._updater.run();
		});

		Locale.init();

		document.getElementById('page-body-content')!.innerHTML = '<div class="center-abs text-center">' + _t('general/loading') + '</div>';
		
		this._nav_menu = new Nav_Menu(this._updater);

		this._init_scenes();
		await this._init_auth();
		Push_Notifications.init(this._updater);
		
		// Calls update, so must be initialized after _nav_menu.
		// Sets scene, so must be after _init_auth to avoid redirecting for authentication.
		this._init_deeplinks();

		if( Auth.current_user && Auth.current_user.status === 'active' )
			await this._run_with_socket();
		else
			await this._run_without_socket();
	}

	async update()
	{
		await Scene_Manager.update();

		await this._nav_menu.update();
		this._update_bottom_menu();
		Locale.update();
	}
	
	private _nav_menu!: Nav_Menu;
	private _socket?: io.Socket;
	private _updater!: Updater;

	private async _init_auth(): Promise<User | undefined>
	{
		Auth.init();
		if( !Auth.token )
			return;

		const messages = {};

		const user = await Auth.resume_session(messages);
		if( !user ){
			print_global_messages(messages);
			return;
		}

		return user;
	}

	private _init_deeplinks()
	{
		Capacitor.App.addListener('appUrlOpen', async (event: Capacitor.URLOpenListenerEvent) => {
			
			// If we login we need to remember the redirect.
			const old_query = new URLSearchParams(location.search);
			if( old_query.has('redirect') )
				event.url += (event.url.includes('?') ? '&' : '?') + 'redirect=' + old_query.get('redirect');

			await Scene_Manager.open_url(event.url);
			this._updater.run();
		});
	}

	private _init_scenes()
	{
		Scene_Manager.init(this._updater);

		Scene_Manager.add(new No_Connection_Scene(this._updater));
		Scene_Manager.add(new Not_Found_Scene(this._updater));

		Scene_Manager.add(new Auth_Login_Scene(this._updater));
		Scene_Manager.add(new Auth_Scene(this._updater));
		// Scene_Manager.add(new Auth_Signup_Scene(this._updater));
		Scene_Manager.add(new Auth_Verify_Email_Scene(this._updater));

		Scene_Manager.add(new Profile_Scene(this._updater));
		Scene_Manager.add(new Profile_Personal_Info_Scene(this._updater));
		Scene_Manager.add(new Profile_Preferences_Scene(this._updater));
		Scene_Manager.add(new Profile_Picture_Scene(this._updater));

		Scene_Manager.add(new Activities_Scene(this._updater));
		Scene_Manager.add(new Activities_Create_Scene(this._updater));
		Scene_Manager.add(new Activities_Discover_Scene(this._updater));
		Scene_Manager.add(new Activities_Info_Scene(this._updater));
		
		Scene_Manager.add(new Chat_Scene(this._updater));
		Scene_Manager.add(new Chat_Image_Scene(this._updater));
		Scene_Manager.add(new Chat_Info_Scene(this._updater));
		Scene_Manager.add(new Chats_Scene(this._updater));

		Scene_Manager.add(new Users_Blocked_Scene(this._updater));
		Scene_Manager.add(new Users_Friends_Scene(this._updater));
		Scene_Manager.add(new Users_Info_Scene(this._updater));
		Scene_Manager.add(new Users_Requests_In_Scene(this._updater));
		Scene_Manager.add(new Users_Requests_Out_Scene(this._updater));
		
		Scene_Manager.add(new Notifications_Scene(this._updater));
	}

	private async _init_screen()
	{
		try{
			await ScreenOrientation.lock({ orientation: 'portrait' });
		}catch(e){
			console.log('Screen orientation not supported.');
		}
	}

	private async _run_with_socket()
	{
		this._socket = io.connect(API.url, { auth: {token: Auth.token} });

		await Network.init(this._socket);

		this._socket.on('log', (data: any) => {
			const log = {};
			parse_API_messages(log, data);
			print_global_messages(log);	
		});

		let first_connection = true;

		this._socket.on('connect', async () => {
			if( !first_connection )
				return;
			
			await Chat_Manager.init(this._socket!);
			await Notification_Manager.init(this._socket!);
			
			if( !Scene_Manager.active_scene )
				await Scene_Manager.open_url();
			this._updater.run();
			
			first_connection = false;
		});
	}

	private async _run_without_socket()
	{
		await Network.init();
		await Scene_Manager.open_url('');
		this._updater.run();
	}

	private _update_bottom_menu()
	{
		const footer_element = document.getElementById('page-footer')!;
		footer_element.innerHTML = '';
		if( Scene_Manager.active_scene && Scene_Manager.active_scene.tools_element ){
			footer_element.append(Scene_Manager.active_scene.tools_element);
			footer_element.style.display = 'block';
		}else{
			footer_element.style.display = 'none';
		}
	}
}
