import globals from '../.env';
import { API } from "../api";
import { Auth } from "../auth";
import { is_native } from '../core';
import { _t } from '../localization';
import { Scene } from "../scenes";
import { print_global_messages } from "../ui/global_message";
import { Updater } from "../updater";

export class Auth_Scene extends Scene
{
    constructor(updater: Updater)
    {
        super(updater, 'auth');
        this.user_state = 'logged_out';
        this.menu_type = 'language';
        this._init_element();

        addEventListener('message', (event) => {
            if( event.origin === API.server_url && event.data['oauth'] )
                this._login_with_oauth_token(event.data['oauth']);
        });
    }

    async open(args?: Record<string, string>)
    {
        super.open(args);

        if( args && args.token )
            this._login_with_oauth_token(args.token);
    }

    private _generate_oauth_button(name: string, url: string, image: string)
    {
        const button = document.createElement('a');
        button.className = 'button text-center';
        button.style.borderRadius = '10px';
        button.style.display = 'block';
        button.style.margin = '10px auto';
        button.style.padding = '0';
        button.style.position = 'relative';
        button.style.textDecoration = 'none';
        button.target = '_blank';
        button.addEventListener('click', () => {
            const oauth_window = open(url, '_blank')!;

            // For the web app.
            setInterval(() => {
                oauth_window.postMessage('oauth', API.server_url)
            }, 1000);
        });

        const img = document.createElement('img');
        img.alt = name;
        img.src = image;
        img.style.bottom = '10px'
        img.style.left = '10px'
        img.style.position = 'absolute';
        img.style.top = '10px'
        img.style.width = '50px';
        button.appendChild(img);

        const text = document.createElement('span');
        text.style.fontSize = '20px';
        text.style.lineHeight = '70px';
        text.textContent = _t('auth/login_with_' + name);
        button.appendChild(text);

        return button;
    }

    private _init_element()
    {
        this.element.className = 'page-section';

        if( !is_native() ){
            const download_header = document.createElement('h1');
            download_header.className = 'text-center';
            download_header.textContent = _t('auth/download');
            this.element.appendChild(download_header);

            const play_button = document.createElement('img');
            play_button.alt = 'Get it from Google Play';
            play_button.src = '/assets/images/google-play.png';
            play_button.style.cursor = 'pointer';
            play_button.style.display = 'block';
            play_button.style.margin = '10px auto';
            play_button.style.width = '150px';
            play_button.addEventListener('click', () => {
                open(globals.PLAY_STORE_URL, '_blank');
            });
            this.element.appendChild(play_button);
            
            const separator = document.createElement('div');
            separator.className = 'text-center';
            separator.style.position = 'relative';
            separator.style.margin = '20px auto';
            separator.innerHTML = '<hr/>' + _t('general/or') + '<hr/>';
            this.element.appendChild(separator);
        }

        const login_header = document.createElement('h1');
        login_header.className = 'text-center';
        login_header.textContent = _t('auth/login');
        this.element.appendChild(login_header);

        const google_button = this._generate_oauth_button('google', `${API.url}/auth/google`, '/assets/images/google.png');
        this.element.appendChild(google_button);

        const facebook_button = this._generate_oauth_button('facebook', `${API.url}/auth/facebook`, '/assets/images/facebook.png');
        this.element.appendChild(facebook_button);
    }

    private async _login_with_oauth_token(token: string)
    {
        const messages = {};

        await Auth.login_with_oauth({'token': token}, messages);
        print_global_messages(messages);
    }
}
