import { API, API_Data, parse_API_messages } from "./api";
import * as core from "./core";
import { Users, User_Summary_Data, User } from "./users";

type Discovery_Result = {
    activity: Activity;
    token: string;
};

type Activity_Action = {
    type: string;
    inviter: number;
    invitees: number[];
};

export class Activity
{
    uid = '';
    name = '';
    description = '';
    min_guests = 0;
    max_guests = 0;
    max_invitees = 0;
    guest_count = 0;
    gender = '';
    date_of_birth?: Date;
    max_age_diff = 0;
    paid = false;
    datetime?: Date;
    deadline?: Date;
    address = '';
    city = '';
    country = '';
    image = '';
    status = '';
    user?: User_Summary_Data;
    guests = new Array<User_Summary_Data>;
    created?: Date;
    action?: Activity_Action;
    chat = '';
}

export class Activities
{
    static async cancel(activity: Activity, log: core.Log_Messages = {}): Promise<Activity | undefined>
    {
        const response = await API.PUT('/activity/' + encodeURIComponent(activity.uid) + '/cancel');
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        return this.parse(result.data.activity);
    }

    static async create(data: API_Data, log: core.Log_Messages = {}): Promise<Activity | undefined>
    {
        const response = await API.POST('/activity', this.to_UTC(data));
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        return this.parse(result.data.activity);
    }

    static async decline(activity: Activity, token?: string, log: core.Log_Messages = {}): Promise<Activity | undefined>
    {
        const response = await API.PUT('/activity/' + encodeURIComponent(activity.uid) + '/decline', {'token': token});
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        return this.parse(result.data.activity);
    }

    static async decline_and_discover(activity: Activity, token?: string, log: core.Log_Messages = {}): Promise<Discovery_Result | undefined>
    {
        const response = await API.PUT('/discover/' + encodeURIComponent(activity.uid) + '/decline', {'token': token});
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        return {
            'activity': this.parse(result.data.activity)!,
            'token': result.data.token
        };
    }

    static async discover(log: core.Log_Messages = {}): Promise<Discovery_Result | undefined>
    {
        const response = await API.GET('/discover');
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        const new_activity = this.parse(result.data.activity);
        if( !new_activity )
            return;

        return {
            'activity': new_activity,
            'token': result.data.token
        };
    }

    static async get(uid: string, log: core.Log_Messages = {}): Promise<Activity | undefined>
    {
        const response = await API.GET('/activity/' + encodeURIComponent(uid));
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        return this.parse(result.data.activity);
    }

    static get_image_url(image?: string, context='')
    {
        if( image )
            return image + '?context=' + encodeURIComponent(context);
        else
            return '/assets/images/default-activity-image.png';
    }

    static async get_images(query: string, log: core.Log_Messages = {}): Promise<{[key: string]: string}[] | undefined>
    {
        const response = await API.GET('/activities/images?query=' + encodeURIComponent(query));
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        const urls = new Array<{[key: string]: string}>;
        for(const idx in result.data.images)
            urls.push(result.data.images[idx]);

        return urls;
    }

    static async invite(activity: Activity, friend: User, log: core.Log_Messages = {}): Promise<Activity | undefined>
    {
        const response = await API.PUT('/activity/' + encodeURIComponent(activity.uid) + '/invite', {'friend': friend.uid});
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        return this.parse(result.data.activity);
    }

    static async join(activity: Activity, token?: string, log: core.Log_Messages = {}): Promise<Activity | undefined>
    {
        const response = await API.PUT('/activity/' + encodeURIComponent(activity.uid) + '/join', {'token': token});
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        return this.parse(result.data.activity);
    }

    static async join_and_discover(activity: Activity, token?: string, log: core.Log_Messages = {}): Promise<Discovery_Result | undefined>
    {
        const response = await API.PUT('/discover/' + encodeURIComponent(activity.uid) + '/join', {'token': token});
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        return {
            'activity': this.parse(result.data.activity)!,
            'token': result.data.token
        };
    }

    static async leave(activity: Activity, log: core.Log_Messages = {}): Promise<Activity | undefined>
    {
        const response = await API.PUT('/activity/' + encodeURIComponent(activity.uid) + '/leave');
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        return this.parse(result.data.activity);
    }

    static parse(data: any, activity: Activity | false = false): Activity | undefined
    {
        if( !core.is_object(data) )
            return;

        if( !activity )
            activity = new Activity;

        activity.uid = data.uid || '';
        activity.name = data.name || '';
        activity.description = data.description || '';
        activity.min_guests = data.min_guests || 0;
        activity.max_guests = data.max_guests || 0;
        activity.max_invitees = data.max_invitees || 0;
        activity.guest_count = data.guest_count || 0;
        activity.gender = data.gender || '';
        activity.date_of_birth = core.UTC(data.date_of_birth);
        activity.max_age_diff = data.max_age_diff || 0;
        activity.paid = data.paid || false;
        activity.datetime = core.UTC(data.datetime);
        activity.deadline = core.UTC(data.deadline);
        activity.address = data.address || '';
        activity.city = data.city || '';
        activity.country = data.country || '';
        activity.image = data.image || '';
        activity.status = data.status || '';
        activity.user = data.user;
        activity.guests = Users.parse_summary_array(data.guests);
        activity.created = core.UTC(data.created);
        activity.action = data.action || {};
        activity.chat = data.chat || '';

        return activity;
    }

    static async save(activity: Activity, token?: string, log: core.Log_Messages = {}): Promise<Activity | undefined>
    {
        const response = await API.PUT('/activity/' + encodeURIComponent(activity.uid) + '/save', {'token': token});
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        return this.parse(result.data.activity);
    }

    static async save_and_discover(activity: Activity, token?: string, log: core.Log_Messages = {}): Promise<Discovery_Result | undefined>
    {
        const response = await API.PUT('/discover/' + encodeURIComponent(activity.uid) + '/save', {'token': token});
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        return {
            'activity': this.parse(result.data.activity)!,
            'token': result.data.token
        };
    }

    static async search(log: core.Log_Messages = {}): Promise<Array<Activity> | undefined>
    {
        const response = await API.GET('/activities');
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        const activities = new Array<Activity>;
        for(const idx in result.data.activities){
            const activity = this.parse(result.data.activities[idx]);
            if( activity )
                activities.push(activity);
        }

        return activities;
    }

    static async update(activity: Activity, data: API_Data, log: core.Log_Messages = {}): Promise<Activity | undefined>
    {
        const response = await API.PUT('/activity/' + encodeURIComponent(activity.uid), this.to_UTC(data));
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        return this.parse(result.data.activity);
    }

    static to_UTC(activity: Activity | {[key: string]: unknown}): Activity | {[key: string]: unknown}
    {
        const copy = Object.assign({}, activity);

        if( typeof copy.datetime === 'string' )
            copy.datetime = core.date_to_UTC(copy.datetime);

        if( typeof copy.deadline === 'string' )
            copy.deadline = core.date_to_UTC(copy.deadline);

        return copy;
    }
}
