import globals from "../.env";
import { _t } from "../localization";
import { Network } from "../network";
import { Notification, Notification_Manager, Notifications } from "../notifications";
import { Push_Notification, Push_Notifications } from "../push_notifications";
import { Scene, Scene_Manager } from "../scenes";
import * as DOM from "../ui/DOM";
import { Updater } from "../updater";

export class Notifications_Scene extends Scene
{
    constructor(updater: Updater)
    {
        super(updater, 'notifications');

        this.user_state = 'active';

        this._init_element();
        this._init_nothing_found_element();

        addEventListener('receive_notifications', ((event: CustomEvent) => {
            if( this.is_active )
                this._on_receive_notifications(event);
        }) as EventListener);

        addEventListener('network_connection', () => {
            if( this.is_active && Network.is_connected() )
                Notification_Manager.mark_as_seen();
        });

        Push_Notifications.register_callback((notifications: Push_Notification[]) => {
            // Disabled as hearing a sound makes it harder to miss a notification when already on the page.
            // There is namely no bubble when on the page, only a new message sliding in at the top, which is easy to miss.
            // if( this.is_active )
            //    this._remove_push_notifications(notifications);
        });
    }

    async open()
    {
        await super.open();

        this._oldest_notification = undefined;
        this._list_element.innerHTML = '';
        this._loading_element.style.display = 'block';

        await Notification_Manager.mark_as_seen();

        this._remove_push_notifications();

        setTimeout(this._check_load_area.bind(this), 10);
    }

    private _is_retrieving_messages = false; // When the connection is down, multiple calls can cause a chain reaction.
    private _list_element!: HTMLDivElement;
    private _loading_element!: HTMLDivElement;
    private _nothing_found_element!: HTMLDivElement;
    private _oldest_notification?: Notification;

    private _check_load_area()
    {
        if( DOM.is_in_view(this._loading_element, document.body) )
            this._load_previous_notifications();
    }

    private _init_element()
    {
        this.element.style.display = 'flex';
        this.element.style.flexDirection = 'column';
        this.element.style.height = '100%';
        this.element.style.position = 'absolute';
        this.element.style.width = '100%';
        this.element.innerHTML = `
            <header style="border-bottom: 1px solid var(--light-detail-color); display: flex">
                <h1 style="margin: 20px; flex-grow: 1">${_t('notifications')}</h1>
            </header>
            <div style="flex-grow: 1; overflow-x: hidden; overflow-y: auto;">
                <div class="notifications-container"></div>
                <div class="loading-area text-center" style="padding: 20px;">
                    ${_t('general/loading')}
                </div>
            </div>`;

        const header = this.element.querySelector('header') as HTMLElement;
        if( globals.IS_DEV ){
            const load_button = document.createElement('div');
            load_button.className = 'relative button flush';
            load_button.style.borderLeft = '1px solid var(--light-detail-color)';
            load_button.style.flexShrink = '0';
            load_button.style.width = '70px';
            load_button.innerHTML = '<span class="center-abs" style="font-size: 2em; font-weight: bold">&#x1F847;</span>';
            load_button.addEventListener('click', () => {
                Notification_Manager.get_recent();
            });
            header.appendChild(load_button);
        }

        this._list_element = this.element.querySelector('.notifications-container') as HTMLDivElement;

        this._loading_element = this.element.querySelector('.loading-area') as HTMLDivElement;
        this._loading_element.parentElement!.addEventListener('scroll', this._check_load_area.bind(this));
    }

    private _init_nothing_found_element()
    {
        this._nothing_found_element = document.createElement('p');
        this._nothing_found_element.className = 'text-center';
        this._nothing_found_element.innerHTML = _t('general/nothing_found');
    }

    private async _load_previous_notifications()
    {
        if( this._is_retrieving_messages )
            return;
        
        this._is_retrieving_messages = Notification_Manager.get({
            'before': this._oldest_notification?.id,
            'context': 'old',
            'pos': 'bottom',
        });
        
        setTimeout(() => { this._is_retrieving_messages = false; }, 2000);
    }

    private async _on_notification_clicked(notification: Notification, element: HTMLElement)
    {
        Notification_Manager.mark_as_read(notification);

        if( !notification.url ){
            element.dataset.status = 'read';
            return;
        }

        await Scene_Manager.open_url(notification.url);
        this._updater.run();
    }

    private async _on_receive_notifications(event: CustomEvent<{ notifications: Notification[], is_first_loaded: boolean }>): Promise<void>
    {
        this._is_retrieving_messages = false;

        const { notifications, is_first_loaded } = event.detail;

        if( is_first_loaded && notifications.length === 0 ){
            if( this._oldest_notification === undefined )
                this._list_element.append(this._nothing_found_element);
            
            this._loading_element.style.display = 'none';
            return;
        }

        notifications.forEach(notification => {
            if( !this._oldest_notification || notification.id < this._oldest_notification.id )
                this._oldest_notification = notification;
        });

        this._print_notifications(notifications);
        this._sort_elements();

        await Notification_Manager.mark_as_seen();
        this._updater.run();

        // Give some time to load the UI.
        setTimeout(this._check_load_area.bind(this), 10);
    }

    private _generate_notification_element(notification: Notification): HTMLElement
    {
        const item_element = document.createElement('div');
        item_element.id = 'notification-' + notification.id;
        item_element.className = 'main-list-item notification-list-item button';
        item_element.dataset.id = notification.id.toString();
        item_element.dataset.status = notification.status;
        item_element.innerHTML = `
            <div class="image-container">
                <img src="${Notifications.get_image_url(notification.image, 'main_list_item')}"/>
            </div>
            <div class="info-container" style="padding: 15px 0">
                ${notification.content}
            </div>`;
        item_element.addEventListener('click', this._on_notification_clicked.bind(this, notification, item_element));

        return item_element;
    }

    private _print_notifications(notifications: Notification[])
    {
        notifications.forEach(notification => {
            if( this._oldest_notification === undefined || notification.id < this._oldest_notification.id )
                this._oldest_notification = notification;

            if( !document.getElementById('notification-' + notification.id) )
                this._list_element.append(this._generate_notification_element(notification));
        });
    }

    private async _remove_push_notifications(notifications?: Push_Notification[])
    {
        if( !notifications)
            notifications = await Push_Notifications.get_all();

        Push_Notifications.remove(notifications.filter(
            notification => notification.tag.startsWith('notification='))
        );
    }

    private _sort_elements()
    {
        const elements = Array.from(this._list_element.children) as HTMLElement[];
        elements.sort((a, b) => {
            const id_a = Number(a.dataset.id);
            const id_b = Number(b.dataset.id);
            return id_b - id_a;
        });

        this._list_element.innerHTML = '';
        for(const element of elements)
            this._list_element.appendChild(element);
    }
}
