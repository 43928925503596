import * as Capacitor									from '@capacitor/app';
import { ScreenOrientation }							from '@capacitor/screen-orientation';

import * as io											from "socket.io-client";

import globals											from './.env';
import { API, parse_API_messages }						from "./api";
import { Auth }    										from "./auth";
import { Chat_Manager }									from "./chats";
import * as core										from './core';
import { Locale, _t }									from "./localization";
import { Network }										from './network';
import { Notification_Manager }							from './notifications';
import { Scene, Scene_Manager, Scene_Request }			from "./scenes";
import { Auth_Login_Scene }								from "./scenes/app/auth_login_scene";
import { Auth_Scene }									from "./scenes/app/auth_scene";
import { Auth_Signup_Scene } 							from "./scenes/app/auth_signup_scene";
import { Auth_Verify_Email_Scene }						from "./scenes/app/auth_verify_email_scene";
import { Activities_Create_Scene }						from "./scenes/app/activities_create_scene";
import { Activities_Discover_Scene }					from "./scenes/app/activities_discover_scene";
import { Activities_Info_Scene }						from "./scenes/app/activities_info_scene";
import { Activities_Scene }								from "./scenes/app/activities_scene";
import { Chat_Scene }									from "./scenes/app/chat_scene";
import { Chat_Image_Scene }								from "./scenes/app/chat_image_scene";
import { Chat_Info_Scene }								from "./scenes/app/chat_info_scene";
import { Chats_Scene }									from "./scenes/app/chats_scene";
import { No_Connection_Scene }							from "./scenes/app/no_connection_scene";
import { Not_Found_Scene }								from "./scenes/app/not_found_scene";
import { Notifications_Scene }							from "./scenes/app/notifications_scene";
import { Profile_Personal_Info_Scene }					from "./scenes/app/profile_personal_info_scene";
import { Profile_Preferences_Scene } 					from "./scenes/app/profile_preferences_scene";
import { Profile_Picture_Scene }						from "./scenes/app/profile_picture_scene";
import { Profile_Scene }								from "./scenes/app/profile_scene";
import { Profile_Settings_Scene }						from "./scenes/app/profile_settings_scene";
import { Users_Blocked_Scene }							from "./scenes/app/users_blocked_scene";
import { Users_Friends_Scene }							from "./scenes/app/users_friends_scene";
import { Users_Info_Scene }								from "./scenes/app/users_info_scene";
import { Users_Requests_In_Scene }						from "./scenes/app/users_requests_in_scene";
import { Users_Requests_Out_Scene }						from "./scenes/app/users_requests_out_scene";
import { print_global_message, print_global_messages }	from "./ui/global_message";
import { Nav_Menu }       								from "./ui/nav_menu";
import Updater											from "./updater";
import { Profile_Remove_Account_Scene } 				from './scenes/app/profile_remove_account_scene';

export class App
{
	async run()
	{
		if( core.is_android_web() )
			location.href = globals.ANDROID_STORE_URL;
		else if( core.is_ios_web() )
			location.href = globals.IOS_STORE_URL;

		Updater.init(this.update.bind(this));
		Capacitor.App.addListener('resume', async () => {
			dispatchEvent(new CustomEvent('resume'));
			await Updater.run();
		});

		await Locale.init();

		this._init_template();
		this._init_screen();

		document.getElementById('app-content')!.innerHTML = '<div class="center-abs text-center">' + _t('general/loading') + '</div>';
		
		this._nav_menu = new Nav_Menu();
		this._init_scenes();
		await this._init_auth();

		if( Auth.current_user && Auth.current_user.status === 'active' )
			await this._run_with_socket();
		else
			await this._run_without_socket();
	}

	async update()
	{
		await Scene_Manager.update();

		await this._nav_menu.update();
		this._update_bottom_menu();
		Locale.update();
	}
	
	private _is_opened = false;
	private _nav_menu!: Nav_Menu;
	private _socket?: io.Socket;

	private async _check_connection_redirect()
	{
		if( !this._is_opened ){

			this._is_opened = true; // Do this first avoid an exception bypass.
			try{
				if( !Network.is_connected() )
					throw 'no_internet';

				const result = await API.GET('/ping');
				if( !result || result.status !== 200 )
					throw 'no_server';

			}catch(error){
				return {path: 'no_connection', args: {'reason': error as string}};
			}
		}
	}

	private async _check_verification_redirect(scene: Scene)
	{
		if( !Auth.current_user || Auth.current_user.status !== 'unverified' )
			return;

		if( scene.user_state !== 'active' )
			return;

		if( !Auth.current_user!.verified.includes('email') ){
			return {path: 'auth/verify_email'};
		}else if( !Auth.current_user!.verified.includes('profile') ){
			return {path: 'profile/personal_info', args: {'is_setup': 'true'}};
		}else if( !Auth.current_user!.verified.includes('image') ){
			return {path: 'profile/picture', args: {'is_setup': 'true'}};
		}else{
			print_global_message('error', _t('users/verification_error'));
			return;
		}
	}

	private async _init_auth(): Promise<void>
	{
		Auth.init();

		const messages: Record<string, any> = {};

		await Auth.resume_session(messages);
		print_global_messages(messages);
	}

	/**
	 * The callback is often called immediately, so Scene_Manager and subsequently Auth and Network need to be initialized already.
	 */
	private _init_deeplinks()
	{
		Capacitor.App.addListener('appUrlOpen', async (event: Capacitor.URLOpenListenerEvent) => {

			// If we login we need to remember the redirect.
			const old_query = new URLSearchParams(location.search);
			if( old_query.has('redirect') )
				event.url += (event.url.includes('?') ? '&' : '?') + 'redirect=' + old_query.get('redirect');

			await Scene_Manager.open_url(event.url);

			await Updater.run();
		});
	}

	private _init_scenes()
	{
		Scene_Manager.init(
			document.getElementById('app-content')!,
			globals.HOME_PATH,
			() => Auth.current_user,
			{
				login_path: globals.LOGIN_PATH,
				redirect_cb: this._on_scene_redirect.bind(this)
			}
		);

		Scene_Manager.register(new No_Connection_Scene());
		Scene_Manager.register(new Not_Found_Scene());

		Scene_Manager.register(new Auth_Login_Scene());
		Scene_Manager.register(new Auth_Scene());
		// Scene_Manager.add(new Auth_Signup_Scene(this._updater));
		Scene_Manager.register(new Auth_Verify_Email_Scene());

		Scene_Manager.register(new Profile_Scene());
		Scene_Manager.register(new Profile_Personal_Info_Scene());
		Scene_Manager.register(new Profile_Preferences_Scene());
		Scene_Manager.register(new Profile_Picture_Scene());
		Scene_Manager.register(new Profile_Settings_Scene());
		Scene_Manager.register(new Profile_Remove_Account_Scene());

		Scene_Manager.register(new Activities_Scene());
		Scene_Manager.register(new Activities_Create_Scene());
		Scene_Manager.register(new Activities_Discover_Scene());
		Scene_Manager.register(new Activities_Info_Scene());
		
		Scene_Manager.register(new Chat_Scene());
		Scene_Manager.register(new Chat_Image_Scene());
		Scene_Manager.register(new Chat_Info_Scene());
		Scene_Manager.register(new Chats_Scene());

		Scene_Manager.register(new Users_Blocked_Scene());
		Scene_Manager.register(new Users_Friends_Scene());
		Scene_Manager.register(new Users_Info_Scene());
		Scene_Manager.register(new Users_Requests_In_Scene());
		Scene_Manager.register(new Users_Requests_Out_Scene());
		
		Scene_Manager.register(new Notifications_Scene());
	}

	private async _init_screen()
	{
		try{
			await ScreenOrientation.lock({ orientation: 'portrait' });
		}catch(e){
			console.log('Screen orientation not supported.');
		}
	}

	private async _init_template()
	{
		document.title = _t('general/title');

		document.body.innerHTML = `
		<div id="app">
            <div id="page-top"></div>
            <div id="page-middle">
				<div id="page-content" style="scrollbar-gutter: stable both-edges">
					<div id="app-background"></div>
					<div id="app-content" class="page-center-content"></div>
				</div>
            </div>
            <div id="page-bottom"></div>
        </div>`;
	}

	private async _on_scene_redirect(scene: Scene): Promise<Scene_Request | undefined>
	{
		{
			const result = await this._check_connection_redirect();
			if( result )
				return result;
		}

		{
			const result = await this._check_verification_redirect(scene);
			if( result )
				return result;
		}
	}

	private async _run_with_socket()
	{
		this._socket = io.connect(API.url, {
			withCredentials: true,
		});

		await Network.init(this._socket);
		this._init_deeplinks(); // After `Network.init`!

		this._socket!.on('log', (data: any) => {
			const log = {};
			parse_API_messages(log, data);
			print_global_messages(log);	
		});

		let first_connection = true;

		this._socket!.on('connect', async () => {
			if( !first_connection )
				return;
			
			await Chat_Manager.init(this._socket!);
			await Notification_Manager.init(this._socket!);

			if( !Scene_Manager.active_scene )
				await Scene_Manager.open_url();

			await Updater.run();
			
			first_connection = false;
		});
	}

	private async _run_without_socket()
	{
		await Network.init();
		this._init_deeplinks(); // After `Network.init`!
		await Scene_Manager.open_url('');
		await Updater.run();
	}

	private _update_bottom_menu()
	{
		const footer_element = document.getElementById('page-bottom')!;
		footer_element.innerHTML = '';
		if( Scene_Manager.active_scene && Scene_Manager.active_scene.tools_element ){
			footer_element.append(Scene_Manager.active_scene.tools_element);
			footer_element.style.display = 'block';
		}else{
			footer_element.style.display = 'none';
		}
	}
}
