import * as countries from "countries-list";

import { data as nl_data } from "./lang/nl";
import { data as en_data } from "./lang/en";
import { sprintf, Storage } from "./core";

type Locale_Data = {
    code: string;
    name: {
        english: string;
        native: string;
    };
};

export const locales: {[key: string]: Locale_Data} = {
    "en": {
        code: "en",
        name: {
            english: "English",
            native: "English",
        },
    },
    "nl": {
        code: "nl",
        name: {
            english: "Dutch",
            native: "Nederlands",
        },
    },
};

export class Locale
{
    static country: string;

    static async init()
    {
        await this._init_lang();
        this._init_country();
    }

    static get_lang()
    {
        return this._lang;
    }

    static set_lang(lang: string)
    {
        this._lang = lang;
        return Storage.set('lang', lang);
    }

    static t(text: string): string
    {
        const result = this._data[this._lang][text] || this._data["en"][text];
        return result || text;
    }

    static update()
    {
        const element = document.body;
        const lang_elements = element.querySelectorAll("[data-lang]");
        const lang_data = this._data[this._lang] || this._data["en"];

        lang_elements.forEach((el: Element) => {
            const key = el.getAttribute("data-lang")!;
            el.innerHTML = lang_data[key] || this._data["en"][key];
        });
    }

    static _lang: string;

    private static _country_exists(code: string): boolean
    {
        return countries.getCountryCode(code.toUpperCase()) !== undefined;
    }

    private static _data: {[key: string]: {[key: string]: string}} = {
        "en": en_data,
        "nl": nl_data,
    }

    private static _init_country()
    {
        const env_lang_parts = navigator.language.split('-');
        if( env_lang_parts[1] && this._country_exists(env_lang_parts[1]) ){
            this.country = env_lang_parts[1];
            return;
        }else if( env_lang_parts[0] && this._country_exists(env_lang_parts[0]) ){
            this.country = env_lang_parts[0];
            return;
        }

        this.country = 'us';
    }

    private static async _init_lang()
    {
        const request_lang = await Storage.get('lang');
        if( request_lang && this._data[request_lang] ){
            this._lang = request_lang;
            return;
        }

        const env_lang = navigator.language;
        if( env_lang && this._data[env_lang] ){
            this._lang = env_lang;
            return;
        }

        const env_lang_parts = navigator.language.split('-');
        if( env_lang_parts[0] && this._data[env_lang_parts[0]] ){
            this._lang = env_lang_parts[0];
            return;
        }

        this._lang = 'en';
    }
}

export function _t(text: string, ...args: (string | number)[]): string
{
    return sprintf(Locale.t(text), ...args);
}
