import { locales } from "../localization";
import { esc_attr, esc_html } from './DOM';

export class Language_Input
{
    element: HTMLSelectElement;

    constructor()
    {
        this.element = document.createElement('select');
        this.element.name = 'lang';
        Object.entries(locales)
            .sort((a, b) => a[1].name.english > b[1].name.english ? 1 : -1)
            .forEach(element => { this.element.innerHTML += `<option value="${esc_attr(element[0])}">${esc_html(element[1].name.native)}</option>` });
    }

    get_value(): string
    {
        return this.element.value;
    }
}