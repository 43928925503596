import { Clipboard } from '@capacitor/clipboard';
import { Share } from '@capacitor/share';

import { Auth } from "../auth";
import { Scene, Scene_Manager } from "../scenes";
import { Updater } from "../updater";
import { Users }  from "../users";
import { Friends_Menu } from "../ui/friends_menu";
import { print_global_message, print_global_messages } from "../ui/global_message";
import { _t } from '../localization';
import { HTML_Popup } from '../ui/popups/html_popup';
import { is_mobile } from '../core';
import { esc_attr, esc_html } from '../ui/DOM';
import { Add_Friend_Popup } from '../ui/popups/add_friend_popup';

export class Users_Requests_In_Scene extends Scene
{
    list_element!: HTMLDivElement;

    constructor(updater: Updater)
    {
        super(updater, 'users/requests/in');

        this.user_state = 'active';

        this._init_element();
        this._init_nothing_found_element();
        this._init_tools_element();
    }

    async update()
    {
        if( !Auth.current_user)
            return;

        await this._load_users();
        this._friends_menu.update(); // After loading the users since it uses the most recent data.
    }

    private _friends_menu!: Friends_Menu;
    private _nothing_found_element!: HTMLDivElement

    private async _accept_friend_request(event: Event, uid: string)
    {
        event.stopPropagation();
        if( !Auth.current_user)
            return;
        
        const messages = {};
        const user = await Users.befriend(Auth.current_user, {'uid': uid}, messages);
        if( !user ){
            print_global_messages(messages);
            return;
        }

        print_global_message('success', _t('users/friend_list_updated'));
        this._updater.run();
    }

    private async _decline_friend_request(event: Event, uid: string)
    {
        event.stopPropagation();
        if( !Auth.current_user)
            return;

        const messages = {};
        const user = await Users.unfriend(Auth.current_user, {'uid': uid}, messages);
        if( !user ){
            print_global_messages(messages);
            return;
        }

        this._updater.run();
    }

    private _init_element()
    {
        this.element.className = 'page-section';
        this.element.innerHTML = `
            <button class="create-chat add-button button round primary" style="float: right; margin-top: 15px;">+</button>
            <h1>${_t('users/received_requests')}</h1>
            <div class="users-container"></div>`;

        const create_button = this.element.querySelector('.create-chat') as HTMLButtonElement;
        create_button.addEventListener('click', () => {
            new Add_Friend_Popup().open();
        });

        this.list_element = this.element.querySelector('.users-container') as HTMLDivElement;
    }

    private _init_nothing_found_element()
    {
        this._nothing_found_element = document.createElement('div');
        this._nothing_found_element.innerHTML = `<p class="text-center">${_t('general/nothing_found')}</p>`;
    }

    private _init_tools_element()
    {
        this.tools_element = document.createElement('div');
        this._friends_menu = new Friends_Menu(this.tools_element, this.path, this._updater);
    }

    private async _load_users()
    {
        if( !Auth.current_user )
            return;
        
        const users = await Users.get_received_friend_requests();
        if( !users ){
            this.list_element.innerHTML = '';
            print_global_message('error', _t('general/loading_error'));
            return;
        }

        this.list_element.innerHTML = '';

        for(const user of users){

            const item_element = document.createElement('div');
            item_element.className = 'main-list-item user-list-item button ' + user.relation;
            item_element.dataset.id = user.uid;
            item_element.innerHTML = `
                <div class="image-container">
                    <img src="${esc_attr(Users.get_image_url(user.image, 'main_list_item'))}"/>
                </div>
                <div class="info-container">
                    <div class="name">${esc_html(user.first_name)} ${esc_html(user.last_name)}</div>
                </div>
                <div class="image-container">
                    <a><img class="accept-button" src="/assets/images/accept.png"></a>
                </div>
                <div class="image-container">
                    <a><img class="decline-button" src="/assets/images/decline.png"></a>
                </div>`;

            item_element.addEventListener('click', async () => {
                await Scene_Manager.open('user', {'uid': user.uid});
                this._updater.run();
            });

            item_element.querySelector('.accept-button')!.addEventListener('click', activity => this._accept_friend_request(activity, user.uid));
            item_element.querySelector('.decline-button')!.addEventListener('click', activity => this._decline_friend_request(activity, user.uid));

            this.list_element.append(item_element);
        }

        if( users.length === 0 )
            this.list_element.append(this._nothing_found_element);
    }
}
