import { countries } from "countries-list";

export class Country_Input
{
    element: HTMLSelectElement;

    constructor(args?: {required?: boolean})
    {
        this.element = document.createElement('select');
        this.element.name = 'country';
        this.element.required = Boolean(args?.required);

        Object.entries(countries)
            .sort((a, b) => a[1].native > b[1].native ? 1 : -1)
            .forEach(([code, country]) => {
                const option = document.createElement('option');
                option.value = code.toLowerCase();
                option.textContent = country.native;
                this.element.append(option);
            });
    }

    get_value(): string
    {
        return this.element.value;
    }

    set_value(country: string): void
    {
        this.element.value = country;
    }
}
