import { Auth } from "../../auth";
import { Chat, Chat_Manager } from "../../chats";
import { _t } from "../../localization";
import { Scene, Scene_Manager } from "../../scenes";
import { print_global_message, print_global_messages } from "../../ui/global_message";
import { Image_Uploader } from "../../ui/image_uploader";
import Updater from "../../updater";

export class Chat_Image_Scene extends Scene
{
    image_uploader?: Image_Uploader;

    constructor()
    {
        super('chat/image');

        this.user_state = 'active';

        this.element.className = 'page-section';
        this.element.innerHTML = `
            <h1>${_t('chats/chat_image')}</h1>
            <div class="image-picker-container"></div>

            <div class="flex-equal horizontal-rounded-content" style="margin-top: 40px">
                <button class="primary" name="submit">${_t('general/save')}</button>
                <button name="cancel" type="button">${_t('general/cancel')}</button>
            </div>`;

        this.element.querySelector("[name='submit']")!.addEventListener('click', this._on_submit_click.bind(this));
        this.element.querySelector("[name='cancel']")!.addEventListener('click', async () => {
            Scene_Manager.go_back();
            await Updater.run();
        });
    }

    close()
    {
        this.element.querySelector('.image-picker-container')!.innerHTML = ''
        this.image_uploader = undefined
    }

    async open(args?: {[key: string]: string})
    {
        await super.open(args)

        if( args && args.uid ){
            const chat = await Chat_Manager.get(args.uid);
            if( !chat ){
                print_global_message('error', _t('chats/chat_not_found'));
                return;
            }

            this._chat = chat;
        }

        this.image_uploader = new Image_Uploader
        this.element.querySelector('.image-picker-container')!.append(this.image_uploader.element)
    }

    private _chat?: Chat;

    private async _on_submit_click()
    {
        if( !Auth.current_user || !this._chat )
            return false;

        if( !this.image_uploader || !this.image_uploader.image ){
            print_global_message('error', _t('chats/no_image_selected'));
            return;
        }

        const messages = {};
        const result = await Chat_Manager.update_image(this._chat, this.image_uploader.image, messages);
        if( !result ){
            print_global_messages(messages);
            return;
        }

        await Scene_Manager.open('chat/info', {'uid': this._chat.uid});
        await Updater.run();
    }
}
