import { User, Users } from "../../users";
import { Popup } from "./popup";
import { _t } from "../../localization";
import { esc_attr, esc_html } from "../DOM";

export type Friends_Popup_Args = {
    exclude?: string[];
}

export class Friends_Popup extends Popup
{
    constructor(title: string, callback: (friends: User[]) => void, args: Friends_Popup_Args = {})
    {
        super('friends', { title: title });

        this._callback = callback;
        this._args = args || {};

        this._body.style.display = 'flex';
        this._body.style.flexDirection = 'column';

        this._list_element = document.createElement('div');
        this._list_element.style.flexGrow = '1';
        this._body.append(this._list_element);

        const footer_element = document.createElement('div');
        footer_element.style.flexShrink = '0';
        this._body.append(footer_element);

        this._confirm_button = document.createElement('button');
        this._confirm_button.className = 'primary full-width';
        this._confirm_button.innerHTML = _t('general/confirm');
        this._confirm_button.style.marginTop = '10px';
        this._confirm_button.addEventListener('click', this._on_confirm.bind(this));

        footer_element.append(this._confirm_button);
    }

    open()
    {
        super.open();
        this._load();
        this._update_confirm_button();
    }

    private _args: Friends_Popup_Args;
    private _callback: (friends: User[]) => void;
    private _checkboxes = new Array<HTMLInputElement>;
    private _confirm_button: HTMLButtonElement;
    private _friends: User[] = [];
    private _list_element!: HTMLDivElement;

    private async _load()
    {
        const users = await Users.search({'relation': 'friend'});
        if( !users ){
            this._list_element.innerHTML = `<p class="text-center">${_t('general/loading_error')}</p>`;
            return;
        }
        
        this._friends = users;
        if( this._args.exclude ){
            this._friends = this._friends.filter(user => {
                return !this._args.exclude!.includes(user.uid);
            })
        }

        this._list_element.innerHTML = '';

        for(const user of this._friends){
            const item_element = document.createElement('div');
            item_element.className = 'main-list-item user-list-item button';
            item_element.dataset.id = user.uid;
            item_element.innerHTML = `
                <div class="select-container"position: relative">
                    <input
                        name="friends[]"
                        style="height: var(--default-line-height); margin: 15px; width: var(--default-line-height)"
                        type="checkbox"
                        value="${esc_attr(user.uid)}"
                    />
                </div>
                <div class="image-container">
                    <img src="${esc_attr(Users.get_image_url(user.image, 'main_list_item'))}"/>
                </div>
                <div class="info-container">
                    <div class="name">${esc_html(user.first_name)} ${esc_html(user.last_name)}</div>
                </div>`;

            const checkbox = item_element.querySelector('input') as HTMLInputElement;
            checkbox.addEventListener('input', this._update_confirm_button.bind(this));
            this._checkboxes.push(checkbox);

            item_element.addEventListener('click', async event => {
                if( event.target !== checkbox )
                    checkbox.checked = !checkbox.checked;
                this._update_confirm_button();
            })

            this._list_element.append(item_element);
        }

        if( this._friends.length === 0 )
            this._list_element.innerHTML = `<p class="text-center">${_t('general/nothing_found')}</p>`;
    }

    private async _on_confirm()
    {
        this.close();

        const selected_elements = this._list_element.querySelectorAll('input[name="friends[]"]:checked') as NodeListOf<HTMLInputElement>;
        const selected_ids = Array.from(selected_elements).map(element => element.value);
        const selected_friends = this._friends.filter(friend => selected_ids.includes(friend.uid));
        
        this._callback(selected_friends);
    }

    private _update_confirm_button()
    {
        this._confirm_button.disabled = !this._checkboxes.some(checkbox => checkbox.checked);
    }
}
