import { Auth } from "../../auth";
import * as DOM from "../../ui/DOM";
import { Activities } from "../../activities";
import { Scene, Scene_Manager } from "../../scenes";
import { Country_Input } from "../../ui/country_input";
import { print_global_message, print_global_messages } from "../../ui/global_message";
import { _t } from "../../localization";
import { Log_Messages } from "../../core";
import { esc_attr, esc_html } from "../../ui/DOM";
import Updater from "../../updater";
import { Price_Input } from "../../ui/price_input";
import { Chats_Popup } from "../../ui/popups/chats_popup";
import { Chat, Chats } from "../../chats";
import { Activity_Images_Popup } from "../../ui/popups/activity_images_popup";
import { Image } from "../../media";

const DEBUG_DISABLE_REQUIRED = false;

export class Activities_Create_Scene extends Scene
{
    form: HTMLFormElement;

    constructor()
    {
        super('activities/create');

        this.user_state = 'active';

        const required_attr = DEBUG_DISABLE_REQUIRED ? '' : 'required';

        this.element.className = 'page-section';
        this.element.innerHTML = `
        <form>
            <h1>${_t('activities/new_activity')}</h1>

            <label class="block full-width">
                <span data-lang="activities/form/name">Name:</span><br/>
                <input class="full-width" name="name" type="text" ${required_attr}>
            </label>

            <hr/>

            <label data-lang="activities/form/description" for="description-field">Description:</label>
            <div data-lang="activities/description_explanation">
                <p>What do people need to think of before they join your activity? Try to be thorough. Some example are ...</p>
                <ul>
                    <li>... tools and outfit.</li>
                    <li>... entree fee to party.</li>
                </ul>
            </div>
            <textarea id="description-field" class="full-width" name="description" type="text" ${required_attr}></textarea>

            <hr/>

            <p>${_t('activities/location_warning')}</p>
            <label class="block full-width">
                <span data-lang="activities/form/address">Address:</span><br/>
                <input class="full-width" name="address" type="text" ${required_attr}>
            </label>
            <label class="block full-width">
                <span data-lang="activities/form/city">City:</span><br/>
                <input class="full-width" name="city" type="text" ${required_attr}>
            </label>
            <label class="block full-width country-section">
                <span data-lang="activities/form/country">Country:</span>
            </label>

            <hr/>

            <label class="block full-width">
                <span data-lang="activities/form/date_and_time">Date and Time:</span><br/>
                <input class="full-width" name="datetime" type="datetime-local" ${required_attr}>
            </label>
            <label class="block full-width">
                <span data-lang="activities/form/deadline">Signup Deadline:</span><br/>
                <input class="full-width" name="deadline" type="datetime-local" ${required_attr}>
            </label>

            <hr/>

            <div class="full-width">
                <span>${_t('activities/form/max_age_diff')}</span><br/>
                <div style="display: flex">
                    <div style="align-content: center">
                        <input id="max_age_diff_enabled" name="max_age_diff_enabled" type="checkbox"/>
                    </div>
                    <div style="flex-grow: 1">
                        <div class="if_max_age_diff_enabled">
                            <input class="full-width" name="max_age_diff" type="number" ${required_attr}/>
                        </div>
                        <div class="if_max_age_diff_disabled" style="margin: 10px 0; padding: 12px 0">
                            <label for="max_age_diff_enabled">(${_t('general/disabled')})</label>
                            <input name="max_age_diff_hidden" type="hidden">
                        </div>
                    </div>
                </div>
            </div>

            <hr style="margin-top: 0"/>

            <label class="block full-width gender-section">
            
            </label>

            <hr/>

            <label class="block full-width price-section" for="price-input-price">
                <span data-lang="activities/form/price">Price:</span>
                <p data-lang="activities/form/price/explanation"></p>
            </label>

            <hr/>

            <label class="block full-width">
                <span>${_t('activities/form/audience')}</span><br/>
                <select class="full-width" name="audience" ${required_attr}>
                    <option value="public">${_t('activities/form/audience/public')}</option>
                    <option value="private">${_t('activities/form/audience/private')}</option>
                </select>
            </label>
            <label class="block full-width">
                <span data-lang="activities/form/min_guests">Minimum amount of guests:</span><br/>
                <input class="full-width" name="min_guests" type="number" min="1" ${required_attr}>
            </label>
            <label class="block full-width">
                <span data-lang="activities/form/max_guests">Maximum amount of guest:</span><br/>
                <input class="full-width" name="max_guests" type="number" min="1" ${required_attr}>
            </label>
            <label class="block full-width">
                <span data-lang="activities/form/max_invitees">Maximum amount of friends a guest can take with them:</span><br/>
                <input class="full-width" name="max_invitees" type="number" min="0" ${required_attr}>
            </label>

            <hr/>

            <div>
                <p>${_t('activities/form/image')}</p>

                <div class="image-picker" style="border: 1px solid var(--detail-color); border-radius: 10px; padding: 10px">
                    <button class="image-button full-width" type="button">${_t('media/select_image')}</button>

                    <div class="flex grow-first image-name-holder" style="border: 1px solid var(--detail-color); border-radius: 10px; padding: 10px">
                        <div style="line-break: anywhere; margin-right: 10px">
                            <img class="image-image round" style="display: block; margin: auto; max-width: 100px"/>
                        </div>
                        <div class="image-remove" style="align-content: center; cursor: pointer; font-size: 1.5em">&#10005;</div>
                    </div>

                    <input name="image" type="hidden"/>
                </div>
            </div>

            <hr/>

            <div>
                ${_t('activities/form/chat')}

                <p>${_t('activities/form/chat/explanation')}</p>

                <label class="full-width" style="margin: 10px 0">
                    <input class="enable-use-existing-chat" type="checkbox"/>
                    ${_t('activities/use_existing_chat')}
                </label>

                <div class="chat-picker" style="border: 1px solid var(--detail-color); border-radius: 10px; padding: 10px">
                    <button class="chat-button full-width" type="button">${_t('chats/select')}</button>

                    <div class="flex grow-center chat-name-holder" style="border: 1px solid var(--detail-color); border-radius: 10px; padding: 10px">
                        <div style="align-content: center">
                            <img class="chat-image round" style="display: block; margin-right: 10px; width: 50px">
                        </div>
                        <div class="chat-name" style="align-content: center; overflow: hidden; padding: 10px 0"></div>
                        <div class="chat-remove" style="align-content: center; cursor: pointer; font-size: 1.5em; margin-left: 10px">&#10005;</div>
                    </div>

                    <label class="full-width" style="margin: 10px 0 0">
                        <input name="chat_invite" type="checkbox"/>${_t('activities/send_invites')}
                    </label>

                    <input name="chat" type="hidden"/>
                </div>
            </div>

            <hr/>

            ${_t('activities/creation_bottom_info')}

            <hr/>

            <div class="flex-equal horizontal-rounded-content" style="margin-top: 40px">
                <button class="primary" name="submit" data-lang="activities/launch">
                    Publish
                </button>
                <button name="cancel" type="button" data-lang="general/cancel">
                    Cancel
                </button>
            </div>
        </form>`;

        this.form = this.element.querySelector('form')!;
        this.form.addEventListener('submit', this._on_submit.bind(this));

        //this._image_search_input = this.element.querySelector("[name='image_search']")!;
        //this._image_search_input.addEventListener('input', this._load_images.bind(this));

        this.element.querySelector("[name='submit']")!.addEventListener('click', () => { this.form.classList.add('submitted') });
        this.element.querySelector("[name='cancel']")!.addEventListener('click', async () => {
            Scene_Manager.go_back();
            await Updater.run();
        });

        this._country_el = new Country_Input({ required: true });
        this._country_el.element.classList.add('full-width');
        this.element.querySelector('.country-section')!.append(this._country_el.element);

        this._price_el = new Price_Input({ id: 'price-input', required: true });
        this._price_el.element.style.width = '100%';
        this.element.querySelector('.price-section')!.append(this._price_el.element);
        this._country_el.element.addEventListener('input', () => {
            this._price_el.set_currency_by_country(this._country_el.element.value);
        });

        const max_age_diff_checkbox = this.element.querySelector('#max_age_diff_enabled') as HTMLInputElement;
        max_age_diff_checkbox.addEventListener('input', this.update.bind(this));

        this._image_els = {
            button: this.element.querySelector('.image-button') as HTMLButtonElement,
            input: this.element.querySelector('[name="image"]') as HTMLInputElement,
            image: this.element.querySelector('.image-image') as HTMLImageElement,
            name_holder: this.element.querySelector('.image-name-holder') as HTMLInputElement,
            picker: this.element.querySelector('.image-picker') as HTMLInputElement,
            remove: this.element.querySelector('.image-remove') as HTMLDivElement,
        };
        this._image_els.remove.addEventListener('click', () => {
            this._image_els.input.value = '';
            this.update();
        });
        this._image_picker = new Activity_Images_Popup(this._on_select_image.bind(this));
        this._image_els.button.addEventListener('click', () => {
            this._image_picker.open();
        });

        this._chat_els = {
            button: this.element.querySelector('.chat-button') as HTMLButtonElement,
            input: this.element.querySelector('[name="chat"]') as HTMLInputElement,
            image: this.element.querySelector('.chat-image') as HTMLImageElement,
            name: this.element.querySelector('.chat-name') as HTMLDivElement,
            name_holder: this.element.querySelector('.chat-name-holder') as HTMLInputElement,
            picker: this.element.querySelector('.chat-picker') as HTMLInputElement,
            remove: this.element.querySelector('.chat-remove') as HTMLDivElement,
            toggle: this.element.querySelector('.enable-use-existing-chat') as HTMLInputElement,
        }

        this._chat_els.toggle.addEventListener('input', this.update.bind(this));
        this._chat_els.remove.addEventListener('click', () => {
            this._chat_els.input.value = '';
            this.update();
        });
        this._chat_els.button.addEventListener('click', () => {
            (new Chats_Popup(this._on_select_chat.bind(this))).open();
        });
    }

    async open()
    {
        await super.open();

        if( this._is_first_open ){
            this.reset_form();
            this._is_first_open = false;
        }

        // this._load_images();
    }

    reset_form()
    {
        if( !Auth.current_user )
            return false;

        const user = Auth.current_user;
        const country = user.country;
        DOM.set_form_values(this.form, {
            'audience': 'public',
            'city': user.city,
            'country': country,
            'max_age_diff': user.max_age_diff,
            'max_age_diff_hidden': 1000,
            'price': 0,
        });

        this._price_el.set_currency_by_country(country);

        this.form.classList.remove('submitted');

        const gender_section_element = this.form.querySelector('.gender-section')!;
        if( Auth.current_user.gender === 'male' )
            gender_section_element.innerHTML = `<input name="gender" type="checkbox" value="male"><span data-lang="activities/form/men_only">Men only</span>`;
        else if( Auth.current_user.gender === 'female' )
            gender_section_element.innerHTML = `<input name="gender" type="checkbox" value="female"><span data-lang="activities/form/women_only">Women only</span>`;
    }

    async update()
    {
        this._update_max_age_diff()
        this._update_chat_picker();
        this._update_image_picker();
    }

    private _chat_els!: {
        button: HTMLButtonElement,
        input: HTMLInputElement,
        image: HTMLImageElement,
        name: HTMLDivElement,
        name_holder: HTMLDivElement,
        picker: HTMLDivElement,
        remove: HTMLDivElement,
        toggle: HTMLInputElement,
    };
    private _country_el: Country_Input;
    private _image_els: {
        button: HTMLButtonElement,
        input: HTMLInputElement,
        image: HTMLImageElement,
        name_holder: HTMLDivElement,
        picker: HTMLDivElement,
        remove: HTMLDivElement,
    };
    private _image_picker: Activity_Images_Popup;
    private _image_search_input!: HTMLInputElement;
    private _is_first_open = true;
    private _price_el: Price_Input;

    private _load_images()
    {
        const keywords = this._image_search_input.value;

        const image_container = this.element.querySelector('.activity-image-picker')!;
        image_container.innerHTML = '<p class="text-center">' + _t('general/loading') + '</p>';

        setTimeout(async () => {

            if( this._image_search_input.value !== keywords )
                return;

            // Use `full-width` because the parent element is flex.
            const messages: Log_Messages = {};
            const images = await Activities.get_images({'keywords': keywords + ' default'}, messages);
            if( !images ){
                const error = messages['activities/error/too_many_image_keywords']?.message || _t('general/image_loading_error');
                image_container.innerHTML = '<p class="text-center full-width error">' + error + '</p>';
                print_global_messages(messages);
                return;
            }else if( !images.length  ){
                image_container.innerHTML = '<p class="text-center full-width">' + _t('general/no_image_found') + '</p>';
                return;
            }

            image_container.innerHTML = '';

            for(const image of images){
                const url = Activities.get_image_url(image['url'], 'activity_search_list_item');
                image_container.innerHTML += `
                    <label class="entry">
                        <input name="image" type="radio" value="${esc_attr(image['uid'])}" style="display: none"/>
                        <span class="image-cell"><img src="${esc_attr(url)}"/></span>
                    </label>`;
            }
        }, 1000);
    }

    private _on_select_chat(chat: Chat)
    {
        this._chat_els.image.src = Chats.get_image_url(chat.image, 'main_list_item');
        this._chat_els.name.innerHTML = `
            <strong>${esc_html(chat.name)}</strong>
            <br/>
            <em>(${esc_html(_t('chats/group_size', chat.group_size))})</em>
        `;
        this._chat_els.input.value = chat.uid;

        this.update();
    }

    private _on_select_image(image: Image)
    {
        this._image_els.image.src = Activities.get_image_url(image.url, 'main_list_item');
        this._image_els.input.value = image.uid;

        this.update();
    }

    private async _on_submit(event: Event)
    {
        event.preventDefault();

        if( !Auth.current_user )
            return false;

        const form_data = DOM.parse_form(this.form);

        if( !form_data['max_age_diff_enabled'] )
            form_data['max_age_diff'] = form_data['max_age_diff_hidden'];
        delete form_data['max_age_diff_enabled'];
        delete form_data['max_age_diff_hidden'];

        if( this._chat_els.toggle.checked ){
            if( !form_data['chat'] ){
                print_global_message('error', _t('activities/error/no_chat'));
                return false;
            }
        }else{
            delete form_data['chat'];
            delete form_data['chat_invite'];
        }

        const messages = {};
        const activity = await Activities.create(form_data, messages);
        print_global_messages(messages);
        if( !activity )
            return;

        await Scene_Manager.open('activities');

        this.reset_form();

        await Updater.run();
    }

    private _update_chat_picker()
    {
        this._chat_els.picker.style.display = this._chat_els.toggle.checked ? 'block' : 'none';

        if( this._chat_els.input.value ){
            this._chat_els.name_holder.style.display = '';
            this._chat_els.button.style.setProperty('display', 'none', 'important');
        }else{
            this._chat_els.name_holder.style.display = 'none';
            this._chat_els.button.style.display = '';
        }
    }

    private _update_image_picker()
    {
        if( this._image_els.input.value ){
            this._image_els.name_holder.style.display = '';
            this._image_els.button.style.setProperty('display', 'none', 'important');
        }else{
            this._image_els.name_holder.style.display = 'none';
            this._image_els.button.style.display = '';
        }
    }

    private _update_max_age_diff()
    {
        const checkbox = this.element.querySelector('#max_age_diff_enabled') as HTMLInputElement;
        const max_age_diff_el = this.element.querySelector('[name="max_age_diff"]') as HTMLInputElement;
        const el_if_enabled = this.element.querySelector('.if_max_age_diff_enabled') as HTMLInputElement;
        const el_if_disabled = this.element.querySelector('.if_max_age_diff_disabled') as HTMLInputElement;

        if( checkbox.checked ){
            el_if_enabled.style.display = 'block';
            el_if_disabled.style.display = 'none';
            max_age_diff_el.required = true;
        }else{
            el_if_enabled.style.display = 'none';
            el_if_disabled.style.display = 'block';
            max_age_diff_el.required = false;
        }
    }
}
