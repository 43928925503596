import { API, API_Data, parse_API_messages } from "./api";
import { Log_Messages } from "./core";
import { Locale } from "./localization";
import { Push_Notifications } from "./push_notifications";
import { User, Users } from "./users";

export class Auth
{
    static current_user?: User;
    static token?: string;

    static clear_tokens()
    {
        API.auth_token = undefined;
        this.token = '';
        sessionStorage.removeItem('auth');
        localStorage.removeItem('auth');
    }

    static init()
    {
        this._init_session();

        API.callbacks.push(this._logout_unauthenticated_user.bind(this));
        
        this.token = this._retrieve_token();
    }

    static redirect_to_requested_url()
    {
        const searchParams = new URLSearchParams(location.search);
        const redirect = searchParams.get('redirect');
        if( !redirect ){
            location.pathname = '/';
            return;
        }

        const url = new URL(redirect);

        // We use the origin of the current location to prevent redirecting to a different domain.
        open(location.origin + url.pathname + url.search, '_self');
    }

    static async login(data: API_Data, log: Log_Messages = {}): Promise<boolean>
    {
        const response = await API.POST('/auth/login', data);
        if( !response ){
            return false;
        }else if( response.status !== 200 ){
            parse_API_messages(log, await response.json());
            return false;
        }

        await this._process_login_data(response, data, log);
        
        this._login_other_tabs();

		this.redirect_to_requested_url();

        return true;
    }

    static async login_with_oauth(data: API_Data, log: Log_Messages = {}): Promise<boolean>
    {
        const response = await API.POST('/auth/oauth_login', data);
        if( !response ){
            return false;
        }else if( response.status !== 200 ){
            parse_API_messages(log, await response.json());
            return false;
        }

        data['remember'] = true;
        await this._process_login_data(response, data, log);

        this._login_other_tabs();

		this.redirect_to_requested_url();

        return true;
    }

    static async logout(log: Log_Messages = {}): Promise<boolean>
    {
        const response = await API.POST('/auth/logout');
        if( !response ){
            return false;
        }else if( response.status !== 200 && response.status !== 401 ){
            parse_API_messages(log, await response.json());
            return false;
        }

        Push_Notifications.unregister();

        this.clear_tokens();
        this._logout_other_tabs();

		location.pathname = '/';

        return true;
    }

    static async request_email_verification(log: Log_Messages = {}): Promise<User | undefined>
    {
        if( !this.current_user )
            return;

        const response = await API.POST('/user/' + encodeURIComponent(this.current_user.uid) + '/email');
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;
 
        return Users.parse(result.data, this.current_user);
    }

    static async resume_session(errors: Log_Messages = {}): Promise<User | undefined>
    {
        if( !this.token )
            return;
        
        API.auth_token = this.token;
        const user = await Users.get_current(errors);
        if( !user ){ // TO DO: Is this a security risk?
            // this.clear_tokens(); // If enabled, quick successive refreshes can cause a logout.
            API.auth_token = undefined;
            return;
        }

        this.current_user = user;
        this.token = this.token;
        
        return this.current_user;
    }

    static async verify_email(hash: string, log: Log_Messages = {}): Promise<User | undefined>
    {
        if( !this.current_user )
            return;

        const response = await API.PUT('/user/' + encodeURIComponent(this.current_user.uid) + '/email/verify', { hash: hash });
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;
        
        return Users.parse(result.data, this.current_user);
    }

    private static _auth_broadcast = new BroadcastChannel('login');

    private static _init_session()
	{
		// If a new tab is opened and this tab is logged in, login all tabs to make sure the new tab is logged in as well.
		var new_tab_broadcast = new BroadcastChannel('new_tab');
		new_tab_broadcast.addEventListener('message', () => {
			if( sessionStorage.getItem('auth') )
				this._login_other_tabs();
		});

        // Let other tabs know this tab is created.
		if( !sessionStorage.getItem('auth') )
			new_tab_broadcast.postMessage(true);

		// If another tab logs in or out, update this tab.
		this._auth_broadcast.addEventListener('message', (message) => {
			if( !message.data ){
                if( this.token){
                    Auth.clear_tokens();
                    location.pathname = '/';
                }
			}else if( message.data !== sessionStorage.getItem('auth') ){
				sessionStorage.setItem('auth', message.data);
				this.redirect_to_requested_url();
			}
		});
	}

    private static _login_other_tabs()
	{
		this._auth_broadcast.postMessage(sessionStorage.getItem('auth'));
	}

    private static _logout_other_tabs()
	{
		this._auth_broadcast.postMessage(false);
	}

    private static async _logout_unauthenticated_user(response: Response)
    {
        if( response.status === 401 ){
            this.clear_tokens();
            this._logout_other_tabs();
            location.pathname = '/';
        }
    }

    private static async _process_login_data(response: Response, data: API_Data, log: Log_Messages = {})
    {
        const result = await response.json();
        this.current_user = Users.parse(result.data.user)!;
        this.token = typeof(result.data.auth) === 'string' ? result.data.auth : false;
        if( this.token )
            this._store_token(this.token, data['remember'])
        
        API.auth_token = this.token;

        if( this.current_user.lang )
            Locale.set_lang(this.current_user.lang);
    }

    private static _retrieve_token(): string | undefined
    {
        let token = sessionStorage.getItem('auth');
        if( !token )
            token = localStorage.getItem('auth');
        return token || undefined;
    }

    private static _store_token(token: string, remember: boolean)
    {
        sessionStorage.setItem('auth', token);
        if( remember )
            localStorage.setItem('auth', token);
    }
}
