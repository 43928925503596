/**
 * - When the login state changes, a full redirect if performed.
 */

import { API } from "./api";
import { Auth } from "./auth";
import { _t } from "./localization";
import { Network } from "./network";
import { print_global_message } from "./ui/global_message";
import Updater from "./updater";
import { User_Status } from "./users";

type Query_Args = Record<string, string> | string[][];
type Auth_State = 'logged_in' | 'logged_out' | 'active';

export class Scene_Request
{
    path = '';
    args?: Query_Args;
}

type User_Info = {
    status: User_Status
}

export class Scene
{
    element: HTMLDivElement;
    path: string;
    is_active = false;
    user_state?: Auth_State;

    menu_type: '' | 'nav' | 'language' = 'nav';
    tools_element?: HTMLDivElement;

    constructor(path: string)
    {
        this.path = path;
        this.element = document.createElement('div');
    }

    close()
    {
        this.is_active = false;
    }

    async open(args?: Query_Args)
    {
        this.is_active = true;
    }

    async update() {}
}

type User_CB = () => User_Info | undefined;
type Redirect_CB = (scene: Scene) => Promise<Scene_Request | undefined>;

export class Scene_Manager
{
    static active_scene ?: Scene;
    static home_path = '';
    static login_path = '';
    static referrer = '';
    static verify_path = '';

    static go_back()
    {
        history.back();
    }

    static init(parent_el: HTMLElement, home_path: string, user_cb: User_CB, args: {
        login_path?: string,
        verify_path?: string,
        redirect_cb: Redirect_CB,
    }){
        this._parent_el = parent_el;
        this.home_path = home_path;
        this._user_cb = user_cb;

        this.login_path = args.login_path ?? home_path;
        this.verify_path = args.verify_path ?? home_path;
        this._redirect_cb = args.redirect_cb;

        addEventListener('popstate', Scene_Manager._on_pop_state.bind(this));
    }

    static login_redirect()
    {
        const searchParams = new URLSearchParams(location.search);
        const redirect = searchParams.get('redirect');
        if( !redirect ){
            location.pathname = this.home_path;
            return;
        }

        const url = new URL(redirect);

        // We use the origin of the current location to prevent redirecting to a different domain.
        open(location.origin + url.pathname + url.search, '_self');
    }

    // Sets scene AND changes the URL.
    static async open(path: string, args?: Query_Args)
    {
        const request = await this._set_scene(path, args);
        if( !request )
            return;

        const query = new URLSearchParams(request.args);
        let url = this._base_url + request.path;
        if( query.size )
            url += '?' + query.toString();

        history.pushState({}, '', url);
    }

    static async open_url(url = '')
    {
        const user_info = this._user_cb();

        let final_path = user_info ? this.home_path : this.login_path;
		let final_query: Record<string, string> = {};
		
		if( !url )
			url = location.href;

		const url_data = new URL(url);
	
        // `pathname` doesn't work with custom protocols. So we find the ourselves.
		//let path = url_data.pathname.split('.com').pop();
		//if( path && path[0] === '/' )
		//	path = path.slice(1);

        const path = url_data.pathname;
		if( path ){
			const query: Record<string, string> = {};
			url_data.searchParams.forEach((value, key) => query[key] = value);

			final_path = path;
			final_query = query;
		}
		
		await Scene_Manager.open(final_path, final_query);
    }

    static register(scene: Scene)
    {
        this._scenes.set(scene.path, scene);
    }

    static async update()
    {
        if( this.active_scene )
            await this.active_scene.update();
    }
    
    private static _base_url = '/';
    private static _parent_el: HTMLElement;
    private static _redirect_cb: Redirect_CB;
    private static _scenes = new Map<string, Scene>;
    private static _user_cb: User_CB;

    private static _generate_url(path: string, args: Query_Args = {})
    {
        return location.origin + this._base_url + path + '?' + new URLSearchParams(args).toString();
    }

    private static async _on_pop_state()
    {
        await this._set_scene(
            location.pathname.substring(this._base_url.length),
            Object.fromEntries(new URLSearchParams(location.search))
        );

        await Updater.run();
    }

    // Sets scene WITHOUT changing the URL.
    private static async _set_scene(path: string, args: Query_Args = {}): Promise<Scene_Request | undefined>
    {
        if( path[0] === '/' )
            path = path.slice(1);

        if( !path )
            path = this.home_path;

        //if( this.active_scene?.path )
        //    args['referrer'] = this.active_scene?.path;

        let new_scene = this._scenes.get(path);
        if( !new_scene )
            new_scene = this._scenes.get('not_found')!;

        if( this._redirect_cb !== undefined ){
            const redirect = await this._redirect_cb(new_scene);
            if( redirect ){
                this._set_scene(redirect.path, redirect.args);
                return;
            }
        }

        const user_info = this._user_cb();

        if( new_scene.user_state === 'active' ){
            if( user_info && user_info.status === 'unverified' ){
                location.href = this._generate_url(this.verify_path, {'redirect': this._generate_url(path, args)});
                return;
            }else if( !user_info || user_info.status !== 'active' ){
                location.href = this._generate_url(this.login_path, {'redirect': this._generate_url(path, args)});
                return;
            }
        }else if( new_scene.user_state === 'logged_in' ){
            if( !user_info || user_info.status !== 'active' && user_info.status !== 'unverified' ){
                location.href = this._generate_url(this.login_path, {'redirect': this._generate_url(path, args)});
                return;
            }
        }else if( new_scene.user_state === 'logged_out' && user_info && (user_info.status === 'active' || user_info.status === 'unverified') ){
            this.login_redirect();
            return;
        }

        if( this.active_scene ){
            this.referrer = this.active_scene.path;
            this.active_scene.close();
        }

        this.active_scene = new_scene;
        await this.active_scene.open(args);

        // If the new scene called `open` to redirect to a different scene,
        // the other 'open' call already took care of everything. So we can stop.
        if( this.active_scene != new_scene )
            return;

        this._parent_el.innerHTML = '';
        this._parent_el.append(this.active_scene.element);

        return {path, args};
    }
}
