import DOMPurify from "dompurify";

/*
    Scrolling.
*/
export function is_in_view(element: HTMLElement, container: HTMLElement): boolean
{
    const rect = element.getBoundingClientRect();
    const parent_rect = container.getBoundingClientRect();

    return rect.top > parent_rect.top && rect.top < parent_rect.bottom
        || rect.bottom > parent_rect.top && rect.bottom < parent_rect.bottom;
}

export function is_scrolled_to_bottom(element: HTMLElement): boolean
{
    return element.scrollHeight - element.scrollTop - element.clientHeight < 1;
}

export function scroll_to(element: HTMLElement, offset = 20): void
{
    const pos = element.getBoundingClientRect()
    scrollTo(scrollX, pos.top - offset + scrollY)
}

/*
    Froms.
*/
export function parse_form(form: HTMLElement): {[key: string]: (string | string[])}
{
    const inputs = form.querySelectorAll('input, select, textarea') as NodeListOf<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    const result: {[key: string]: (string | string[])} = {}
    for(const input of inputs){
        if( !input.name )
            continue;

        if( input instanceof HTMLInputElement ){
            if( input.type === 'checkbox' ){
                if( input.checked )
                    result[input.name] = input.value

                /* Always array.
                let all_values = result.get(input.name)
                if( !core.is_array(all_values) ){
                    result.set(input.name, new Array<string>)
                    all_values = result.get(input.name) as Array<string>
                }
                
                if( input.checked )
                    all_values.push(input.value)
                */

                /* Single value if only one checked. Otherwise array.
                let values = result.get(input.name)
                if( core.is_array(values) ){
                    values.push(input.value)
                }else if( values ){
                    values = [values, input.value]
                    result.set(input.name, values);
                }else{
                    result.set(input.name, input.value)
                }
                */
            }else if( input.type === 'radio' ){
                if( input.checked )
                    result[input.name] = input.value
            }else if( input.type === 'datetime-local' ){
                result[input.name] = input.value.replace('T', ' ')
            }else{
                result[input.name] = input.value
            }
        }else{
            result[input.name] = input.value
        }
    }

    return result
}

export function reset_form(element: HTMLElement)
{
    set_form_values(element, {})
}

export function set_form_values(element: HTMLElement, values: {[key: string]: boolean | number | string | string[]})
{
    const inputs = element.querySelectorAll('input, select, textarea') as NodeListOf<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    for(const input of inputs){
        if( !input.name )
            continue;

        const value = values[input.name]
        if( input instanceof HTMLInputElement ){
            if( input.type === 'checkbox' ){
                if( typeof(value) === 'boolean' )
                    input.checked = value
                else if( typeof(value) === 'string' || typeof(value) === 'number' )
                    input.checked = input.value == value
                else if( typeof(value) === 'object' )
                    input.checked = value.includes(input.value)
                else
                    input.checked = false
            }else if( input.type === 'radio' ){
                if( typeof(value) === 'string' || typeof(value) === 'number' )
                    input.checked = input.value == value
                else
                    input.checked = false
            }else if( input.type === 'date' ){
                if( typeof(value) === 'string' )
                    input.value = value;
            }else if( input.type !== 'button' && input.type !== 'search' && input.type !== 'submit' ){
                if( typeof(value) === 'string' )
                    input.value = value
                else if( typeof(value) === 'number' )
                    input.value = value.toString()
                else
                    input.value = ''
            }
        }else if( input instanceof HTMLSelectElement || input instanceof HTMLTextAreaElement ){
            input.value = value ? value.toString() : ''
        }
    }
}

/*
    Events.
*/
type Position = {x: number, y: number};
const MOUSE_HOLD_DELAY = 1000;
const MOUSE_DRAG_DISTANCE = 10;

export function add_drag_listener(
    element: HTMLElement,
    direction: 'horizontal' | 'vertical',
    type: 'mouse' | 'touch' | 'both',
    start_callback?: (event: MouseEvent | TouchEvent, start: Position, current: Position) => void,
    move_callback?: (event: MouseEvent | TouchEvent, start: Position, current: Position) => void,
    end_callback?: (event: MouseEvent | TouchEvent, start: Position, current: Position) => void,
): void
{
    let start_position = {x: 0, y: 0};
    let current_position = {x: 0, y: 0};
    let drag_state: 'before' | 'dragging' | 'after' = 'before';

    const on_end = (event: MouseEvent | TouchEvent) => {
        if( drag_state === 'dragging' && end_callback )
            end_callback(event, start_position, current_position);
        drag_state = 'after';
    }

    const on_move = (event: MouseEvent | TouchEvent) => {

        const new_x = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
        const new_y = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
        const dx = Math.abs(current_position.x - new_x);
        const dy = Math.abs(current_position.y - new_y);
        
        if( drag_state === 'before' ){
            if( // If the drag is in the right direction.
                direction === 'horizontal' && dx > MOUSE_DRAG_DISTANCE && dx > dy ||
                direction === 'vertical' && dy > MOUSE_DRAG_DISTANCE && dy > dx
            ){
                if( start_callback )
                    start_callback(event, start_position, current_position);
                drag_state = 'dragging';
            }else if( // If the drag is in the wrong direction.
                direction === 'horizontal' && dy > MOUSE_DRAG_DISTANCE && dx < dy ||
                direction === 'vertical' && dx > MOUSE_DRAG_DISTANCE && dy < dx
            ){
                return;
            }
        }else if( drag_state === 'dragging' ){
            current_position = {x: new_x, y: new_y};
            if( move_callback )
                move_callback(event, start_position, current_position);
        }else if( drag_state === 'after' ){
            return;
        }

        addEventListener(event instanceof MouseEvent ? 'mousemove' : 'touchmove', on_move, {once: true});
    };

    const on_start = (event: MouseEvent | TouchEvent) => {

        const new_x = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
        const new_y = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;

        start_position = {x: new_x, y: new_y};
        current_position = {x: new_x, y: new_y};
        drag_state = 'before';

        addEventListener('mousemove', on_move, {once: true});
        addEventListener('touchmove', on_move, {once: true});

        addEventListener('mouseup', on_end);
        addEventListener('touchend', on_end);
        addEventListener('touchcancel', on_end);

        // If held for a while, a hold event should be fired. No drag event.
        if( element.classList.contains('holdable') ){
            setTimeout(() => {
                if( drag_state !== 'dragging' )
                    drag_state = 'after';
            }, MOUSE_HOLD_DELAY);
        }
    }

    if( type === 'mouse' || type === 'both' )
        element.addEventListener('mousedown', on_start);
    else if( type === 'touch' || type === 'both' )
        element.addEventListener('touchstart', on_start);
}

export function add_hold_listener(element: HTMLElement, callback: (event: MouseEvent | TouchEvent) => void): void
{
    element.classList.add('holdable');

    element.addEventListener('mousedown', (event) => {

        let position = {x: event.clientX, y: event.clientY};
        let timeout = setTimeout(() => callback(event), MOUSE_HOLD_DELAY);

        const on_move = (event: MouseEvent) => {
            if( Math.abs(position.x - event.clientX) > MOUSE_DRAG_DISTANCE || Math.abs(position.y - event.clientY) > MOUSE_DRAG_DISTANCE )
                clearTimeout(timeout);
            else
                element.addEventListener('mousemove', on_move, {once: true});
        };

        element.addEventListener('mousemove', on_move, {once: true});

        element.addEventListener('mouseup', () => {
            clearTimeout(timeout);
        }, {once: true});
    
        element.addEventListener('mouseleave', () => {
            clearTimeout(timeout);
        }, {once: true});
    });

    element.addEventListener('touchstart', (event) => {

        let position = {x: event.touches[0].clientX, y: event.touches[0].clientY};
        let timeout = setTimeout(() => callback(event), MOUSE_HOLD_DELAY);

        const on_move = (event: TouchEvent) => {
            if( Math.abs(position.x - event.touches[0].clientX) > MOUSE_DRAG_DISTANCE || Math.abs(position.y - event.touches[0].clientY) > MOUSE_DRAG_DISTANCE )
                clearTimeout(timeout);
            else
                element.addEventListener('touchmove', on_move, {once: true});
        };

        element.addEventListener('touchmove', on_move, {once: true});

        element.addEventListener('touchend', () => {
            clearTimeout(timeout);
        }, {once: true});
    
        element.addEventListener('touchcancel', () => {
            clearTimeout(timeout);
        }, {once: true});

        element.addEventListener('touchmove', () => {
            clearTimeout(timeout);
        }, {once: true});
    });
}

export function is_mouse_over(element: HTMLElement, position: Position): boolean
{
    const rect = element.getBoundingClientRect();
    return position.x >= rect.left && position.x <= rect.right && position.y >= rect.top && position.y <= rect.bottom;
}

// Formatting.
export function process_links(text: string): string
{
    return text.replace(/(https?:\/\/[^\s"]+[^\s.!?,:;"'])/g, '<a href="$1" target="_blank">$1</a>');
}

export function esc_attr(text: string): string
{
    return text.replace(/"/g, '&quot;');
}

export function esc_html(text: string): string
{
    return DOMPurify.sanitize(text, {ALLOWED_TAGS: ['b', 'u', 'i'], ALLOWED_ATTR: []});
}
