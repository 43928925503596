import * as io from "socket.io-client";
import { Network as Capacitor_Network } from '@capacitor/network';
import { _t } from "./localization";
import globals from "./.env";

export class Network
{
    static async init(socket?: io.Socket)
    {
        const status = await Capacitor_Network.getStatus();
        this._is_connected = status.connected;
 
        // If there is a socket, let it take its time to connect.
        if( socket ){
            socket.on('connect', this._on_connection_change.bind(this, true));
            socket.on('disconnect', this._on_connection_change.bind(this, false));
        }else{
            Capacitor_Network.addListener('networkStatusChange', async status => {
                this._on_connection_change(status.connected);
            });
        }

        this._init_message();
    }

    static is_connected(): boolean
    {
        return this._is_connected;
    }

    private static _is_connected = false;

    private static _init_message()
    {
        const message_element = document.createElement('div');
		message_element.id = 'network-message';
		message_element.style.backgroundColor = 'black';
		message_element.style.bottom = '20px';
		message_element.style.color = 'white';
		message_element.style.left = '0';
		message_element.style.padding = '5px';
		message_element.style.position = 'fixed';
		message_element.style.right = '0';
		message_element.style.textAlign = 'center';
		message_element.style.zIndex = '100';
		message_element.innerText = _t('network/no_connection');
		
		if( !Network.is_connected() )
			document.body.append(message_element);

		addEventListener('network_connection', () => {
			if( Network.is_connected() ){
				message_element.remove();
            }else{
                // Avoid notifying the about short disconnections.
                // Everything should be handled behind the scenes.
                setTimeout(() => {
                    if( !Network.is_connected() )
                        document.body.append(message_element);
                }, globals.SEC_BEFORE_DISCONNECT_MESSAGE);
            }
		});
    }

    private static _on_connection_change(is_connected: boolean): void
    {
        if( this._is_connected === is_connected )
            return;

        this._is_connected = is_connected;
        
        dispatchEvent(
            new CustomEvent('network_connection', { detail: {
                is_connected: is_connected
            }})
        );
    }
}