import { Auth } from "../auth";
import { Chat_Manager } from "../chats";
import { is_ios_app } from "../core";
import { Locale } from "../localization";
import { Notification_Manager } from "../notifications";
import { Scene_Manager } from "../scenes";
import Updater from "../updater";
import { Users } from "../users";

let logo_click_count = 0;

export class Nav_Menu
{
    constructor()
    {
        this._init_main_menu();
        this._init_login_menu();

        addEventListener('receive_messages', this._update_chat_alerts.bind(this));
        addEventListener('update_chats', this._update_chat_alerts.bind(this));
        addEventListener('receive_notifications', this._update_notification_alerts.bind(this));
    }

    async update()
    {
        // Do this first, so the menu notifications are up to date.
        if( Auth.current_user && this._first_update )
            await Users.get_received_friend_requests(true);

        this._update_visibility();
        this._update_main_menu();

        this._first_update = false;
    }

    private _first_update = true;
    private _login_menu_element!: HTMLElement;
    private _main_menu_element!: HTMLElement;

    private _init_login_menu()
    {
        // Make sure to use 'data-language' instead of 'data-lang' which is used for the translator.
        this._login_menu_element = document.createElement('div');
        this._login_menu_element.className = "nav-menu page-center-content";
        this._login_menu_element.innerHTML = `
            <a id="nav-logo-link" class="nav-left-button">
                <img class="round logo" src="/assets/images/wuddys-50x50.png">
            </a>
            <div class="nav-buttons">
                <a class="nav-button button" data-language="en">
                    <img class="round" src="/assets/images/flags/en.png">
                </a
                ><a class="nav-button button" data-language="nl">
                    <img class="round" src="/assets/images/flags/nl.png">
                </a>
            </div>`;

        this._login_menu_element.querySelector('#nav-logo-link')!.addEventListener('click', async () => {
            if( ++logo_click_count >= 10 ){
                await Scene_Manager.open('/auth/login');
                logo_click_count = 0;
            }else{
                await Scene_Manager.open('/auth'); // Maybe there was no connection, try the logged in page.
            }
            await Updater.run();
        });

        this._login_menu_element.querySelectorAll("a[data-language]")!.forEach(
            el => el.addEventListener('click', this._on_lang_click.bind(this, el))
        );
    }

    private _init_main_menu()
    {
        this._main_menu_element = document.createElement('div');
        this._main_menu_element.className = "nav-menu page-center-content";
        this._main_menu_element.innerHTML = `
            <div class="nav-buttons">
                <a id="nav-discover-button" class="nav-button button" data-scene="activities/discover">
                    <img class="hover-flash" src="/assets/images/nav-discover.png">
                </a
                ><a id="nav-activities-button" class="nav-button button" data-scene="activities">
                    <img class="hover-flash" src="/assets/images/nav-activities.png">
                </a
                ><a id="nav-chats-button" class="nav-button button" data-scene="chats">
                    <img class="hover-flash" src="/assets/images/nav-chats.png">
                </a
                ><a id="nav-friends-button" class="nav-button button" data-scene="users/friends">
                    <img class="hover-flash" src="/assets/images/nav-friends.png">
                </a
                ><a id="nav-notifications-button" class="nav-button button" data-scene="notifications">
                    <img class="hover-flash" src="/assets/images/nav-notifications.png">
                </a>
            </div>
            <a id="nav-profile-button" class="profile">
                <img class="profile-picture"/>
            </a>`;

        if( is_ios_app() ){
            const button = document.createElement('a');
            button.id = 'nav-back-button';
            button.className = 'nav-left-button nav-button button';
            button.innerHTML = '<img src="/assets/images/nav-back.png">';
            button.addEventListener('click', async () => {
                Scene_Manager.go_back();
                await Updater.run();
            });
            this._main_menu_element.prepend(button);
        }else{
            const button = document.createElement('a');
            button.className = 'nav-left-button';
            button.innerHTML = '<img class="round logo" src="/assets/images/wuddys-50x50.png">';
            button.addEventListener('click', async () => {
                await Scene_Manager.open('activities/discover');
                await Updater.run();
            });
            this._main_menu_element.prepend(button);
        }

        const nav_buttons = this._main_menu_element.querySelectorAll('.nav-buttons .nav-button') as NodeListOf<HTMLAnchorElement>;
        for(const button of nav_buttons){
            button.addEventListener('click', async () => {
                await Scene_Manager.open(button.dataset.scene!);
                await Updater.run();
            });
        }

        this._main_menu_element.querySelector('#nav-profile-button')!.addEventListener('click', async () => {
            await Scene_Manager.open('profile');
            await Updater.run();
        });
    }

    private async _on_lang_click(el: Element)
    {
        await Locale.set_lang((el as HTMLElement).dataset.language! || 'en');
        location.reload();
    }

    private _update_chat_alerts()
    {
        const chats = Chat_Manager.get_all();
        if( !chats )
            return;

        let unread_count = 0;
        for(const [_, chat] of chats)
            unread_count += chat.member_unread_count;

        const button = this._main_menu_element.querySelector('#nav-chats-button') as HTMLAnchorElement;
        if( unread_count > 0 )
            button.classList.add('with-alert');
        else
            button.classList.remove('with-alert');
    }

    private async _update_friend_alerts()
    {
        if( !Auth.current_user )
            return;
        
        const button = this._main_menu_element.querySelector('#nav-friends-button') as HTMLAnchorElement;
        const requests = await Users.get_received_friend_requests(true);
        if( requests && requests.length > 0 )
            button.classList.add('with-alert');
        else
            button.classList.remove('with-alert');
    }

    private _update_notification_alerts()
    {
        if( !Auth.current_user )
            return;

        const button = this._main_menu_element.querySelector('#nav-notifications-button') as HTMLAnchorElement;
        if( Notification_Manager.has_unseen() )
            button.classList.add('with-alert');
        else
            button.classList.remove('with-alert');
    }

    private _update_main_menu()
    {
        this._update_main_menu_buttons();
        this._update_main_menu_profile_picture();
    }

    private _update_main_menu_buttons()
    {
        const nav_buttons = this._main_menu_element.querySelectorAll('.nav-button') as NodeListOf<HTMLAnchorElement>;
        const active_scene_path = Scene_Manager.active_scene?.path;
        for(const button of nav_buttons){
            if( active_scene_path && button.dataset.scene === active_scene_path )
                button.classList.add('primary');
            else
                button.classList.remove('primary');
        }

        this._update_chat_alerts();
        // this._update_friend_alerts();
        this._update_notification_alerts();
    }

    private _update_main_menu_profile_picture()
    {
        if( !Auth.current_user )
            return;

        const element = this._main_menu_element.querySelector('.profile-picture') as HTMLImageElement;
        element.src = Users.get_image_url(Auth.current_user.image, 'main_list_item');
    }

    private _update_visibility()
    {
        const header_el = document.getElementById('page-top');
        const active_scene = Scene_Manager.active_scene;

        if( !active_scene || !active_scene.menu_type || !header_el ){
            if( header_el)
                header_el.innerHTML = ''
            return
        }

        if( active_scene.menu_type === 'nav' && !this._main_menu_element.parentElement ){
            header_el.innerHTML = '';
            document.getElementById('page-top')!.append(this._main_menu_element);
        }else if( active_scene.menu_type === 'language' && !this._login_menu_element.parentElement ){
            header_el.innerHTML = '';
            document.getElementById('page-top')!.append(this._login_menu_element);
        }
    }
}
