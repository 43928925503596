import { Auth } from "../../auth";
import { _t } from "../../localization";
import { Scene, Scene_Manager } from "../../scenes";
import { esc_attr, esc_html } from "../../ui/DOM";
import { print_global_message } from "../../ui/global_message";
import Updater from "../../updater";
import { Users } from "../../users";

export class Users_Blocked_Scene extends Scene
{
    list_element!: HTMLDivElement;

    constructor()
    {
        super('users/blocked');

        this.user_state = 'active';

        this._init_element();
        this._init_nothing_found_element();
    }

    async update()
    {
        await this._load_users();
    }

    private _nothing_found_element!: HTMLDivElement

    private _init_element()
    {
        this.element.className = 'page-section';
        this.element.innerHTML = `
            <h1>${_t('users/blocked')}</h1>
            <div class="users-container"></div>`;

        this.list_element = this.element.querySelector('.users-container') as HTMLDivElement;
    }

    private _init_nothing_found_element()
    {
        this._nothing_found_element = document.createElement('div');
        this._nothing_found_element.innerHTML = `<p class="text-center">${_t('general/nothing_found')}</p>`;
    }

    private async _load_users()
    {
        if( !Auth.current_user ){
            this.list_element.innerHTML = '';
            print_global_message('error', _t('general/loading_error'));
            return;
        }
        
        const users = await Users.search({'relation': 'blocked'})
        if( !users ){
            this.list_element.innerHTML = '';
            print_global_message('error', _t('general/loading_error'));
            return;
        }

        this.list_element.innerHTML = '';

        for(const user of users){
            
            const item_element = document.createElement('div');
            item_element.className = 'main-list-item user-list-item button';
            item_element.dataset.id = user.uid;
            item_element.innerHTML = `
                <div class="image-container">
                    <img src="${esc_attr(Users.get_image_url(user.image, 'main_list_item'))}"/>
                </div>
                <div class="info-container">
                    <div class="name">${esc_html(user.first_name)} ${esc_html(user.last_name)}</div>
                </div>`;

            item_element.addEventListener('click', async () => {
                await Scene_Manager.open('user', {'uid': user.uid});
                await Updater.run();
            });

            this.list_element.append(item_element);
        }

        if( users.length === 0 )
            this.list_element.append(this._nothing_found_element);
    }
}
