import { API, API_Data, parse_API_messages } from "./api";
import * as core from './core';

export type Image = {
    uid: string;
    name: string;
    url: string;
}

export type Image_Profile = {
    name: string;
    ratio: {w: number, h: number};
    size: {w: number, h: number};
};

export type Image_Context = {
    name: string;
    profiles: Record<string, Image_Profile>;
};

export type Image_Crop = {
    profile: string;
    x: number;
    y: number;
    w: number;
    h?: number;
}

export type Media_Meta = {
    url: string;
    name: string;
    size?: { w: number, h: number };
    crops?: Record<string, Image_Crop>; // profile: string
    keywords?: Record<string, string[]>; // <lang, keyword[]>
}

export class Media
{
    static async get_image_context(name: string, log: core.Log_Messages = {}): Promise<Image_Context | undefined>
    {
        const response = await API.GET('/images/context/' + encodeURIComponent(name));
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        const context: Image_Context = {
            name: result.data['context']['name'],
            profiles: {},
        };

        result.data['context']['profiles'].forEach((profile: Image_Profile) => {
            context.profiles[profile.name] = profile;
        });

        return context;
    }

    static async get_image_meta(uid: string, log: core.Log_Messages = {}): Promise<Media_Meta | undefined>
    {
        const response = await API.GET('/image/' + encodeURIComponent(uid) + '/meta');
        if( !response )
            return;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        return this._parse_meta(result.data['meta']);
    }

    static async update_image_meta(uid: string, data: API_Data, log: core.Log_Messages = {}): Promise<boolean>
    {
        const response = await API.PUT('/image/' + encodeURIComponent(uid) + '/meta', data);
        if( !response )
            return false;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return false;

        return true;
    }

    private static _parse_crops(data: any, meta: Media_Meta)
    {
        if( data['crops'] && Array.isArray(data['crops']) ){
            meta.crops = {};
            data['crops'].forEach(crop => {
                if( !crop['profile'] || typeof crop['profile'] !== 'string' )
                    return;

                meta.crops![crop['profile']] = {
                    profile: crop['profile'],
                    x: typeof crop['x'] === 'number' ? crop['x'] : 0,
                    y: typeof crop['y'] === 'number' ? crop['y'] : 0,
                    w: typeof crop['w'] === 'number' ? crop['w'] : 0,
                    h: typeof crop['h'] === 'number' ? crop['h'] : undefined,
                };
            })
        }
    }

    private static _parse_keywords(data: any, meta: Media_Meta)
    {
        if( data['keywords'] && typeof data['keywords'] === 'object' ){
            meta.keywords = {};
            for(const lang in data['keywords']){
                if( !Array.isArray(data['keywords'][lang]) )
                    continue;

                meta.keywords[lang] = [];
                for(const i in data['keywords'][lang])
                    meta.keywords[lang].push(data['keywords'][lang][i]);
            }
        }
    }

    private static _parse_meta(data: any, meta: Media_Meta | false = false): Media_Meta | undefined
    {
        if( !core.is_object(data) )
            return;

        let name = '';
        if( data['name'] && typeof data['name'] === 'string' )
            name = data['name'];

        let url = '';
        if( data['url'] && typeof data['url'] === 'string' )
            url = data['url'];

        let size: { w: 0, h: 0 } | undefined;
        if( typeof data['size'] === 'object' )
            size = data['size'];

        if( !meta )
            meta = { name, url, size };

        this._parse_crops(data, meta);
        this._parse_keywords(data, meta);

        return meta;
    }
}
