import { _t } from "../../localization";
import { Scene } from "../../scenes";

export class Not_Found_Scene extends Scene
{
    form!: HTMLFormElement;

    constructor()
    {
        super('not_found');
    }

    async open()
    {
        await super.open();

        alert(_t('general/page_not_found'));
    }
}
