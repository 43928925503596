export const data: {[key: string]: string} = {

    "activities/all": "all",
    "activities/at_date": "At:",
    "activities/cancel_confirm": `Do you want to permanently cancel for you and all guests? Make sure you let everyone know why you're canceling. Lack of communication can lead to guests reporting your account.`,
    "activities/canceled": 'Canceled',
    "activities/chat_not_opened": "Couldn't immediately open chat. Check the chats page.",
    "activities/creation_bottom_info": `
        <ul>
            <li>After the launch, the activity can't be edited anymore.</li>
            <li>Avoid canceling when guests have already joined. If unavoidable, communicate clearly to them through the chat.</li>
        </ul>`,
    "activities/date": "Date:",
    "activities/deadline": "Deadline:",
    "activities/decline": "Decline",
    "activities/decline_confirm": "Do you want to decline permanently?",
    "activities/description_explanation": `
        <p>What do people need to think of before they join your activity? Try to be thorough. Some example are ...</p>
        <ul>
            <li>... tools and outfit.</li>
            <li>... entree fee to party.</li>
        </ul>`,
    "activities/expired": "Expired",
    "activities/form/address": "Address",
    "activities/form/city": "City",
    "activities/form/country": "Country",
    "activities/form/date_and_time": "Date of Activity",
    "activities/form/deadline": "Deadline for signing up",
    "activities/form/description": "Description",
    "activities/form/image": "Image (enter up to 3 keywords)",
    "activities/form/max_guests" : "Maximum amount of guests",
    "activities/form/max_invitees" : "Maximum amount of friends a guest can take with them",
    "activities/form/men_only" : "Men only",
    "activities/form/min_guests" : "Minimum amount of guests",
    "activities/form/name": "Name",
    "activities/form/women_only" : "Women only",
    "activities/free": "Free",
    "activities/free_question": "Free of charge for guests?",
    "activities/friends_more": "%s more",
    "activities/guest_count": "%s to %s",
    "activities/guests": "Guests:",
    "activities/history": "history",
    "activities/host": "Host:",
    "activities/invited": "Invited",
    "activities/join": "Join",
    "activities/join_confirm": "Join this activity?",
    "activities/label/multiple_friends": "+%s Friends",
    "activities/label/one_friend": "+1 Friend",
    "activities/launch": "Launch",
    "activities/leave": "Leave",
    "activities/leave_confirm": "Are you sure you want to leave? Make sure to politely notify the other participants.",
    "activities/left": "Left",
    "activities/location": "Location:",
    "activities/location_warning": "<strong>IMPORTANT:</strong> If you use your own address, you can use a pickup point near your home to avoid making your address publically available.",
    "activities/men_only": "Mannen",
    "activities/mine": "mine",
    "activities/new_activity": "New Activity",
    "activities/no_discovery": "No new activities found.",
    "activities/no_upcomming": "You have no upcoming activities.",
    "activities/no_upcomming/create": "Host an activity",
    "activities/no_upcomming/discover": "Discover activities to join",
    "activities/note/invite": "Invite a friend",
    "activities/number_of_guests": "Number of guests:",
    "activities/people_you_know": "People You Know",
    "activities/saved": "Saved",
    "activities/spots/multiple_spots_left": "%s spots left",
    "activities/spots/no_spot_left": "Fully booked",
    "activities/spots/one_spot_left": "1 spot left",
    "activities/suspended_explanation": "This activity has violated the terms and has been suspended.",
    "activities/title": "Activities",
    "activities/upcomming": "upcomming",
    "activities/women_only": "Vrouwen",
    "activities/x_more_guests": "%s more guests required",

    "auth/create": "Create",
    "auth/create_new_account": "Create new account",
    "auth/download": "Download the App",
    "auth/login": "Login",
    "auth/login_with_facebook": "Login with Facebook",
    "auth/login_with_google": "Login with Google",
    "auth/log_out": "Log Out",
    "auth/or": "or",
    "auth/password": "Password:",
    "auth/remember_me": "Remember me",
    "auth/use_existing_account": "Login with existing account",

    "chats/active_members": "Active Members",
    "chats/activity": "Activity",
    "chats/activity_deadline_passed": "Since the deadline for this activity has passed, new members are only added to the chat, but not invited to the activity.",
    "chats/add_member_to_activity": `If you want to invite a friend to the activity as well and not just to the chat, you'll need to invite them on the activity page.`,
    "chats/add_member_to_activity/go_to_activity": "Go to the activity",
    "chats/add_member_to_activity/just_chat": "Add only the chat",
    "chats/admin_assign_confirm": "Are you sure you want to make this member an admin? You won't be able to manage this chat anymore.",
    "chats/admin_leave_error": "First assign a new admin before you leave the chat.",
    "chats/chat_image": "Chat Image",
    "chats/chat_not_found": "Chat not found.",
    "chats/decline_confirm": "Are you sure you want to decline this request?",
    "chats/empty_name_error": "Enter a name.",
    "chats/former_members": "Former Members",
    "chats/group_name": "Group Name:",
    "chats/leave": "Leave",
    "chats/leave_confirm": "Are you sure you want to leave this chat?",
    "chats/make_admin": "Make admin",
    "chats/message_removed_status": "(removed)",
    "chats/message_suspended_status": "(suspended)",
    "chats/messages": "Messages",
    "chats/new_chat": "New Chat",
    "chats/new_messages": "New Messages",
    "chats/no_former_members": "No former members.",
    "chats/no_image_selected": "Select an image first.",
    "chats/no_name": "(no name)",
    "chats/no_pending_members": "No pending members.",
    "chats/not_a_group_error": "This is not a group chat.",
    "chats/not_a_member_error": "You're not a member. Only a friend can add you to the chat.",
    "chats/pending_members": "Pending Members",
    "chats/remove_member_confirm": "Are you sure you want to remove this member?",
    "chats/remove_message_confirm": "Are you sure you want to remove this message?",
    "chats/reply": "Reply",
    "chats/user_not_found": "User not found.",
    "chats/write_placeholder": "Write a message...",

    "general/add": "Add",
    "general/back": "Back",
    "general/cancel": "Cancel",
    "general/confirm": "Confirm",
    "general/early_bird": "Early Bird",
    "general/early_bird/explanation": `
        <p style="font-weight: bold">Hi early bird!</p>
        <p>
            There are now no activities in your neighborhood yet, why not organize one yourself?
            You can also change your search prefernces to discover more activities.
        </p>`,
    "general/image_loading_error": "An error occurred while loading the image.",
    "general/info": "Info",
    "general/invite": "Invite",
    "general/loading": "Loading...",
    "general/loading_error": "We could not your data. Please try again.",
    "general/more_info": "more info",
    "general/next": "Next",
    "general/no_connection": "Could not connect. Please try again.",
    "general/no_image_found": "No image found.",
    "general/no_internet": "You're not connected to het internet.",
    "general/no_server": "Could not connect to Wuddy's",
    "general/not_connected": "Not connected. Please try again.",
    "general/note": "Note",
    "general/nothing_found": "Nothing found.",
    "general/options": "Options",
    "general/or": "or",
    "general/page_not_found": "Page not found.",
    "general/premium_only": "This feature is only available for premium users.",
    "general/previous": "Previous",
    "general/remove": "Remove",
    "general/save": "Save",
    "general/servers_down": "Our servers are currently down. Please try again later.",
    "general/suspended": "Suspended",
    "general/try_again": "Try again",
    "general/unknown": "unknown",
    "general/you": "you",

    "media/invalid_image_type": "The selected image is not a JPEG or PNG.",
    "media/load_error": "The file could not be loaded. Please try again.",
    "media/select_other_image": "Select other image",

    "network/no_connection": "No network connection.",

    "notifications": "notifications",

    "push/error": "Push notification error.",
    "push/not_granted": "Push notifications are not enabled.",

    "tips/activity_info/add_member": "Click on this button to add a friend to the activity. If you're a guest, they're invited so the host can add them.",
    "tips/activity_info/cancel_or_leave": "You can cancel or leave an activity here. Make sure to handle this politely with the other participants.",
    "tips/activity_info/chat": "Click on this button to take part in this activity's chat.",
    "tips/discover/accept": "Click on this button when you are certain you'd like to join. You'll be added to the chat and the other participants are notified.",
    "tips/discover/decline": "Click on this button when you don't want to join. This activity will not be shown again.",
    "tips/discover/free/explanation": "Is this activity free of costs?",
    "tips/discover/friends/explanation": "How many friends can you take with you?",
    "tips/discover/gender": "Target",
    "tips/discover/gender/explanation": "Who is this activity for?",
    "tips/discover/known_users": "You can see participants you've met before. Friends have an orange border.",
    "tips/discover/labels": "Activities can have different labels, like:",

    "users/accept_friendship": "Accept Friendship",
    "users/accept_declined_friendship": "Accept Declined Friendship",
    "users/add_friend_description": `
        Share this link with others so they can send you a friend request.
        It they have no account, they'll be invited to create one first.
        You'll find the requests on the 'Received' requests page.`,
    "users/address": "Address:",
    "users/address_info": "The following address will be used to find activities nearby. You can update it later to discover activities in specific locations when you're on vacation for example.",
    "users/block": "Block",
    "users/block_confirm": "Do you want to block this user? Private messages will be blocked and both of you won't be able to participate at activities hosted by the other.",
    "users/blocked": "Blocked",
    "users/blocked_users": "Blocked Users",
    "users/city": "City:",
    "users/copy_link": "Copy Link",
    "users/could_not_load_user": "Could not load this user.",
    "users/country": "Country:",
    "users/date_of_birth": "Date of Birth:",
    "users/decline_friendship": "Decline Friendship",
    "users/edit_preferences": "Edit Preferences",
    "users/email": "Email:",
    "users/first_name": "First Name:",
    "users/female": "Female",
    "users/friend_list_updated": "Your list of friends has been updated.",
    "users/friend_request_sent": "Friend request sent",
    "users/friends": "Friends",
    "users/gender": "Geslacht:",
    "users/gender_preference": "Gender preference:",
    "users/gender_preference/all": "all",
    "users/gender_preference/same": "same",
    "users/invite_existing": "Invite an existing friend",
    "users/lang": "Language:",
    "users/last_name": "Last Name:",
    "users/link_copied": "Link has been copied.",
    "users/male": "Male",
    "users/max_age_diff": "Maximum age difference with host:",
    "users/meet_new": "Meet new people",
    "users/no_image_selected": "Select an image first.",
    "users/personal_info": "Personal Info",
    "users/phone": "Phone:",
    "users/preferences": "Preferences",
    "users/profile": "Profile",
    "users/profile_picture": "Profile Picture",
    "users/profile_picture/description": `
        Make sure your face is clearly visible.
        This image is visible to anyone and is primarily used for activity participant to easily find each other when meeting up.`,
    "users/received_requests": "Received",
    "users/search_radius": "Search Distance (km):",
    "users/send_friend_request": "Send Friend Request",
    "users/sent_requests": "Sent",
    "users/share": "Share",
    "users/share_friend_link": "Share Friend Link",
    "users/share_message/dialog_title": "Share Wuddy's friend link.",
    "users/share_message/text": "You can use this link to send me a Wuddy's friend request.",
    "users/share_message/title": "%s sent you a Wuddy's friend request.",
    "users/unblock": "Unblock",
    "users/unfriend": "Unfriend",
    "users/unfriend_confirm": "Are you sure you want to unfriend this person?",
    "users/verification_error": "We could not verify your account. Please contact support.",
};
