import { Chat, Chat_Manager } from "../../chats";
import { _t } from "../../localization";
import { Chat_Picker } from "../chat_picker";
import { Popup } from "./popup";

export class Chats_Popup extends Popup
{
    constructor(chat_click_cb: (chat: Chat) => void)
    {
        super('chats', { title: _t('chats/select') });

        this._chat_picker = new Chat_Picker((chat: Chat) => {
            this.close();
            chat_click_cb(chat);
        });
        this._body.append(this._chat_picker.element);
    }

    async open()
    {
        await super.open();

        const chats = Chat_Manager.get_all();
        if( !chats )
            return;

        this._chat_picker.print(
            Array.from(chats.values()).filter((c: Chat) => c.member_role === 'admin' )
        );
    }

    private _chat_picker: Chat_Picker;
}