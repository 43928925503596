/**
 * The message cache is assumed to be a continuous block without any missing messages in between.
 */

import * as io from "socket.io-client";

import { API, API_Data, parse_API_messages } from "./api";
import { Auth } from "./auth";
import * as core from "./core";
import { _t } from "./localization";
import { Network } from "./network";
import { print_global_messages } from "./ui/global_message";
import { User_Summary_Data, Users } from "./users";
import { Activities, Activity } from "./activities";

const MAX_MESSAGES_PER_REQUEST = 20; // When updating, don't forget to update the server variable as well.
export type Incomming_Message_Data = {
    chat: Chat,
    messages: Message[],
};

export class Message
{
    id = 0;
    chat = '';
    user = '';
    content = '';
    reply_to?: Message;
    type = '';
    status = '';
    created_at?: Date;
}

class Message_Cache
{
    is_first_loaded = false;
    end_datetime?: Date;

    messages = new Array<Message>;
    message_map = new Map<number, Message>;
}

export class Chat
{
    uid = '';
    name = '';
    type = '';
    image = '';
    last_message_datetime?: Date;
    created_at?: Date;
    updated?: Date;
    group_size = 0;

    members = new Array<User_Summary_Data>;
    member_map = new Map<string, User_Summary_Data>;
    member_last_read_message = 0;
    member_role = '';
    member_status = '';
    member_unread_count = 0;

    _activities_cache: Activity[] | undefined;
    message_cache = new Message_Cache;

    reset()
    {
        this.message_cache = new Message_Cache;
    }
}

export class Messages
{
    static parse(data: any, message: Message | false = false): Message | undefined
    {
        if( !core.is_object(data) )
            return;

        if( !message )
            message = new Message

        message.id = data.id || 0
        message.chat = data.chat;
        message.user = data.user;
        message.content = data.content || '';
        message.reply_to = data.reply_to ? this.parse(data.reply_to) : undefined;
        message.type = data.type || '';
        message.status = data.status || '';
        message.created_at = core.UTC(data.created_at);
        
        return message;
    }

    static async remove(message: Message | number, log: core.Log_Messages = {}): Promise<boolean>
    {
        const message_id = typeof message === 'object' ? message.id : message;

        const response = await API.DELETE(`/message/${message_id}`);
        if( !response )
            return false;

        const result = await response.json();
        parse_API_messages(log, result);

        return response.status === 200;
    }

    static async update_last_read(message: Message | number, log: core.Log_Messages = {}): Promise<boolean>
    {
        const message_id = typeof message === 'object' ? message.id : message;

        const response = await API.PUT(`/message/${message_id}/last_read`);
        if( !response )
            return false;

        const result = await response.json();
        parse_API_messages(log, result);

        return response.status === 200;
    }
}

export class Chats
{
    static async add_member(chat: Chat, user: User_Summary_Data, log: core.Log_Messages = {}): Promise<boolean>
    {
        const response = await API.PUT(`/chat/${encodeURIComponent(chat.uid)}/member`, {user: user.uid});
        if( !response )
            return false;

        const result = await response.json();
        parse_API_messages(log, result);

        return response.status === 200;
    }

    static async add_members(chat: Chat, users: User_Summary_Data[], log: core.Log_Messages = {}): Promise<boolean>
    {
        const uids = users.map(user => user.uid);
        const response = await API.PUT(`/chat/${encodeURIComponent(chat.uid)}/members`, {users: uids});
        if( !response )
            return false;

        const result = await response.json();
        parse_API_messages(log, result);

        return response.status === 200;
    }

    static async create_group_chat(name: string, log: core.Log_Messages = {}): Promise<Chat | undefined>
    {
        const response = await API.POST('/chat/group', {'name': name});
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        return Chats.parse(result.data.chat);
    }

    static async create_private_chat(user: string, log: core.Log_Messages = {}): Promise<Chat | undefined>
    {
        const response = await API.POST('/chat/private', {'user': user});
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        return Chats.parse(result.data.chat);
    }

    static async get(uid: string, log: core.Log_Messages = {}): Promise<Chat | undefined>
    {
        const response = await API.GET('/chat/' + encodeURIComponent(uid));
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        return this.parse(result.data.chat);
    }

    static async get_all(data: API_Data, log: core.Log_Messages = {}): Promise<Map<string, Chat> | undefined>
    {
        const response = await API.GET('/chats', data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        const chats = new Map<string, Chat>();
        for(const idx in result.data.chats){
            const chat = this.parse(result.data.chats[idx]);
            if( chat )
                chats.set(chat.uid, chat);
        }

        return chats;
    }

    /** Returns ordered by datetime in reverse chronological order. */
    static async get_activities(chat: Chat, log: core.Log_Messages = {}): Promise<Activity[] | undefined>
    {
        if( chat._activities_cache )
            return chat._activities_cache;

        const response = await API.GET(`/chat/${encodeURIComponent(chat.uid)}/activities`);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        chat._activities_cache = new Array<Activity>;
        for(const idx in result.data.activities){
            const activity = Activities.parse(result.data.activities[idx]);
            if( activity )
                chat._activities_cache.push(activity);
        }

        return chat._activities_cache;
    }

    static get_image_url(image?: string, context='')
    {
        if( image )
            return image + '?context=' + encodeURIComponent(context);
        else
            return '/assets/images/default-chat-image.png';
    }

    static async get_messages(chat: Chat, data: API_Data, log: core.Log_Messages = {}): Promise<Message[] | undefined>
    {
        const response = await API.GET(`/chat/${encodeURIComponent(chat.uid)}/messages`, data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        const messages = new Array<Message>;
        for(const idx in result.data.messages){
            const message = Messages.parse(result.data.messages[idx]);
            if( message )
                messages.push(message);
        }

        return messages;
    }

    static parse(data: any, chat: Chat | false = false): Chat | undefined
    {
        if( !core.is_object(data) )
            return;

        if( !chat )
            chat = new Chat

        chat.uid = data.uid || '';
        chat.name = data.name || '';
        chat.type = data.type || '';
        chat.image = data.image || '';
        chat.last_message_datetime = core.UTC(data.last_message_datetime);
        chat.created_at = core.UTC(data.created_at);
        chat.updated = core.UTC(data.updated);
        chat.group_size = data.group_size || 0;

        chat.members = Users.parse_summary_array(data.members);
        chat.members.forEach(member => chat.member_map.set(member.uid, member));

        chat.member_last_read_message = data.member_last_read_message || 0;
        chat.member_role = data.member_role || '';
        chat.member_status = data.member_status || '';
        chat.member_unread_count = data.member_unread_count || 0;

        return chat;
    }

    static async remove_member(chat: Chat, user: User_Summary_Data, log: core.Log_Messages = {}): Promise<boolean>
    {
        const response = await API.DELETE(`/chat/${encodeURIComponent(chat.uid)}/member`, {user: user.uid});
        if( !response )
            return false;

        const result = await response.json();
        parse_API_messages(log, result);

        return response.status === 200;
    }

    static async send_message(chat: Chat, content: string, reply_to?: Message, log: core.Log_Messages = {}): Promise<boolean>
    {
        const response = await API.POST(`/chat/${encodeURIComponent(chat.uid)}/message`, {content, reply_to: reply_to?.id});
        if( !response )
            return false;

        const result = await response.json();
        parse_API_messages(log, result);

        return response.status === 200;
    }

    static async update_admin(chat: Chat, user: User_Summary_Data, log: core.Log_Messages = {}): Promise<boolean>
    {
        const response = await API.PUT(`/chat/${encodeURIComponent(chat.uid)}/admin`, {user: user.uid});
        if( !response )
            return false;

        const result = await response.json();
        parse_API_messages(log, result);

        return response.status === 200;
    }

    static async update(chat: Chat, data: API_Data, log: core.Log_Messages = {}): Promise<boolean>
    {
        const response = await API.PUT(`/chat/${encodeURIComponent(chat.uid)}`, data);
        if( !response )
            return false;

        const result = await response.json();
        parse_API_messages(log, result);

        return response.status === 200;
    }
}

export class Chat_Manager
{
    /**
     * Use 'add_members' for adding multiple members instead of this one.
     * It avoids receiving a chat update for each individual user.
     * You will receive the specific messages with this though.
     */
    static add_member(chat: Chat, user: User_Summary_Data, log: core.Log_Messages = {}): Promise<boolean>
    {
        return Chats.add_member(chat, user, log);
    }

    /**
     * Use this for adding multiple members instead of this one.
     * It avoids receiving a chat update for each individual user.
     * You will receive summarizing messages.
     */
    static add_members(chat: Chat, users: User_Summary_Data[], log: core.Log_Messages = {}): Promise<boolean>
    {
        return Chats.add_members(chat, users, log);
    }

    static async create_group_chat(name: string, log: core.Log_Messages = {}): Promise<Chat | undefined>
    {
        const chat = await Chats.create_group_chat(name, log);
        if( chat ){
            this._cache.set(chat.uid, chat);
            this._sort_chats();
        }

        return chat;
    }

    static async create_private_chat(user: string, log: core.Log_Messages = {}): Promise<Chat | undefined>
    {
        const chat = await Chats.create_private_chat(user, log);
        if( chat ){
            this._cache.set(chat.uid, chat);
            this._sort_chats();
        }

        return chat;
    }

    static async get(uid: string, log: core.Log_Messages = {}): Promise<Chat | undefined>
    {
        let chat = Chat_Manager._cache.get(uid);
        if( chat )
            return chat;

        chat = await Chats.get(uid, log);
        if( chat )
            Chat_Manager._cache.set(uid, chat);

        return chat;
    }

    static get_all(): Map<string, Chat> | undefined
    {
        return Chat_Manager._cache;
    }

    static async get_message_history(chat: Chat, first: number | undefined, last: number | undefined): Promise<Message[] | undefined>
    {
        const new_messages = new Array<Message>;
        while(true){

            let messages = this._get_message_cache(chat, first, last, 'desc');
            if( !messages ){

                messages = await Chats.get_messages(chat, {
                    'first': first ? first : '',
                    'last': last ? last : '',
                    'order': 'desc',
                });
                if( !messages )
                    return;

                this._add_messages_to_cache(messages);
            }

            if( messages.length === 0 ){
                if( first === undefined )
                    chat.message_cache.is_first_loaded = true;
                break;
            }

            last = messages.reduce((id, message) => id === undefined || message.id < id ? message.id : id, last)! - 1;
            new_messages.push(...messages);

            // Without an upper boundry just one batch in enough.
            if( first === undefined )
                break;
        }

        this._sort_messages(new_messages);
        this._update_read_count(chat);

        return new_messages;
    }

    static get_private_chat_by_user(user: string): Chat | undefined
    {
        for(const chat of this._cache.values()){
            if( chat.type === 'private'
             && chat.members.length === 2
             && (chat.members[0].uid === user || chat.members[1].uid === user) )
                return chat;
        }

        return;
    }
    
    // Check for new data since last request.
    static async get_recent_messages(chat: Chat): Promise<Message[] | undefined>
    {
        const message_cache = chat.message_cache;

        // If nothing has been loaded at all, we don't need to check as they will be loaded later anyway.
        if( message_cache.messages.length === 0 && !message_cache.is_first_loaded )
            return [];

        const new_messages = new Array<Message>;

        while(true){
            const last_message = message_cache.messages[message_cache.messages.length - 1];
            const messages = await Chats.get_messages(chat, {
                'first': last_message ? last_message.id + 1 : '', // +1: load after this message.
            });
            if( !messages )
                return;

            if( messages.length === 0 )
                break;

            new_messages.push(...messages);
            this._add_messages_to_cache(messages);
        }

        this._sort_messages(new_messages);
        this._update_read_count(chat);

        return new_messages;
    }

    static async init(socket: io.Socket): Promise<void>
    {
        addEventListener('network_connection', async () => {
            if( !Network.is_connected() )
                return;

            await this._load_chats(this._last_chat_updated_datetime);
            this._last_chat_updated_datetime = core.UTC();
            
            dispatchEvent(new CustomEvent('update_chats'));
        });

        socket.on('chats/receive_messages', this._on_receive_messages.bind(this));
        socket.on('chats/update_chat', this._on_update_chat.bind(this));

        await this._load_chats();
    }

    static remove_member(chat: Chat, user: User_Summary_Data, log: core.Log_Messages = {}): Promise<boolean>
    {
        return Chats.remove_member(chat, user, log);
    }

    static remove_message(message: Message): Promise<boolean>
    {
        return Messages.remove(message);
    }

    static send_message(chat: Chat, content: string, reply_to?: Message, log: core.Log_Messages = {}): Promise<boolean>
    {
        return Chats.send_message(chat, content, reply_to, log);
    }

    static update_admin(chat: Chat, user: User_Summary_Data, log: core.Log_Messages = {}): Promise<boolean>
    {
        return Chats.update_admin(chat, user, log);
    }

    static update_chat(chat: Chat, data: API_Data, log: core.Log_Messages = {}): Promise<boolean>
    {
        return Chats.update(chat, data, log);
    }

    static async update_image(chat: Chat, image: Blob, log: core.Log_Messages = {}): Promise<Chat | undefined>
    {
        const form_data = new FormData;
        form_data.append('image', image);

        const response = await API.POST('/chat/' + encodeURIComponent(chat.uid) + '/image', form_data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        return Chats.parse(result.data.chat, chat);
    }

    static async update_last_read_message(chat: Chat, message: Message | number): Promise<boolean>
    {
        const message_id = typeof message === 'object' ? message.id : message;
        if( message_id <= chat.member_last_read_message )
            return false;

        if( !await Messages.update_last_read(message) )
            return false;

        chat.member_last_read_message = message_id;

        this._update_read_count(chat);

        return true;
    }

    private static _cache = new Map<string, Chat>;
    private static _last_chat_updated_datetime?: Date;

    private static _add_message_to_cache(message: Message): void
    {
        const chat_cache = this._cache.get(message.chat);
        if( !message.chat || !chat_cache )
            return;

        const message_cache_data = chat_cache.message_cache;

        if( message.created_at && (!chat_cache.last_message_datetime || message.created_at > chat_cache.last_message_datetime) )
            chat_cache.last_message_datetime = message.created_at;

        if( message_cache_data.message_map.has(message.id) ){
            message_cache_data.messages = message_cache_data.messages.map(m => m.id === message.id ? message : m);
        }else{
            message_cache_data.messages.push(message);
            this._sort_messages(message_cache_data.messages);
        }
        message_cache_data.message_map.set(message.id, message);
    }

    private static _add_messages_to_cache(messages: Message[]): void
    {
        messages.forEach(message => this._add_message_to_cache(message));
    }

    private static _get_message_cache(chat: Chat, first: number | undefined, last: number | undefined, order: 'asc' | 'desc'): Message[] | undefined
    {
        const chat_cache = this._cache.get(chat.uid);
        if( !chat_cache )
            return;

        let messages = new Array<Message>;
        const message_cache_data = chat_cache.message_cache;

        if( first !== undefined || last !== undefined ){
            for(let i = 0; i < message_cache_data.messages.length; i++){

                let idx = i;
                // If in reverse order, we can save cycles by starting at the bottom.
                if( order === 'desc' )
                    idx = message_cache_data.messages.length - i - 1;

                const message = message_cache_data.messages[idx];

                if( first !== undefined && message.id < first )
                    continue;

                if( last !== undefined && message.id > last )
                    continue;

                messages.push(message);
                
                // If only one limit is set, we can break early.
                if( (first !== last) && messages.length >= MAX_MESSAGES_PER_REQUEST )
                    break;
            }
        }else{
            messages = message_cache_data.messages.slice(-MAX_MESSAGES_PER_REQUEST, message_cache_data.messages.length);
        }

        // Check if no cached messages were found, but not all messages were yet cached.
        // We return 'undefined' so the returning function knows they need to request the server.
        if( messages.length === 0 && !message_cache_data.is_first_loaded )
            return;
        
        return messages;
    }

    private static async _load_chats(updated_after?: Date): Promise<void>
    {
        if( !Auth.current_user )
            return;

        const logs = {};
        
        const date_string = updated_after ? core.serialize_datetime(updated_after) : '';

        const chats = await Chats.get_all({'after': date_string}, logs);
        if( !chats ){
            print_global_messages(logs);
            return;
        }

        for(const chat of chats.values()){
            const chat_cache = this._cache.get(chat.uid);
            if( chat_cache )
                chat.message_cache = chat_cache.message_cache;

            if( chat.updated && (!this._last_chat_updated_datetime || chat.updated > this._last_chat_updated_datetime) )
                this._last_chat_updated_datetime = chat.updated;

            this._cache.set(chat.uid, chat);
        }
    }

    private static _on_receive_messages(data: any)
    {
        if( !Auth.current_user )
            return;

        if( typeof data !== 'object' )
            return;

        const all_chat_data = data['chats'];
        const result: Record<string, Incomming_Message_Data> = {};
        for(const chat_data of all_chat_data){

            const chat = this._cache.get(chat_data['uid']);
            if( !chat )
                continue;

            const chat_result: Incomming_Message_Data = {
                'chat': chat,
                'messages': new Array<Message>,
            };

            const all_message_data = chat_data['messages'];
            for(const message_data of all_message_data){

                const message = Messages.parse(message_data);
                if( !message )
                    continue;

                // Avoid adding a message that is not yet in cache as it will be seen as the oldest loaded message.
                // History will be loaded from this updated message, leaving a gap in the cache.
                if( data['context'] === 'update' && !chat.message_cache.message_map.has(message.id) )
                    continue;

                this._add_message_to_cache(message);
                chat_result['messages'].push(message);
            }

            this._update_read_count(chat);
            this._sort_messages(chat.message_cache.messages);

            result[chat.uid] = chat_result;
        }

        dispatchEvent(
            new CustomEvent('receive_messages', {
                detail: {
                    'chats': result,
                    'context': data['context']
                }
            })
        );
    }

    private static _on_update_chat(data: any)
    {
        if( typeof data !== 'object' )
            return;
        
        const chat_data = data['chat'];
        if( !chat_data )
            return;

        const chat = Chats.parse(chat_data);
        if( !chat )
            return;

        const cache_data = this._cache.get(chat.uid);
        if( cache_data ){
            // Messages might be missing that are between other messages. For simplicity, we just reload the page.
            if( !(cache_data.member_status !== 'active' && chat.member_status === 'active') )
                chat.message_cache = cache_data.message_cache;
        }

        this._cache.set(chat.uid, chat);

        this._sort_chats();

        dispatchEvent(new CustomEvent('update_chats'));
    }

    private static _sort_chats()
    {
        this._cache = new Map([...this._cache.entries()].sort((a, b) => {
            if( !b[1].last_message_datetime )
                return -1;

            if( !a[1].last_message_datetime )
                return 1;

            return b[1].last_message_datetime!.getTime() - a[1].last_message_datetime!.getTime()
        }));
    }

    private static _sort_messages(messages: Message[]): Message[]
    {
        return messages.sort((a, b) => a.id - b.id);
    }

    private static _update_read_count(chat: Chat)
    {
        chat.member_unread_count = chat.message_cache.messages.reduce((count, message) => {
            return count + Number(message.id > chat.member_last_read_message && message.user !== Auth.current_user!.uid);
        }, 0);
    }
}
