import { Auth } from "../../auth";
import { _t } from "../../localization";
import { Scene, Scene_Manager } from "../../scenes";
import { esc_attr, esc_html } from "../../ui/DOM";
import { Friends_Menu } from "../../ui/friends_menu";
import { print_global_message } from "../../ui/global_message";
import { Add_Friend_Popup } from "../../ui/popups/add_friend_popup";
import Updater from "../../updater";
import { Users }  from "../../users";

export class Users_Friends_Scene extends Scene
{
    constructor()
    {
        super('users/friends');

        this.user_state = 'active';

        this._init_element();
        this._init_nothing_found_element();
        this._init_tools_element();
    }

    async update()
    {
        await this._load_users();
        this._friends_menu.update(); // After loading the users since it uses the most recent data.
    }

    private _friends_menu!: Friends_Menu;
    private _list_element!: HTMLDivElement;
    private _nothing_found_element!: HTMLDivElement;

    private _init_element()
    {
        this.element.className = 'page-section';
        this.element.innerHTML = `
            <button class="create-chat add-button button round primary" style="float: right; margin-top: 15px;">+</button>

            <h1>${_t('users/friends')}</h1>
            <div class="users-container"></div>`;

        const create_button = this.element.querySelector('.create-chat') as HTMLButtonElement;
        create_button.addEventListener('click', () => {
            new Add_Friend_Popup().open();
        });

        this._list_element = this.element.querySelector('.users-container') as HTMLDivElement;
    }

    private _init_nothing_found_element()
    {
        this._nothing_found_element = document.createElement('div');
        this._nothing_found_element.className = 'center-abs full-width';
        this._nothing_found_element.style.padding = '20px';
        this._nothing_found_element.innerHTML = `
            <p class="text-center">${_t('general/nothing_found')}</p>
            <button class="activity-button full-width" style="margin-bottom: 20px">${_t('users/meet_new')}</button>
            <button class="friend-request-button full-width">${_t('users/invite_existing')}</button>`;

        this._nothing_found_element.querySelector('.activity-button')!.addEventListener('click', async () => {
            await Scene_Manager.open('activities/discover');
            await Updater.run();
        });

        this._nothing_found_element.querySelector('.friend-request-button')!.addEventListener('click', async () => {
            await Scene_Manager.open('users/requests/out');
            await Updater.run();
        });
    }

    private _init_tools_element()
    {
        this.tools_element = document.createElement('div');
        this._friends_menu = new Friends_Menu(this.tools_element, this.path);
    }

    private async _load_users()
    {
        if( !Auth.current_user ){
            this._list_element.innerHTML = '';
            print_global_message('error', _t('general/loading_error'));
            return;
        }
        
        const users = await Users.search({'relation': 'friend'})
        if( !users ){
            this._list_element.innerHTML = '';
            print_global_message('error', _t('general/loading_error'));
            return;
        }

        this._list_element.innerHTML = '';

        for(const user of users){

            const item_element = document.createElement('div');
            item_element.className = 'main-list-item user-list-item button';
            item_element.dataset.id = user.uid;
            item_element.innerHTML = `
                <div class="image-container">
                    <img src="${esc_attr(Users.get_image_url(user.image, 'main_list_item'))}"/>
                </div>
                <div class="info-container">
                    <div class="name">${esc_html(user.first_name)} ${esc_html(user.last_name)}</div>
                </div>`;

            item_element.addEventListener('click', async () => {
                await Scene_Manager.open('user', {'uid': user.uid});
                await Updater.run();
            })

            this._list_element.append(item_element);
        }

        if( users.length === 0 )
            this._list_element.append(this._nothing_found_element);
    }
}
