import { Auth } from "../auth";
import { _t } from "../localization";
import { Scene, Scene_Manager } from "../scenes";
import { print_global_message, print_global_messages } from "../ui/global_message";
import { Image_Picker } from "../ui/image_picker";
import { Updater } from "../updater";
import { Users } from "../users";
import globals from "../.env";

export class Profile_Picture_Scene extends Scene
{
    image_picker?: Image_Picker;

    constructor(updater: Updater)
    {
        super(updater, 'profile/picture');

        this.user_state = 'logged_in'

        this.element.className = 'page-section';
        this.element.innerHTML = `
            <h1>${_t('users/profile_picture')}</h1>

            <p>${_t('users/profile_picture/description')}</p>

            <div class="image-picker-container"></div>

            <div class="flex-equal horizontal-rounded-content" style="margin-top: 40px">
                <button class="primary" name="submit">${_t('general/save')}</button>
                <button name="cancel" type="button">${_t('general/cancel')}</button>
            </div>`;

        this._save_button = this.element.querySelector("[name='submit']")!;
        this._save_button.addEventListener('click', this._on_submit_click.bind(this));

        this._cancel_button = this.element.querySelector("[name='cancel']")!;
        this._cancel_button.addEventListener('click', () => {
            Scene_Manager.go_back();
            this._updater.run();
        });
    }

    async open(args: {[key: string]: string} = {})
    {
        await super.open();

        this._is_setup = !!args['is_setup'];

        const container = this.element.querySelector('.image-picker-container')!;
        container.innerHTML = '';

        this.image_picker = new Image_Picker;
        container.append(this.image_picker.element);

        if( this._is_setup )
            this._cancel_button.remove();
        else
            this._save_button.parentElement?.append(this._cancel_button);
    }

    private _cancel_button: HTMLButtonElement;
    private _is_setup = false;
    private _save_button: HTMLButtonElement;

    private async _on_submit_click(_: Event)
    {
        if( !Auth.current_user )
            return false;
        
        if( !this.image_picker || !this.image_picker.file ){
            print_global_message('error', _t('users/no_image_selected'));
            return;
        }
        
        const messages = {};
        const result = await Users.update_image(Auth.current_user, this.image_picker.file, messages);
        print_global_messages(messages);
        if( !result )
            return;

        if( this._is_setup ){
            await Scene_Manager.open(globals.HOME_PATH);
            location.reload();
            return;
        }

        await Scene_Manager.open('profile');
        
        this._updater.run();
    }
}
