import { Auth } from "../../auth";
import { Chat, Chat_Manager } from "../../chats";
import { _t } from "../../localization";
import { Scene, Scene_Manager } from "../../scenes";
import { Chat_Picker } from "../../ui/chat_picker";
import { _ } from "../../ui/DOM";
import { print_global_message, print_global_messages } from "../../ui/global_message";
import { Friends_Popup } from "../../ui/popups/friends_popup";
import { Input_Popup } from "../../ui/popups/input_popup";
import Updater from "../../updater";
import { User } from "../../users";

export class Chats_Scene extends Scene
{
    constructor()
    {
        super('chats');

        this.user_state = 'active';

        this._chat_picker = new Chat_Picker(async (chat) => {
            await Scene_Manager.open('chat', {'uid': chat.uid});
            await Updater.run();
        });

        this._init_element();
        this._init_nothing_found_element();

        addEventListener('update_chats', (() => {
            if( this.is_active )
                this.print();
        }) as EventListener);

        addEventListener('receive_messages', ((event: CustomEvent) => {
            if( this.is_active )
                this.print();
        }) as EventListener);
    }

    async open()
    {
        await super.open();
        await this.print();
    }

    async print()
    {
        if( !Auth.current_user ){
            await this._print_list([]);
            print_global_message('error', _t('general/loading_error'));
            return;
        }
        
        const chats = Chat_Manager.get_all();
        if( !chats ){
            await this._print_list([]);
            print_global_message('error', _t('general/loading_error'));
            return;
        }

        if( chats.size === 0 ){
            await this._print_list([]);
            return;
        }

        await this._print_list(Array.from(chats.values()));
    }

    private _chat_picker: Chat_Picker;
    private _list_holder = _('div');
    private _new_chat_name = '';
    private _nothing_found_element!: HTMLDivElement;

    private _init_element()
    {
        this.element.append(
            _('div', {class: 'page-section'}, [
                _('button', {
                    class: 'create-chat add-button button round primary',
                    style: 'float: right; margin-top: 15px'
                }, '+'),
                _('h1', {}, _t('chats/messages')),
                this._list_holder,
            ])
        );

        const create_button = this.element.querySelector('.create-chat') as HTMLButtonElement;
        create_button.addEventListener('click', this._on_create_chat_button_click.bind(this));
    }

    private _init_nothing_found_element()
    {
        this._nothing_found_element = document.createElement('div');
        this._nothing_found_element.innerHTML = `
            <p class="text-center">${_t('general/nothing_found')}</p>
            <button class="discover-button full-width" style="margin-bottom: 20px">${_t('users/meet_new')}</button>
            <button class="friend-request-button full-width">${_t('users/invite_existing')}</button>`;

        this._nothing_found_element.querySelector('.discover-button')!.addEventListener('click', async () => {
            await Scene_Manager.open('activities/discover');
            await Updater.run();
        });

        this._nothing_found_element.querySelector('.friend-request-button')!.addEventListener('click', async () => {
            await Scene_Manager.open('users/requests/in');
            await Updater.run();
        });
    }

    private _on_create_chat_button_click()
    {
        const input_popup = new Input_Popup('group_name', _t('chats/group_name'), 'text', _t('chats/new_chat'), async (value) => {

            if( !value ){
                alert(_t('chats/empty_name_error'));
                return;
            }
            
            this._new_chat_name = value;

            const friends_popup = new Friends_Popup(_t('general/add'), this._on_create_chat_friends_selected.bind(this));
            friends_popup.open();

            input_popup.close();
        });

        input_popup.open();
    }

    private async _on_create_chat_friends_selected(friends: User[])
    {
        if( !Auth.current_user )
            return;

        const log = {};

        const chat = await Chat_Manager.create_group_chat(this._new_chat_name, log);
        if( !chat ){
            print_global_messages(log);
            return;
        }

        for(const friend of friends)
            await Chat_Manager.add_member(chat, friend);

        await Scene_Manager.open('chat', {'uid': chat.uid});
        await Updater.run();
    }

    private async _print_list(chats: Chat[])
    {
        await this._chat_picker.print(chats);

        if( chats.length === 0 ){
            this._chat_picker.element.remove();
            this._list_holder.append(this._nothing_found_element);
        }else{
            this._list_holder.append(this._chat_picker.element);
            this._nothing_found_element.remove();
        }
    }
}
