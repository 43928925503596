import { API, API_Data, parse_API_messages } from "./api";
import * as core from "./core";

export const USER_UID_LENGTH = 10;

export type User_Status = 'active' | 'suspended' | 'removed' | 'unverified';

export type Relation = {
    known: boolean;
    status: '' | 'friend' | 'friend_request_sent' | 'friend_request_received' | 'friend_request_declined' | 'blocked';
}

export type Chat_Data = {
    color: string;
    role: '' | 'user' | 'admin';
    status: '' | 'active' | 'left' | 'pending' | 'removed';
}

export class User
{
    uid = '';
    email = '';
    first_name = '';
    last_name = '';
    gender = '';
    date_of_birth = '';
    max_age_diff = 0;
    phone = '';
    address = '';
    city = '';
    country = '';
    lang = '';
    radius = 0;
    gender_preference = '';
    status: User_Status = 'active';
    account_type = '';
    image = '';
    created_at?: Date;

    relation: Relation = {
        known: false,
        status: '',
    };

    chat_data: Chat_Data = {
        color: '',
        role: '',
        status: '',
    };

    verified: string[] = [];
}

export class User_Summary_Data
{
    uid = '';
    first_name = '';
    last_name = '';
    image = '';

    relation: Relation = {
        known: false,
        status: '',
    };

    chat_data: Chat_Data = {
        color: '',
        role: '',
        status: '',
    };
}

export class Users
{
    static _last_received_friend_requests?: Array<User>;
    static token?: string;

    static async befriend(auth_user: User, data: API_Data, log: core.Log_Messages = {}): Promise<User | undefined>
    {
        const response = await API.PUT(`/user/${encodeURIComponent(auth_user.uid)}/befriend`, data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        return this.parse(result.data.user);
    }

    static async block(auth_user: User, data: API_Data, log: core.Log_Messages = {}): Promise<User | undefined>
    {
        const response = await API.PUT(`/user/${encodeURIComponent(auth_user.uid)}/block`, data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        return this.parse(result.data.user);
    }

    static async create(data: API_Data, log: core.Log_Messages = {}): Promise<User | undefined>
    {
        const response = await API.POST('/user', data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        return this.parse(result.data.user);
    }

    static generate_friend_link(user: User)
    {
        return core.get_web_url() + '/user?uid=' + user.uid;
    }

    static async get(uid: string, log: core.Log_Messages = {}): Promise<User | undefined>
    {
        const response = await API.GET('/user/' + encodeURIComponent(uid));
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        return this.parse(result.data.user);
    }

    static async get_current(log: core.Log_Messages = {}): Promise<User | undefined>
    {
        const response = await API.GET('/user');
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        return this.parse(result.data.user);
    }

    static get_image_url(image?: string, context='')
    {
        if( image )
            return image + '?context=' + encodeURIComponent(context);
        else
            return '/assets/images/default-profile-picture.png';
    }

    static async get_received_friend_requests(use_cache_if_available = false): Promise<Array<User> | undefined>
    {
        if( this._last_received_friend_requests && use_cache_if_available )
            return this._last_received_friend_requests;

        const users = await Users.search({'relation': 'friend_request_received'})
        if( !users )
            return;

        this._last_received_friend_requests = users;
        
        return users;
    }

    static parse(data: any, user: User | false = false): User | undefined
    {
        if( !core.is_object(data) )
            return;

        if( !user )
            user = new User;

        user.uid = data.uid || '';
        user.email = data.email || '';
        user.first_name = data.first_name || '';
        user.last_name = data.last_name || '';
        user.gender = data.gender || '';
        user.date_of_birth = data.date_of_birth || '';
        user.max_age_diff = data.max_age_diff || 0;
        user.phone = data.phone || '';
        user.address = data.address || '';
        user.city = data.city || '';
        user.country = data.country || '';
        user.lang = data.lang || '';
        user.radius = data.radius || 0;
        user.gender_preference = data.gender_preference || '';
        user.status = data.status || '';
        user.account_type = data.account_type || '';
        user.image = data.image || '';
        user.created_at = core.UTC(data.created_at);
        user.verified = data.verified || [];

        if( data.relation ){
            user.relation = {
                known: data.relation?.known || '',
                status: data.relation?.status || '',
            };
        }

        if( data.chat_data ){
            user.chat_data = {
                color: data.chat_data?.color || '',
                role: data.chat_data?.role || '',
                status: data.chat_data?.status || '',
            }
        }

        return user;
    }

    static parse_summary(data: any, user: User_Summary_Data | false = false): User_Summary_Data | undefined
    {
        if( !core.is_object(data) )
            return;

        if( !user )
            user = new User_Summary_Data;

        user.uid = data.uid || '';
        user.first_name = data.first_name || '';
        user.last_name = data.last_name || '';
        user.image = data.image || '';

        if( data.relation ){
            user.relation = {
                known: data.relation?.known || '',
                status: data.relation?.status || '',
            };
        }

        if( data.chat_data ){
            user.chat_data = {
                color: data.chat_data?.color || '',
                role: data.chat_data?.role || '',
                status: data.chat_data?.status || '',
            };
        }

        return user;
    }

    static parse_summary_array(data: any): Array<User_Summary_Data>
    {
        const users = new Array<User_Summary_Data>;
        if( Array.isArray(data) ){
            for(const single_data of data){
                const summary = Users.parse_summary(single_data);
                if( summary )
                    users.push(summary);
            }
        }

        return users;
    }
    
    static async soft_remove(user: User, log: core.Log_Messages = {}): Promise<boolean>
    {
        const response = await API.PUT(`/user/${encodeURIComponent(user.uid)}/soft_remove`);
        if( !response )
            return false;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return false;

        return true;
    }

    static async search(data: API_Data, log: core.Log_Messages = {}): Promise<Array<User> | undefined>
    {
        const response = await API.GET('/users', data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        const users = new Array<User>;
        for(const idx in result.data.users){
            const user = this.parse(result.data.users[idx]);
            if( user )
                users.push(user);
        }

        return users;
    }

    static async unblock(auth_user: User, data: API_Data, log: core.Log_Messages = {}): Promise<User | undefined>
    {
        const response = await API.PUT(`/user/${encodeURIComponent(auth_user.uid)}/unblock`, data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        return this.parse(result.data.user);
    }

    static async unfriend(auth_user: User, data: API_Data, log: core.Log_Messages = {}): Promise<User | undefined>
    {
        const response = await API.PUT(`/user/${encodeURIComponent(auth_user.uid)}/unfriend`, data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;
        
        return this.parse(result.data.user);
    }

    static async update(user: User, data: API_Data, log: core.Log_Messages = {}): Promise<User | undefined>
    {
        const response = await API.PUT('/user/' + encodeURIComponent(user.uid), data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        return this.parse(result.data.user, user);
    }

    static async update_image(user: User, image: Blob, log: core.Log_Messages = {}): Promise<User | undefined>
    {
        const form_data = new FormData;
        const name = image.name || `image (${core.UTC().toLocaleString()})`

        form_data.append('image', image, name);

        const response = await API.POST('/user/' + encodeURIComponent(user.uid) + '/image', form_data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        return this.parse(result.data.user, user);
    }
}
