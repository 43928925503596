import { _t } from "../../localization";
import { Popup } from "./popup";

export class Chat_Add_Activity_Member_Popup extends Popup
{
    constructor(activity_callback: () => void, just_chat_callback: () => void)
    {
        super('chat_add_activity_member', { title: _t('general/info') });

        const content = document.createElement('div');
        content.className = 'text-center';
        content.style.margin = '10px';
        content.innerHTML = `
            <img src="/assets/images/nav-activities.png" style="margin: 10px">
            <p>${_t('chats/add_member_to_activity')}</p>`;
        this._body.appendChild(content);

        const activity_button = document.createElement('button');
        activity_button.className = 'full-width primary';
        activity_button.innerHTML = _t('chats/add_member_to_activity/go_to_activity');
        activity_button.style.marginTop = '10px';
        activity_button.addEventListener('click', () => { this.close(); activity_callback(); });
        content.appendChild(activity_button);

        const just_chat_button = document.createElement('button');
        just_chat_button.className = 'full-width';
        just_chat_button.innerHTML = _t('chats/add_member_to_activity/just_chat');
        just_chat_button.style.marginTop = '10px';
        just_chat_button.addEventListener('click', () => { this.close(); just_chat_callback(); });
        content.appendChild(just_chat_button);
    }
}
