import { esc_html } from "../DOM";

export type Popup_Args =
{
    has_close_button?: boolean;
    has_header?: boolean;
    title: string;
}

export class Popup
{
    name: string; // Should be [A-Za-z0-9_-] as it will be used in the URL hash.

    constructor(name: string, { has_close_button = true, has_header = true, title }: Popup_Args)
    {
        this.name = name;
        this._can_close = has_close_button;

        this._element = document.createElement('div');
        this._element.className = 'popup ' + this.name + '_popup';

        if( has_header ){
            this._header = document.createElement('div');
            this._header.className = 'header';
            this._element.appendChild(this._header);

            if( title )
                this._header.innerHTML = `<div class="title">${esc_html(title)}</div>`;

            if( has_close_button ){
                const close_button = document.createElement('div');
                close_button.className = 'close';
                close_button.innerHTML = '&#10005;';
                close_button.addEventListener('click', this.close.bind(this), { once: true });
                this._header.appendChild(close_button);
            }
        }

        if( has_close_button ){
            document.addEventListener('keydown', (event: KeyboardEvent) => {
                if( event.key === 'Escape' )
                    this.close();
            });
        }

        this._body = document.createElement('div');
        this._body.className = 'body';
        this._element.appendChild(this._body);

        this._curtain = document.createElement('div');
        this._curtain.className = 'popup-curtain';

        if( has_close_button )
            addEventListener('popstate', this.close.bind(this), { once: true });
    }

    close()
    {
        // Returns to last location which calls the popstate event. Avoid double closing after second popstate event.
        if( !this._is_open )
            return;
        this._is_open = false;
        
        if( location.hash === '#' + this.name )
            history.replaceState(null, '', '#');
        
        this._element.remove();
        this._curtain.remove();
    }

    async open()
    {
        if( this._can_close )
            this._curtain.addEventListener('click', this.close.bind(this), { once: true });

        history.replaceState(null, '', '#' + this.name);

        document.body.append(this._curtain);
        document.body.append(this._element);

        this._is_open = true;
    }

    protected _body!: HTMLElement;
    protected _can_close = true;
    protected _element!: HTMLElement;
    protected _is_open = false;
    protected _header!: HTMLElement;

    private _curtain!: HTMLElement;
}