import { Browser } from '@capacitor/browser';

import globals from '../../.env';
import { API } from "../../api";
import { Auth } from "../../auth";
import * as core from '../../core';
import { _t } from '../../localization';
import { Scene, Scene_Manager } from "../../scenes";
import { print_global_message, print_global_messages } from '../../ui/global_message';

export class Auth_Scene extends Scene
{
    constructor()
    {
        super('auth');
        this.user_state = 'logged_out';
        this.menu_type = 'language';
        this._init_element();

        window.onmessage = event => console.log('event received in app', event);
    }

    async open(args?: {[key: string]: string})
    {
        await super.open(args);

        // Just in case it's the first load after a login attempt. iOS does otherwise not work after returning from the error page.
        // if( core.is_mobile() )
        //    Browser.close();

        if( args ){
            if( args.error )
                print_global_message('error', _t('auth/error/oauth'));
            else if( args.token )
                this._process_OAuth_token(args.token);
        }
    }

    private _generate_oauth_button(name: string, url: string, image: string)
    {
        const button = document.createElement('a');
        button.className = 'button text-center';
        button.style.borderRadius = '10px';
        button.style.display = 'block';
        button.style.margin = '10px auto';
        button.style.padding = '0';
        button.style.position = 'relative';
        button.style.textDecoration = 'none';
        button.target = '_blank';
        button.addEventListener('click', event => {
            if( name === 'apple' )
                alert(_t('auth/apple_email_warning'));
            this._on_login_click(url);
        });

        const img = document.createElement('img');
        img.alt = name;
        img.src = image;
        img.style.bottom = '10px';
        img.style.left = '10px';
        img.style.margin = 'auto';
        img.style.position = 'absolute';
        img.style.top = '10px'
        img.style.width = '50px';
        button.appendChild(img);

        const text = document.createElement('span');
        text.style.fontSize = '20px';
        text.style.lineHeight = '70px';
        text.textContent = _t('auth/login_with_' + name);
        button.appendChild(text);

        return button;
    }

    private _init_element()
    {
        this.element.className = 'page-section';

        if( !core.is_mobile_app() ){
            const download_header = document.createElement('h1');
            download_header.className = 'text-center';
            download_header.textContent = _t('auth/download');
            this.element.appendChild(download_header);

            if( !core.is_ios_web() ){
                const android_button = document.createElement('img');
                android_button.alt = 'Get it from Google Play';
                android_button.src = '/assets/images/google-play.png';
                android_button.style.cursor = 'pointer';
                android_button.style.display = 'block';
                android_button.style.margin = '10px auto';
                android_button.style.width = '150px';
                android_button.addEventListener('click', () => {
                    open(globals.ANDROID_STORE_URL, '_blank');
                });
                this.element.appendChild(android_button);
            }

            if( !core.is_android_web() ){
                const ios_button = document.createElement('img');
                ios_button.alt = 'Download on the app store';
                ios_button.src = '/assets/images/app-store.svg';
                ios_button.style.cursor = 'pointer';
                ios_button.style.display = 'block';
                ios_button.style.margin = '10px auto';
                ios_button.style.width = '150px';
                ios_button.addEventListener('click', () => {
                    open(globals.IOS_STORE_URL, '_blank');
                });
                this.element.appendChild(ios_button);
            }
            
            const separator = document.createElement('div');
            separator.className = 'text-center';
            separator.style.position = 'relative';
            separator.style.margin = '20px auto';
            separator.innerHTML = '<hr/>' + _t('general/or') + '<hr/>';
            this.element.appendChild(separator);
        }

        const login_header = document.createElement('h1');
        login_header.className = 'text-center';
        login_header.textContent = _t('auth/login');
        this.element.appendChild(login_header);

        const google_button = this._generate_oauth_button('google', `${API.url}/auth/google`, '/assets/images/google.png');
        this.element.appendChild(google_button);

        const facebook_button = this._generate_oauth_button('facebook', `${API.url}/auth/facebook`, '/assets/images/facebook.png');
        this.element.appendChild(facebook_button);

        const apple_button = this._generate_oauth_button('apple', `${API.url}/auth/apple`, '/assets/images/apple.png');
        this.element.appendChild(apple_button);

        const terms_el = document.createElement('div');
        terms_el.className = 'text-center';
        terms_el.style.position = 'relative';
        terms_el.style.margin = '20px auto';
        terms_el.innerHTML = `<hr/><p>${_t('general/agreement')}</p>`;
        this.element.appendChild(terms_el);

        const terms_link = terms_el.querySelector('a')!;
        terms_link.addEventListener('click', event => {
            event.preventDefault();
            const url = (event.target as HTMLAnchorElement).href;
            if( core.is_mobile() )
                Browser.open({presentationStyle: 'popover', url});
            else
                window.open(url);
        });
    }

    private async _on_login_click(url: string)
    {
        const host = core.get_app_url();
        let redirect_url = host + location.pathname + location.search;

        const pkce = core.generate_pkce();
        core.Storage.set('pkce_verifier', pkce.verifier);

        url +=
            `?redirect=${encodeURIComponent(redirect_url)}&` +
            `pkce_challenge=${encodeURIComponent(pkce.challenge)}&` + 
            `pkce_challenge_method=${encodeURIComponent(pkce.challenge_method)}`;

        if( core.is_mobile() )
            open(url, '_blank'); // Browser.open({presentationStyle: 'popover', url});
        else
            location.href = url;
    }

    private async _process_OAuth_token(token: string)
    {
        const messages = {};

        const pkce_verifier = await core.Storage.get('pkce_verifier');
        if( !pkce_verifier ){
            alert(_t('auth/error/oauth'));
            return;
        }

        if( !await Auth.login_with_oauth_token(token, pkce_verifier, messages) ){
            print_global_messages(messages);
            return;
        }

        Scene_Manager.login_redirect();
    }
}
