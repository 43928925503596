// import ExifReader from 'exifreader';

import { is_mobile } from "../core";
import { _t } from "../localization";
import { print_global_message } from "./global_message";
import { Camera, CameraDirection, CameraResultType } from '@capacitor/camera';

// type Rotation_Angles = 0 | 90 | 180 | 270;

declare global {
    export interface Blob
    {
        name?: string
    }
}

export class Image_Uploader
{
    element: HTMLDivElement;
    image?: Blob;

    constructor()
    {
        this.element = document.createElement('div');
        this.element.className = 'image-picker';
        this.element.style.border = '1px solid var(--light-detail-color)';
        this.element.style.borderRadius = '10px';
        this.element.style.overflow = 'hidden';

        this._upload_panel = document.createElement('div');
        this._upload_panel.className = 'uploader';
        this._upload_panel.style.padding = '20px';
        this.element.append(this._upload_panel);

        this._upload_input = document.createElement('button');
        this._upload_input.style.display = 'block';
        this._upload_input.style.margin = 'auto';
        this._upload_input.innerHTML = _t('Upload');
        this._upload_input.addEventListener('click', this._on_upload_click_mobile.bind(this));
        this._upload_panel.append(this._upload_input);

        this._editor_panel = document.createElement('div');
        this._editor_panel.className = 'editor text-center';

        this._canvas_element = document.createElement('canvas');
        this._canvas_element.style.display = 'block';
        this._editor_panel.append(this._canvas_element);

        const back_element = document.createElement('a');
        back_element.innerHTML = _t('media/select_other_image');
        back_element.style.display = 'block';
        back_element.style.margin = '10px';
        back_element.addEventListener('click', this._on_back_click.bind(this));
        this._editor_panel.append(back_element);
    }

    switch_to(panel: string)
    {
        if( panel === 'uploader' ){
            this.element.innerHTML = '';
            this.element.append(this._upload_panel);
        }else if( panel === 'editor' ){
            this.element.innerHTML = '';
            this.element.append(this._editor_panel);
        }
    }

    private _canvas_element: HTMLCanvasElement; // Canvas automatically removes EXIF orientation.
    private _editor_panel: HTMLDivElement;
    private _file_name = '';
    private _image_el?: HTMLImageElement;
    private _upload_input: HTMLButtonElement;
    private _upload_panel: HTMLDivElement;

    private _on_back_click(_: Event)
    {
        this.image = undefined;
        this._upload_input.value = '';
        this.switch_to('uploader');
    }

    private _on_upload_click_desktop(event: Event)
    {
        if( !event.target || !(event.target instanceof HTMLInputElement) )
            return;

        const input = event.target;
        if( !input.files || !input.files.length )
            return;

        const file = input.files[0];
        if( file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/webp' ){
            print_global_message('error', _t('media/invalid_image_type'));
            return;
        }

        this._file_name = file.name;
        
        const original_reader = new FileReader;
        original_reader.onload = event => {
            if( !event.target || !event.target.result ){
                print_global_message('error', _t('media/load_error'));
                return;
            }

            this.image = file;

            const image_src = event.target.result as string;

            this._image_el = new Image;
            this._image_el.onload = async () => {
                try{
                    /* const tags = await ExifReader.load(file);

                    if( !this.image )
                        throw 'no image';

                    const orientation = tags['Orientation']?.value;
                    let rotation: Rotation_Angles = 0;
                    let switch_dimensions = false;
                    switch(orientation){
                        case 3:
                            rotation = 180;
                            break;
                        case 6:
                            rotation = 90;
                            switch_dimensions = true;
                            break;
                        case 8:
                            rotation = 270;
                            switch_dimensions = true;
                            break;
                    } */

                    //if( rotation ){
                    //    this._print_rotated(rotation);
                    //}else{
                       this._print();
                    //}
                }catch(_){
                    alert('An image occurred while displaying the image.');
                    return;
                }
            }

            this._image_el.src = image_src;

            this.switch_to('editor');
        }
        original_reader.readAsDataURL(file);
    }

    private async _on_upload_click_mobile()
    {
        const photo = await Camera.getPhoto({
            // allowEditing: true, // Not very user friendly on Android (iOS not tested). Has a delay and only shows a cancel button.
            direction: CameraDirection.Front,
            promptLabelCancel: _t('general/cancel'),
            promptLabelHeader: _t('media/select_image'),
            promptLabelPhoto: _t('media/from_library'),
            promptLabelPicture: _t('media/take_picture'),
            resultType: CameraResultType.DataUrl,
            webUseInput: true,
        });

        if( !photo || !photo.dataUrl ){
            print_global_message('error', _t('general/error/unexpected'));
            return;
        }
        
        const data = await fetch(photo.dataUrl);
        const blob = await data.blob();
        if( blob.type !== 'image/jpeg' && blob.type !== 'image/png' && blob.type !== 'image/webp' ){
            print_global_message('error', _t('media/invalid_image_type'));
            return;
        }

        this.image = blob;

        this._image_el = new Image;
        this._image_el.onload = async () => {
            try{
                this._print();
            }catch(_){
                alert('An image occurred while displaying the image.');
                return;
            }
        }
        
        this._image_el.src = photo.dataUrl;
        this.switch_to('editor'); // Switch here to make sure it's loaded when printing.
    }

    private _print()
    {
        if( !this._image_el )
            return;

        const context = this._canvas_element.getContext("2d");
        if( !context )
            throw 'invalid context';

        let image_width = this._image_el.naturalWidth;
        let image_height = this._image_el.naturalHeight;
        const canvas_width = this._canvas_element.parentElement!.clientWidth;
        const canvas_height = canvas_width / image_width * image_height;

        this._canvas_element.width = image_width;
        this._canvas_element.height = image_height;
        this._canvas_element.style.width = canvas_width + 'px';
        this._canvas_element.style.height = canvas_height + 'px';

        context.translate(image_width / 2, image_height / 2);
        context.drawImage(this._image_el, -image_width / 2, -image_height / 2, image_width, image_height);

        // The canvas automatically removes EXIF rotation. So use the edited file, even if nothing changed.
        this._canvas_element.toBlob(blob => {
            if( blob ){
                blob.name = this._file_name;
                this.image = blob;
            }else{
                alert('An image occurred while displaying the image.');
            }
        }, 'image/jpeg');
    }

    private _print_rotated(angle: 0 | 90 | 180 | 270)
    {
        if( !this._image_el )
            return;

        const context = this._canvas_element.getContext("2d");
        if( !context )
            throw 'invalid context';

        const switch_dimensions = angle === 90 || angle === 270;

        let canvas_width = this._canvas_element.parentElement!.clientWidth;
        let canvas_height = canvas_width / this._image_el.width * this._image_el.height;
        if( switch_dimensions )
            canvas_height = canvas_width / this._image_el.height * this._image_el.width;
        this._canvas_element.width = canvas_width;
        this._canvas_element.height = canvas_height;

        context.translate(canvas_width / 2, canvas_height / 2);

        const image_width = switch_dimensions ? canvas_height : canvas_width;
        const image_height = switch_dimensions ? canvas_width : canvas_height;
        context.rotate(angle * Math.PI / 180);
        context.drawImage(this._image_el, -image_width / 2, -image_height / 2, image_width, image_height);

        this._canvas_element.toBlob(edited_file => {
            if( edited_file )
                this.image = edited_file;
            else
                alert('An image occurred while displaying the image.');
        }, 'image/jpeg');
    }
}
