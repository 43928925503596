export const data: {[key: string]: string} = {

    "activities/all": "alles",
    "activities/at_date": "Op:",
    "activities/cancel_confirm": `Wil je deze activiteit permanent annuleren voor jou en alle gasten? Laat iedereen weten waarom je annuleert. Gebrek aan communicatie kan leiden tot gasten die je account rapporteren.`,
    "activities/canceled": 'Geannuleerd',
    "activities/chat_not_opened": "Kan de chat niet automatisch openen. Bekijk je chats overzicht.",
    "activities/creation_bottom_info": `
        <ul>
            <li>Activiteiten kunnen niet aangepast worden na het lanceren.</li>
            <li>Vermijd het annuleren wanneer deelnemers zich al hebben opgegeven. Indien het niet anders kan, laat het ze weten via de chat.</li>
        </ul>`,
    "activities/date": "Datum:",
    "activities/deadline": "Deadline:",
    "activities/decline": "Afwijzen",
    "activities/decline_confirm": "Weet je zeker dat je deze activiteit permanent wilt afwijzen?",
    "activities/description_explanation": `
        <p>Waar moet je rekening mee houden voordat je je opgeeft voor je activiteit? Wees specifiek. Sommige voorbeelden zijn ...</p>
        <ul>
            <li>... benodigdheden en outfit.</li>
            <li>... kosten voor een feest.</li>
        </ul>`,
    "activities/expired": "Verlopen",
    "activities/form/address": "Adres",
    "activities/form/city": "Stad",
    "activities/form/country": "Land",
    "activities/form/date_and_time": "Datum van Activiteit",
    "activities/form/deadline": "Uiterste Inschrijfdatum",
    "activities/form/description": "Omschrijving",
    "activities/form/image": "Afbeelding (gebruik maximaal 3 kernwoorden)",
    "activities/form/max_guests" : "Maximum aantal deelnemers",
    "activities/form/max_invitees" : "Maximum aantal vrienden die een deelnemer mag meenemen",
    "activities/form/men_only" : "Alleen mannen",
    "activities/form/min_guests" : "Minimum aantal deelnemers",
    "activities/form/name": "Naam",
    "activities/form/women_only" : "Alleen vrouwen",
    "activities/free": "Gratis",
    "activities/free_question": "Gratis voor deelnemers?",
    "activities/friends_more": "%s meer",
    "activities/guests": "Deelnemers:",
    "activities/guest_count": "%s tot %s.",
    "activities/history": "geschiedenis",
    "activities/host": "Organisator:",
    "activities/invited": "Uitgenodigd",
    "activities/join": "Deelnemen",
    "activities/join_confirm": "Deelnemen aan deze activiteit?",
    "activities/label/multiple_friends": "+%s Vrienden",
    "activities/label/one_friend": "+1 Vriend",
    "activities/launch": "Lanceren",
    "activities/leave": "Afmelden",
    "activities/leave_confirm": "Weet je zeker dat je je wilt afmelden? Laat de andere deelnemers weten dat je niet meer komt.",
    "activities/left": "Afgemeld",
    "activities/location": "Locatie:",
    "activities/location_warning": "<strong>BELANGRIJK:</strong> Als je je eigen adres gebruikt, dan kun je een ophaalpunt in de buurt gebruiken om te voorkomen dat je jouw adres openbaar maakt.",
    "activities/men_only": "mannen",
    "activities/mine": "mijn",
    "activities/new_activity": "Nieuwe Activiteit",
    "activities/no_discovery": "Geen nieuwe activiteiten gevonden.",
    "activities/no_upcomming": "Geen aankomende activiteiten.",
    "activities/no_upcomming/create": "Organiseer een activiteit",
    "activities/no_upcomming/discover": "Ontdek activiteiten om aan deel te nemen",
    "activities/note/invite": "Nodig een vriend uit",
    "activities/number_of_guests": "Aantal deelnemers:",
    "activities/people_you_know": "Mensen die je kent",
    "activities/saved": "Opgeslagen",
    "activities/spots/multiple_spots_left": "Nog %s plekken beschikbaar",
    "activities/spots/no_spot_left": "Volgeboekt",
    "activities/spots/one_spot_left": "Nog 1 plek beschikbaar",
    "activities/suspended_explanation": "Deze activiteit heeft de voorwaarden overtreden en is afgelast.",
    "activities/title": "Activiteiten",
    "activities/upcomming": "aankomend",
    "activities/women_only": "Vrouwen",
    "activities/x_more_guests": "Nog %s deelnemer(s) nodig",

    "auth/create": "Aanmaken",
    "auth/create_new_account": "Maak nieuw account aan",
    "auth/download": "Download de App",
    "auth/login": "Inloggen",
    "auth/login_with_facebook": "Login met Facebook",
    "auth/login_with_google": "Login met Google",
    "auth/log_out": "Uitloggen",
    "auth/or": "of",
    "auth/password": "Wachtwoord:",
    "auth/remember_me": "Onthoud mij",
    "auth/use_existing_account": "Inloggen met bestaand account",

    "chats/active_members": "Actieve Leden",
    "chats/activity": "Activiteit",
    "chats/activity_deadline_passed": "Omdat de deadline voor deze activiteit is verstreken, worden nieuwe leden alleen toegevoegd aan de chat, maar niet uitgenodigd voor de activiteit.",
    "chats/add_member_to_activity": `Als je een vriend ook wilt uitnodigen voor de activiteit en niet alleen de chat, dan kan dit via de activiteitenpagina.`,
    "chats/add_member_to_activity/go_to_activity": "Ga naar de activiteit",
    "chats/add_member_to_activity/just_chat": 'Alleen aan de chat toevoegen',
    "chats/admin_assign_confirm": "Weet je zeker dat je dit lid tot beheerder wilt maken? Je kunt deze chat dan niet meer beheren.",
    "chats/admin_leave_error": "Stel iemand aan als beheerder voordat je de chat verlaat.",
    "chats/chat_image": "Chat Afbeelding",
    "chats/chat_not_found": "Chat niet gevonden.",
    "chats/decline_confirm": "Weet je zeker dat je dit verzoek wilt afwijzen?",
    "chats/empty_name_error": "Vul een naam in.",
    "chats/former_members": "Voormalige Leden",
    "chats/group_name": "Groepsnaam:",
    "chats/leave": "Verlaat chat",
    "chats/leave_confirm": "Weet je zeker dat je deze chat wilt verlaten?",
    "chats/make_admin": "Maak tot admin",
    "chats/message_removed_status": "(verwijderd)",
    "chats/message_suspended_status": "(geschorst)",
    "chats/messages": "Berichten",
    "chats/new_chat": "Nieuwe Chat",
    "chats/new_messages": "Nieuwe Berichten",
    "chats/no_former_members": "Geen voormalige leden.",
    "chats/no_image_selected": "Selecteer eerst een afbeelding.",
    "chats/no_name": "(naamloos)",
    "chats/no_pending_members": "Niemand in afwachting.",
    "chats/not_a_group_error": "Dit is geen groepschat.",
    "chats/not_a_member_error": "Je bent geen lid. Alleen vrienden kunnen je toevoegen aan de chat.",
    "chats/pending_members": "Verzoeken",
    "chats/remove_member_confirm": "Weet je zeker dat je dit lid wilt verwijderen?",
    "chats/remove_message_confirm": "Weet je zeker dat je dit bericht wilt verwijderen?",
    "chats/reply": "Reageren",
    "chats/user_not_found": "Gebruiker niet gevonden.",
    "chats/write_placeholder": "Schrijf een bericht...",

    "general/add": "Toevoegen",
    "general/back": "Terug",
    "general/cancel": "Annuleren",
    "general/confirm": "Bevestigen",
    "general/early_bird": "Early Bird",
    "general/early_bird/explanation": `
        <p style="font-weight: bold">Hi Early Bird!</p>
        <p>
            Er zijn nu geen activiteiten bij jou in de buurt, waarom organiseer je er zelf niet een?
            Je kunt ook je zoekvoorkeuren aanpassen om meer activiteiten te ontdekken.
        </p>`,
    "general/image_loading_error": "Er heeft zich een probleem voorgedaan bij het laden van de afbeelding.",
    "general/info": "Info",
    "general/invite": "Uitnodigen",
    "general/loading": "Laden...",
    "general/loading_error": "We konden je gegevens niet laden. Probeer het opnieuw.",
    "general/more_info": "meer info",
    "general/next": "Volgende",
    "general/no_connection": "Kan geen verbinding maken. Probeer het opnieuw.",
    "general/no_image_found": "Geen afbeelding gevonden.",
    "general/no_internet": "Geen internetverbinding.",
    "general/no_server": "Kan geen verbinding maken met Wuddy's",
    "general/not_connected": "Geen verbinding. Probeer het opnieuw",
    "general/note": "Opmerking",
    "general/nothing_found": "Niets gevonden.",
    "general/options": "Opties",
    "general/or": "of",
    "general/page_not_found": "Pagina niet gevonden.",
    "general/premium_only": "Deze functie is alleen beschikbaar voor premium gebruikers.",
    "general/previous": "Vorige",
    "general/remove": "Verwijderen",
    "general/save": "Opslaan",
    "general/servers_down": "Onze servers zijn momenteel niet beschikbaar. Probeer het later opnieuw.",
    "general/suspended": "Geschorst",
    "general/try_again": "Probeer opnieuw",
    "general/unknown": "onbekend",
    "general/you": "jij",

    "media/invalid_image_type": "De geselecteerde afbeelding is geen JPEG of PNG.",
    "media/load_error": "Het bestand kon niet worden geladen. Probeer het opnieuw.",
    "media/select_other_image": "Selecteer andere afbeelding",

    "network/no_connection": "Geen netwerkverbinding.",

    "notifications": "Meldingen",

    "push/error": "Push notification error.",
    "push/not_granted": "Pushmeldingen zijn niet ingeschakeld.",

    "tips/activity_info/add_member": "Klik op deze knop om een vriend toe te voegen aan de activiteit. Als je een gast bent, worden ze uitgenodigd zodat de organisator ze kan toevoegen.",
    "tips/activity_info/cancel_or_leave": "Je kunt hier een activiteit annuleren of verlaten. Zorg ervoor dat je dit beleefd afhandelt met de andere deelnemers.",
    "tips/activity_info/chat": "Klik op deze knop om deel te nemen aan de chat van deze activiteit.",
    "tips/discover/accept": "Klik op deze knop wanneer je er zeker van bent dat je wilt deelnemen. Je wordt toegevoegd aan de chat en de andere deelnemers ontvangen een melding.",
    "tips/discover/decline": "Klik op deze knop wanneer je niet wilt deelnemen. Deze activiteit zal niet meer aan je getoond worden.",
    "tips/discover/free/explanation": "Is deze activiteit gratis?",
    "tips/discover/friends": "Hoeveel vrienden mag je meenemen?",
    "tips/discover/friends/explanation": "Hoeveel vrienden mag je meenemen?",
    "tips/discover/gender": "Doelgroep",
    "tips/discover/gender/explanation": "Voor wie is deze activiteit bedoeld?",
    "tips/discover/known_users": "Je kunt zien welke deelnemers je al eerder hebt ontmoet. Vrienden zijn omringd door een oranje rand.",
    "tips/discover/labels": "Activiteiten kunnen labels hebben, zoals:",

    "users/accept_friendship": "Accepteer Vriendschap",
    "users/accept_declined_friendship": "Accepteer Afgewezen Vriendschap",
    "users/add_friend_description": `
        Deel deze link met anderen, zodat ze je een vriendschapsverzoek kunnen sturen.
        Als ze nog geen account hebben, dan wordt hen gevraagd om zich te registreren.
        Je kunt deze verzoeken dan vinden op de 'Ontvangen' verzoeken pagina.`,
    "users/address": "Adres:",
    "users/address_info": "Het onderstaande adres kan gebruikt worden om activiteiten in de buurt te vinden. Je kunt deze later bijwerken om activiteiten in specifieke locaties te ontdekken, bijvoorbeeld wanneer je op vakantie bent.",
    "users/block": "Blokkeren",
    "users/block_confirm": "Weet je zeker dat je deze gebruiker wilt blokkeren? Privéberichten worden geblokkeerd en jullie kunnen niet meer deelnemen aan activiteiten georganiseerd door de ander.",
    "users/blocked": "Geblokkeerd",
    "users/blocked_users": "Geblokkeerde Gebruikers",
    "users/city": "Woonplaats:",
    "users/copy_link": "Kopieer Link",
    "users/could_not_load_user": "Kan deze gebruiker niet laden.",
    "users/country": "Land:",
    "users/date_of_birth": "Geboortedatum:",
    "users/decline_friendship": "Wijs Vriendschap Af",
    "users/edit_preferences": "Bewerk Voorkeuren",
    "users/email": "E-mail:",
    "users/first_name": "Voornaam:",
    "users/female": "Vrouw",
    "users/friend_list_updated": "Je vriendenlijst is bijgewerkt.",
    "users/friend_request_sent": "Vriendschapsverzoek verzonden",
    "users/friends": "Vrienden",
    "users/gender": "Geslacht:",
    "users/gender_preference": "Geslachtsvoorkeur:",
    "users/gender_preference/all": "alles",
    "users/gender_preference/same": "zelfde",
    "users/invite_existing": "Nodig een bestaande vriend uit",
    "users/lang": "Taal:",
    "users/last_name": "Achternaam:",
    "users/link_copied": "Link is gekopieerd.",
    "users/male": "Man",
    "users/max_age_diff": "Maximaal leeftijdsverschil met organisator:",
    "users/meet_new": "Ontmoet nieuwe mensen",
    "users/no_image_selected": "Selecteer eerst een afbeelding.",
    "users/phone": "Telefoon:",
    "users/personal_info": "Persoonlijke Info",
    "users/preferences": "Voorkeuren",
    "users/profile": "Profiel",
    "users/profile_picture": "Profielfoto",
    "users/profile_picture/description": `
        Zorg ervoor dat je gezicht goed zichtbaar is.
        Deze afbeelding is voor iedereen zichtbaar en wordt voornamelijk gebruikt zodat deelnemers aan activiteiten elkaar gemakkelijk kunnen herkennen.`,
    "users/received_requests": "Ontvangen",
    "users/search_radius": "Zoekafstand (km):",
    "users/send_friend_request": "Verzend Vriendschapsverzoek",
    "users/sent_requests": "Verzonden",
    "users/share": "Delen",
    "users/share_friend_link": "Deel Vriendenlink",
    "users/share_message/dialog_title": "Deel Wuddy's vriendenlink.",
    "users/share_message/text": "Je kunt deze link gebruiken om mij een Wuddy's vriendschapsverzoek te sturen.",
    "users/share_message/title": "%s heeft je een Wuddy's vriendschapsverzoek gestuurd.",
    "users/unblock": "Deblokkeren",
    "users/unfriend": "Ontvrienden",
    "users/unfriend_confirm": "Weet je zeker dat je deze persoon wilt ontvrienden?",
    "users/verification_error": "We kunnen je account niet verifiëren. Neem contact op.",
};
