import { Activities } from "../../activities";
import { _t } from "../../localization";
import { Image } from "../../media";
import { Scene, Scene_Manager } from "../../scenes";
import { esc_attr, esc_html } from "../../ui/DOM";
import { print_global_message, print_global_messages } from "../../ui/global_message";
import { Image_Uploader_Popup } from "../../ui/popups/image_uploader_popup";
import Updater from "../../updater";

export class Activities_Images_Scene extends Scene
{
    constructor()
    {
        super('activities/images');

        this.user_state = 'active';

        this._init_element();
        this._init_nothing_found_element();
    }

    async open()
    {
        await super.open();
        this._page = 1;
        this._list_element.innerHTML = '';
        this._more_button.style.display = 'block';
        await this._load_page();
    }

    private _page = 1;
    private _list_element!: HTMLDivElement;
    private _more_button!: HTMLButtonElement;
    private _nothing_found_element!: HTMLDivElement;

    private _init_element()
    {
        this.element.style.padding = '20px';
        this.element.innerHTML = `
            <button class="add-button button round primary" style="float: right; margin-top: 15px;">+</button>

            <h1>${_t('activities/images')}</h1>
            <div class="item-list"></div>
            <button class="more">${_t('general/more')}</button>`;

        const create_button = this.element.querySelector('.add-button')!;
        create_button.addEventListener('click', () => {
            new Image_Uploader_Popup(this._on_add_image.bind(this)).open();
        });

        this._list_element = this.element.querySelector('.item-list')!;

        this._more_button = this.element.querySelector('.more')!;
        this._more_button.style.marginTop = '20px';
        this._more_button.addEventListener('click', () => this._load_page());
    }

    private _init_nothing_found_element()
    {
        this._nothing_found_element = document.createElement('div');
        this._nothing_found_element.className = 'center-abs';
        this._nothing_found_element.style.padding = '20px';
        this._nothing_found_element.innerHTML = `<p>${_t('general/nothing_found')}</p>`;
    }

    private async _load_page(): Promise<Record<string, string>[] | undefined>
    {
        const images = await Activities.get_images({page: this._page});
        if( !images ){
            this._list_element.innerHTML = '';
            print_global_message('error', _t('general/loading_error'));
            return;
        }

        this._page++;

        if( images.length > 0 ){
            for(const image of images)
                this._print_image(image);
        }else{
            this._more_button.style.display = 'none';
        }

        if( this._list_element.childNodes.length === 0 )
            this._list_element.append(this._nothing_found_element);

        return images;
    }

    private async _on_add_image(image: Blob)
    {
        const messages = {};
        const uid = await Activities.upload_image(image, messages);
        print_global_messages(messages);
        if( !uid )
            return;

        await Scene_Manager.open('/activities/image', {uid});
        
        await Updater.run();
    }

    private _print_image(image: Image)
    {
        const item_element = document.createElement('div');
        item_element.className = 'main-list-item user-list-item button';
        item_element.dataset.id = image.uid;
        item_element.innerHTML = `
            <div class="image-container">
                <img src="${esc_attr(Activities.get_image_url(image.url, 'main_list_item'))}"/>
            </div>
            <div class="info-container">
                <div class="name">${esc_html(image.name)} (${esc_html(image.uid)})</div>
            </div>`;

        item_element.addEventListener('click', async () => {
            await Scene_Manager.open('activities/image', {'uid': image.uid});
            await Updater.run();
        });

        this._list_element.append(item_element);
    }
}
