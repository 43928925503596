import { clip, UTC } from "../../core";
import { _t, locales } from "../../localization";
import { Image_Context, Image_Crop, Image_Profile, Media, Media_Meta } from "../../media";
import { Scene } from "../../scenes";
import { _ } from "../../ui/DOM";
import { print_global_messages } from "../../ui/global_message";

const MIN_CROP_PIXEL_SIZE = 30;

export class Activities_Image_Scene extends Scene
{
    constructor()
    {
        super('activities/image');

        this.user_state = 'active';

        this._init_element();
    }

    async open(args?: {[key: string]: string})
    {
        await super.open();

        if( !args || !args['uid'] ){
            alert(_t('general/error/not_found'));
            return;
        }

        await this._prepare_first_open();

        this._image_uid = args['uid'];

        const log = {}

        const meta = await Media.get_image_meta(this._image_uid, log);
        if( !meta ){
            print_global_messages(log);
            return;
        }

        if( !meta.size ){
            alert('Image could not be loaded');
            return;
        }

        const image_el = this.element.querySelector('.image-editor img') as HTMLImageElement;
        image_el.onload = this._update_crop.bind(this);
        image_el.src = meta.url;
        this._image_size = meta.size;
        
        this._open_name(meta);
        this._open_keywords(meta);
        this._open_crop(meta);
    }

    private _context?: Image_Context;
    private _crop_area?: HTMLDivElement;
    private _crops: Record<string, Image_Crop> = {};
    private _custom_profiles: Record<string, Image_Profile> = {}; // Since multiple profiles can have the same ratio we map them to the same profile.
    private _dragging_area_point?: {x: number, y: number};
    private _dragging_handle = '';
    private _dragging_handle_bl = {x: 0, y: 0}; // Accumulating drags can cause issues. Make an anchor point to remeber where the bottom right started.
    private _image_el?: HTMLImageElement;
    private _image_uid?: string;
    private _image_size?: {w: number, h: number}

    private _generate_ratio_name(ratio: {w: number, h: number})
    {
        return ratio.w + ' x ' + ratio.h;
    }

    private _init_element()
    {
        const langs = Object.keys(locales);

        this.element.style.bottom = '0';
        this.element.style.display = 'flex';
        this.element.style.left = '0';
        this.element.style.position = 'absolute';
        this.element.style.right = '0';
        this.element.style.top = '0';

        this.element.append(
            _('div', {style: 'flex-grow: 1; overflow: auto'}, [
                _('div', {class: 'image-editor', style: 'margin: 20px'}, [
                    this._image_el =
                    _('img', {class: "crop-image no-select", draggable: 'false', style: 'max-height: 800px; max-width: 90%'}),
                    this._crop_area =
                    _('div', {class: 'crop-area', onmousedown: (event: MouseEvent) => this._on_crop_area_drag_start(event)}, [
                        _('div', {class: 'crop-handle handle-tl', onmousedown: (event: MouseEvent) => this._on_crop_handle_drag_start(event, 'tl')}),
                        _('div', {class: 'crop-handle handle-tr', onmousedown: (event: MouseEvent) => this._on_crop_handle_drag_start(event, 'tr')}),
                        _('div', {class: 'crop-handle handle-bl', onmousedown: (event: MouseEvent) => this._on_crop_handle_drag_start(event, 'bl')}),
                        _('div', {class: 'crop-handle handle-br', onmousedown: (event: MouseEvent) => this._on_crop_handle_drag_start(event, 'br')}),
                    ]),
                ]),
            ]),
            _('div', {class: 'sidebar',style: 'background-color: var(--detail-color); flex-shrink: 1; overflow: auto; padding: 20px 10px; width: 300px'}, [
                _('button', {class: 'primary full-width', onclick: this._on_save_click.bind(this)}, _t('general/save')),

                _('hr', {style: 'background-color: var(--light-detail-color)'}),
                _('h3', _t('general/info')),
                _('hr', {style: 'background-color: var(--light-detail-color)'}),
                _('label', {style: 'display: block, margin: 0'}, [
                    _('strong', {style: 'margin: 20px 0 0'}, _t('general/name')),
                    _('input', {class: "full-width", name: 'name'})
                ]),

                _('hr', {style: 'background-color: var(--light-detail-color)'}),
                _('h3', _t('general/keywords')),
                _('hr', {style: 'background-color: var(--light-detail-color)'}),
                ...langs.map(lang => 
                    _('label', {style: 'display: block, margin: 0'}, [
                        _('strong', {style: 'margin: 20px 0 0'}, lang),
                        _('textarea', {'data-lang': lang, style: "width: 100%"}),
                    ])
                ),

                _('hr', {style: 'background-color: var(--light-detail-color)'}),
                _('h3', _t('media/crop')),
                _('hr', {style: 'background-color: var(--light-detail-color)'}),
                _('div', {class: 'crop-list'}),
            ]),
        );

        window.addEventListener('resize', this._update_crop.bind(this));
    }

    private _on_crop_area_drag_move(event: MouseEvent)
    {
        if( !this._dragging_area_point || !this._image_size )
            return;

        let profile_input = this.element.querySelector('.crop-list input:checked') as HTMLInputElement;
        let crop = this._crops[profile_input.value];

        const image_bb = this._image_el!.getBoundingClientRect();
        let mouse_x = this._to_image_coords(event.x - image_bb.x);
        let mouse_y = this._to_image_coords(event.y - image_bb.y);

        crop.x = clip(mouse_x - this._dragging_area_point!.x, 0, this._image_size.w - crop.w);
        crop.y = clip(mouse_y - this._dragging_area_point!.y, 0, this._image_size.h - crop.h!);

        this._update_crop();

        window.addEventListener('mousemove', this._on_crop_area_drag_move.bind(this), {once: true})
    }

    private _on_crop_area_drag_start(event: MouseEvent)
    {
        event.preventDefault();

        let profile_input = this.element.querySelector('.crop-list input:checked') as HTMLInputElement | undefined;
        if( !profile_input )
            throw `No profile selected.`;

        let crop = this._crops[profile_input.value];
        if( !crop )
            throw `No crop data for custom profile ${profile_input.value}.`;

        const crop_area_bb = this._crop_area!.getBoundingClientRect();
        this._dragging_area_point = {
            x: this._to_image_coords(event.x - crop_area_bb.x),
            y: this._to_image_coords(event.y - crop_area_bb.y),
        }

        window.addEventListener('mousemove', this._on_crop_area_drag_move.bind(this), {once: true})

        window.addEventListener('mouseup', () => {
            this._dragging_area_point = undefined;
        }, {once: true});
    }

    private _on_crop_handle_drag_move(event: MouseEvent)
    {
        if( !this._dragging_handle || !this._image_size )
            return;

        const image_bb = this._image_el!.getBoundingClientRect();
        let mouse_x = this._to_image_coords(event.x - image_bb.x);

        let profile_input = this.element.querySelector('.crop-list input:checked') as HTMLInputElement;
        const profile = this._custom_profiles[profile_input.value];
        let crop = this._crops[profile_input.value];

        if( this._dragging_handle === 'tl' ){
            let new_w = this._dragging_handle_bl.x - mouse_x;
            new_w = clip(new_w, MIN_CROP_PIXEL_SIZE, this._dragging_handle_bl.x);

            let new_h = new_w / profile.ratio.w * profile.ratio.h;
            new_h = clip(new_h, MIN_CROP_PIXEL_SIZE, this._dragging_handle_bl.y);
            new_w = new_h / profile.ratio.h * profile.ratio.w;

            crop.w = Math.round(new_w);
            crop.h = Math.round(new_h);
            crop.x = this._dragging_handle_bl.x - crop.w;
            crop.y = this._dragging_handle_bl.y - crop.h;

        }else if( this._dragging_handle === 'tr' ){
            let new_w = mouse_x - crop.x;
            new_w = clip(new_w, MIN_CROP_PIXEL_SIZE, this._image_size.w - crop.x);

            let new_h = new_w / profile.ratio.w * profile.ratio.h;
            new_h = clip(new_h, MIN_CROP_PIXEL_SIZE, this._dragging_handle_bl.y);
            new_w = new_h / profile.ratio.h * profile.ratio.w;

            crop.w = Math.round(new_w);
            crop.h = Math.round(new_h);
            crop.y = this._dragging_handle_bl.y - crop.h;

        }else if( this._dragging_handle === 'bl' ){
            let new_w = this._dragging_handle_bl.x - mouse_x;
            new_w = clip(new_w, MIN_CROP_PIXEL_SIZE, this._dragging_handle_bl.x);

            let new_h = new_w / profile.ratio.w * profile.ratio.h;
            new_h = clip(new_h, MIN_CROP_PIXEL_SIZE, this._image_size.h - crop.y);
            new_w = new_h / profile.ratio.h * profile.ratio.w;

            crop.w = Math.round(new_w);
            crop.h = Math.round(new_h);
            crop.x = this._dragging_handle_bl.x - crop.w;

        }else if( this._dragging_handle === 'br' ){
            let new_w = mouse_x - crop.x;
            new_w = clip(new_w, MIN_CROP_PIXEL_SIZE, this._image_size.w - crop.x);

            let new_h = new_w / profile.ratio.w * profile.ratio.h;
            new_h = clip(new_h, MIN_CROP_PIXEL_SIZE, this._image_size.h - crop.y);
            new_w = new_h / profile.ratio.h * profile.ratio.w;

            crop.w = Math.round(new_w);
            crop.h = Math.round(new_h);
        }

        this._update_crop();

        window.addEventListener('mousemove', this._on_crop_handle_drag_move.bind(this), {once: true})
    }

    private _on_crop_handle_drag_start(event: MouseEvent, handle: string)
    {
        event.preventDefault();
        event.stopPropagation(); // The crop area has its own action.

        let profile_input = this.element.querySelector('.crop-list input:checked') as HTMLInputElement | undefined;
        if( !profile_input )
            throw `No profile selected.`;

        let crop = this._crops[profile_input.value];
        if( !crop )
            throw `No crop data for custom profile ${profile_input.value}.`;

        this._dragging_handle = handle;
        this._dragging_handle_bl = {
            x: crop.x + crop.w,
            y: crop.y + crop.h!,
        }

        window.addEventListener('mousemove', this._on_crop_handle_drag_move.bind(this), {once: true})

        window.addEventListener('mouseup', () => {
            this._dragging_handle = '';
        }, {once: true});
    }

    private async _on_save_click()
    {
        if( !this._image_uid )
            return;

        const meta: Record<string, any> = {};

        this._save_name(meta);
        this._save_crop(meta);
        this._save_keywords(meta);

        const log = {};

        await Media.update_image_meta(this._image_uid, meta, log);

        print_global_messages(log);
    }

    private _open_crop(meta: Media_Meta)
    {
        this._crops = {};

        if( !meta.crops )
            return;

        Object.values(meta.crops).forEach(crop => {
            const profile = this._context?.profiles[crop.profile];
            if( !profile )
                return;

            const custom_profile_name = this._generate_ratio_name(profile.ratio);
            // Multiple crops can correspond to a profile. Skip duplicates.
            if( this._crops[custom_profile_name] )
                return;

            this._crops[custom_profile_name] = {
                profile: custom_profile_name,
                x: crop. x,
                y: crop.y,
                w: crop.w,
                h: Math.floor(crop.w / profile.ratio.w * profile.ratio.h),
            };
        });

        for(const name in this._custom_profiles){
            if( !this._crops[name] )
                this._set_crop_area_to_default(name);
        }
    }

    private _open_keywords(meta: Media_Meta)
    {
        if( meta.keywords ){
            const keyword_inputs = this.element.querySelectorAll('textarea[data-lang]') as NodeListOf<HTMLTextAreaElement>;
            keyword_inputs.forEach(input => {
                const lang = input.dataset['lang']!;
                if( meta!.keywords![lang] )
                    input.value = meta!.keywords![lang].join(', ');
                else
                    input.value = '';
            });
        }
    }

    private _open_name(meta: Media_Meta)
    {
        if( meta.name ){
            const input = this.element.querySelector('input[name="name"]') as HTMLInputElement;
            input.value = meta.name;
        }
    }

    private async _prepare_first_open()
    {
        if( this._context )
            return;

        this._context = await Media.get_image_context('activity_image');
        if( !this._context ){
            alert("Can't load the contexts.");
            return;
        }

        const crop_list = this.element.querySelector('.crop-list') as HTMLDivElement;
        const ratios = new Set<string>;
        let first = true;

        Object.values(this._context.profiles).forEach(profile => {
            const name = this._generate_ratio_name(profile.ratio);
            if( ratios.has(name) )
                return;

            this._custom_profiles[name] = {
                name,
                size: profile.size,
                ratio: profile.ratio
            };

            crop_list.append(this._print_crop_profile(profile, first));
            ratios.add(name);
            first = false;
        })
    }

    private _print_crop_profile(profile: Image_Profile, checked: boolean): HTMLLabelElement
    {
        const profile_name = this._generate_ratio_name(profile.ratio);
        const radio = _('input', {name: 'crop', type: "radio", value: profile_name});
        radio.checked = checked;
        radio.oninput = this._update_crop.bind(this);

        return  _('label', {class: 'full-width', style: 'display: block, margin: 0'}, [
                    radio,
                    _('strong', {name: 'crop', style: 'margin: 20px 0 0'}, `(${profile_name})`),
                    _('br'),
                    _('span', {class: 'values', 'data-profile': profile_name, style: 'font-size: 0.8em; margin-left: 20px'}, '()'),
                ]);
    }

    private _save_crop(meta: Record<string, any>)
    {
        if( UTC() < UTC('2025-01-01') ){
            alert('This is an old image and its crop won\' be saved.');
            return;
        }

        if( !this._context )
            throw 'No image context.';

        /*meta.crops = [];

        Object.values(this._context.profiles).forEach(profile => {
            const custom_profile_name = this._generate_ratio_name(profile.ratio);
            const crop = this._crops[custom_profile_name];
            if( !crop )
                throw 'Not all custom priles have a crop.';

            meta.crops.push({
                name: profile.name,
                x: crop.x,
                y: crop.y,
                w: crop.w,
            });
        });*/
    }

    private _save_keywords(meta: Record<string, any>)
    {
        meta.keywords = {};

        const keyword_inputs = this.element.querySelectorAll('textarea[data-lang]') as NodeListOf<HTMLTextAreaElement>;
        keyword_inputs.forEach(input => {
            const lang = input.dataset['lang']!;
            const keywords = input.value.split(',')
                .map(keyword => keyword.trim())
                .filter(keyword => keyword);
            meta.keywords![lang] = keywords;
        });
    }

    private _save_name(meta: Record<string, any>)
    {
        const input = this.element.querySelector('input[name="name"]') as HTMLInputElement;
        meta.name = input.value;
    }

    private _set_crop_area_to_default(profile_name: string)
    {
        const profile = this._custom_profiles[profile_name];
        if( !profile )
            throw 'Custom profile not found.';
        
        if( !this._image_size )
            throw 'Image size not set.';

        const crop_values = { x: 0, y: 0, w: 0, h: 0 };
        if( this._image_size.w / this._image_size.h >= profile.ratio.w / profile.ratio.h ){
            crop_values.h = this._image_size.h;
            crop_values.w = Math.round(this._image_size.h / profile.ratio.h * profile.ratio.w);
            crop_values.x = Math.round((this._image_size.w - crop_values.w) / 2);
        }else{
            crop_values.w = this._image_size.w;
            crop_values.h = Math.round(this._image_size.w / profile.ratio.w * profile.ratio.h);
            crop_values.y = Math.round((this._image_size.h - crop_values.h) / 2);
        }

        const crop = {
            profile: profile_name,
            x: crop_values.x,
            y: crop_values.y,
            w: crop_values.w,
            h: crop_values.h,
            ratio: profile.ratio,
        };

        this._crops[profile_name] = crop;
        return crop;
    }

    private _to_image_coords(value: number)
    {
        return Math.round(value / this._image_el!.width * this._image_size!.w);
    }
    
    private _update_crop()
    {
        this._update_crop_sidebar();
        this._update_crop_area();
    }

    private _update_crop_sidebar()
    {
        if( !this._context )
            return;

        const value_els = this.element.querySelectorAll('.crop-list .values') as NodeListOf<HTMLSpanElement>;
        value_els.forEach(value_el => {
            const profile_name = value_el.dataset['profile']!;
            const crop = this._crops[profile_name];
            if( crop )
                value_el.innerHTML = `(x: ${crop.x}, y: ${crop.y}, w: ${crop.w}, h: ${crop.h})`;
            else
                value_el.innerHTML = `(???)`;
        });
    }

    private _update_crop_area()
    {
        let profile_input = this.element.querySelector('.crop-list input:checked') as HTMLInputElement | undefined;
        if( !profile_input )
            throw `No profile selected.`;

        if( !this._image_el || !this._image_el.complete || !this._image_size || !this._crop_area )
            return;

        let crop = this._crops[profile_input.value];
        if( !crop )
            throw `No crop data for custom profile ${profile_input.value}.`;

        if( !crop.h )
            throw "Crop height isn't set.";

        const image_to_screen_ratio = this._image_el.clientWidth / this._image_size.w;

        this._crop_area.style.left = crop.x * image_to_screen_ratio + 'px';
        this._crop_area.style.top = crop.y * image_to_screen_ratio + 'px';
        this._crop_area.style.width = crop.w * image_to_screen_ratio + 'px';
        this._crop_area.style.height = crop.h * image_to_screen_ratio + 'px';
    }
}
