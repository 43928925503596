export const data: {[key: string]: string} = {

    "activities/all": "alles",
    "activities/at_date": "Op:",
    "activities/cancel_confirm": `Wil je deze activiteit permanent annuleren voor jou en alle gasten? Laat iedereen weten waarom je annuleert. Gebrek aan communicatie kan leiden tot gasten die je account rapporteren.`,
    "activities/canceled": 'Geannuleerd',
    "activities/chat_not_opened": "Kan de chat niet automatisch openen. Bekijk je chatoverzicht.",
    "activities/creation_bottom_info": `
        <p><strong>BELANGRIJK:</strong></p>
        <ul>
            <li>Activiteiten kunnen niet aangepast worden na het lanceren.</li>
            <li>Wanneer je annuleert, laat het duidelijke weten aan de gasten via bijvoorbeeld de chat.</li>
            <li>Activiteiten zijn alleen voor persoonlijk gebruik. Gebruik een zakelijk account voor commerciële doeleinden zoals het promoten van een evenement of cursus.</li>
        </ul>`,
    "activities/date": "Datum:",
    "activities/deadline": "Deadline:",
    "activities/decline": "Afwijzen",
    "activities/decline_confirm": "Weet je zeker dat je deze activiteit permanent wilt afwijzen?",
    "activities/description_explanation": `
        <p>
            Geef een uitgebreide omschrijving van de activiteit.
            Waar moet je rekening mee houden voordat je je opgeeft voor deze activiteit? Wees specifiek.
            Hier zijn wat voorbeelden afhankelijk van de activiteit ...
        </p>
        <ul>
            <li>... hoe lang duurt het?</li>
            <li>... welke benodigdheden en wat voor een outfit zijn nodig?</li>
            <li>... wat als het weer slecht is?</li>
            <li>... welke betaalwijzen zijn mogelijk?</li>
        </ul>`,
    "activities/error/no_chat": "Geen bestaande chat geselecteerd.",
    "activities/expired": "Verlopen",
    "activities/form/address": "Adres",
    "activities/form/audience": "Beschikbaarheid",
    "activities/form/audience/private": "Privé (alleen op uitnodiging)",
    "activities/form/audience/public": "Openbaar",
    "activities/form/chat": "Chat",
    "activities/form/chat/explanation": `Je kunt een nieuwe chat aanmaken of een bestaande chat waarvan jij de admin bent selecteren.
        Gebruikers die zich aanmelden voor deze activiteit worden automatisch toegevoegd aan deze chat.`,
    "activities/form/city": "Stad",
    "activities/form/country": "Land",
    "activities/form/date_and_time": "Datum van Activiteit",
    "activities/form/deadline": "Uiterste Inschrijfdatum",
    "activities/form/description": "Omschrijving",
    "activities/form/image": "Afbeelding",
    "activities/form/image_keywords": "Kernwoorden (max. 3 met een spatie ertussen)",
    "activities/form/image_keywords/placeholder": "bijv. sport dans BBQ eten",
    "activities/form/max_age_diff" : "Maximaal leeftijdsverschil van gasten t.o.v. jou",
    "activities/form/max_guests" : "Maximaal aantal deelnemers",
    "activities/form/max_invitees" : "Maximaal aantal vrienden die een deelnemer mag meenemen",
    "activities/form/men_only" : "Alleen mannen",
    "activities/form/min_guests" : "Minimaal aantal deelnemers",
    "activities/form/name": "Naam",
    "activities/form/price": "Prijs",
    "activities/form/price/explanation": `
        <strong>BELANGRIJK:</strong>
        Als er toegangskosten zijn, als je materiaal moet aanschaffen, etc. dan kun je hier de geschatte kosten opgeven.
        Houd er rekening mee dat dit niet is voor het genereren van inkomsten.`,
    "activities/form/women_only" : "Alleen vrouwen",
    "activities/free": "Gratis",
    "activities/friends_more": "%s meer",
    "activities/guest_count": "%s nodig",
    "activities/guest_count/range": "%s tot %s nodig",
    "activities/guest_count/currently": "%s (%s)",
    "activities/guests": "Deelnemers:",
    "activities/history": "geschiedenis",
    "activities/host": "Organisator:",
    "activities/images": "Activiteitsafbeeldingen",
    "activities/invited": "Uitgenodigd",
    "activities/join": "Deelnemen",
    "activities/join_confirm": "Deelnemen aan deze activiteit?",
    "activities/label/multiple_friends": "+%s Vrienden",
    "activities/label/one_friend": "+1 Vriend",
    "activities/launch": "Lanceren",
    "activities/leave": "Afmelden",
    "activities/leave_confirm": "Weet je zeker dat je je wilt afmelden? Laat de andere deelnemers weten dat je niet meer komt.",
    "activities/left": "Afgemeld",
    "activities/location": "Locatie:",
    "activities/location_warning": "<strong>BELANGRIJK:</strong> Als je je eigen adres gebruikt, dan kun je een ophaalpunt in de buurt gebruiken om te voorkomen dat je jouw adres openbaar maakt.",
    "activities/men_only": "Mannen",
    "activities/mine": "mijn",
    "activities/new_activity": "Nieuwe Activiteit",
    "activities/no_discovery": "Geen nieuwe activiteiten gevonden.",
    "activities/no_upcomming": "Geen aankomende activiteiten.",
    "activities/no_upcomming/create": "Organiseer een activiteit",
    "activities/no_upcomming/discover": "Ontdek activiteiten om aan deel te nemen",
    "activities/note/invite": "Nodig een vriend uit",
    "activities/number_of_guests": "Aantal deelnemers:",
    "activities/people_you_know": "Mensen die je kent",
    "activities/price": "Prijs:",
    "activities/saved": "Opgeslagen",
    "activities/send_invites": "Nodig automatisch alle leden uit.",
    "activities/spots/multiple_spots_left": "Nog %s plekken beschikbaar",
    "activities/spots/no_spot_left": "Volgeboekt",
    "activities/spots/one_spot_left": "Nog 1 plek beschikbaar",
    "activities/suspended_explanation": "Deze activiteit heeft de voorwaarden overtreden en is afgelast.",
    "activities/title": "Activiteiten",
    "activities/upcomming": "aankomend",
    "activities/use_existing_chat": "Gebruik bestaande chat",
    "activities/women_only": "Vrouwen",
    "activities/x_more_guests": "Nog %s deelnemer(s) nodig",

    "auth/apple_email_warning": "Met Apple kun je jouw e-mailadres geheimhouden. Houd er wel rekening mee dat je dan geen andere providers kunt gebruiken.",
    "auth/create": "Aanmaken",
    "auth/create_new_account": "Maak nieuw account aan",
    "auth/download": "Download de App",
    "auth/error/oauth": "Inloggen mislukt. Probeer het opnieuw.",
    "auth/login": "Inloggen",
    "auth/login_with_apple": "Login met Apple",
    "auth/login_with_facebook": "Login met Facebook",
    "auth/login_with_google": "Login met Google",
    "auth/log_out": "Uitloggen",
    "auth/or": "of",
    "auth/password": "Wachtwoord:",
    "auth/remember_me": "Onthoud mij",
    "auth/use_existing_account": "Inloggen met bestaand account",

    "chats/active_members": "Actieve Leden",
    "chats/activity": "Activiteit",
    "chats/activity_deadline_passed": "Omdat de deadline voor deze activiteit is verstreken, worden nieuwe leden alleen toegevoegd aan de chat, maar niet uitgenodigd voor de activiteit.",
    "chats/add_member_to_activity": `Als je een vriend ook wilt uitnodigen voor de activiteit en niet alleen de chat, dan kan dit via de activiteitenpagina.`,
    "chats/add_member_to_activity/go_to_activity": "Ga naar de activiteit",
    "chats/add_member_to_activity/just_chat": 'Alleen aan de chat toevoegen',
    "chats/admin_assign_confirm": "Weet je zeker dat je dit lid tot beheerder wilt maken? Je kunt deze chat dan niet meer beheren.",
    "chats/admin_leave_error": "Stel iemand aan als beheerder voordat je de chat verlaat.",
    "chats/chat_image": "Chat Afbeelding",
    "chats/chat_not_found": "Chat niet gevonden.",
    "chats/decline_confirm": "Weet je zeker dat je dit verzoek wilt afwijzen?",
    "chats/empty_name_error": "Vul een naam in.",
    "chats/former_members": "Voormalige Leden",
    "chats/group_name": "Groepsnaam:",
    "chats/group_size": "%s leden",
    "chats/leave": "Verlaat chat",
    "chats/leave_confirm": "Weet je zeker dat je deze chat wilt verlaten?",
    "chats/make_admin": "Maak tot admin",
    "chats/message_removed_status": "(verwijderd)",
    "chats/message_suspended_status": "(geschorst)",
    "chats/messages": "Berichten",
    "chats/new_chat": "Nieuwe Chat",
    "chats/new_messages": "Nieuwe Berichten",
    "chats/no_former_members": "Geen voormalige leden.",
    "chats/no_image_selected": "Selecteer eerst een afbeelding.",
    "chats/no_name": "(naamloos)",
    "chats/no_pending_members": "Niemand in afwachting.",
    "chats/not_a_group_error": "Dit is geen groepschat.",
    "chats/not_a_member_error": "Je bent geen lid. Alleen een vriend of admin kan je toevoegen aan de chat.",
    "chats/pending_members": "Verzoeken",
    "chats/remove_member_confirm": "Weet je zeker dat je dit lid wilt verwijderen?",
    "chats/remove_message_confirm": "Weet je zeker dat je dit bericht wilt verwijderen?",
    "chats/reply": "Reageren",
    "chats/select": "Selecteer Chat",
    "chats/success/pending": "In afwachting van goedkeuring door de admin",
    "chats/user_not_found": "Gebruiker niet gevonden.",
    "chats/write_placeholder": "Schrijf een bericht...",

    "general/add": "Toevoegen",
    "general/agreement": "Wanneer je Wuddy's gebruikt, ga je akkoord met onze <a href=\"https://www.wuddys.com/terms/\" target=\"_blank\">Algemene Voorwaarden</a>.",
    "general/back": "Terug",
    "general/cancel": "Annuleren",
    "general/confirm": "Bevestigen",
    "general/description": "Omschrijving",
    "general/disabled": "uitgeschakeld",
    "general/early_bird": "Early Bird",
    "general/early_bird/explanation": `
        <p style="font-weight: bold">Hi Early Bird!</p>
        <p>
            Er zijn nu geen activiteiten bij jou in de buurt, waarom organiseer je er zelf niet een?
            Je kunt ook je zoekvoorkeuren aanpassen om meer activiteiten te ontdekken.
        </p>`,
    "general/error/not_found": "Niet gevonden.",
    "general/error/unexpected": "Er heeft zich een onverwacht probleem voorgedaan.",
    "general/image_loading_error": "Er heeft zich een probleem voorgedaan bij het laden van de afbeelding.",
    "general/info": "Info",
    "general/invite": "Uitnodigen",
    "general/keywords": "Kernwoorden",
    "general/loading": "Laden...",
    "general/loading_error": "We konden je gegevens niet laden. Probeer het opnieuw.",
    "general/more_info": "meer info",
    "general/name": "naam",
    "general/next": "Volgende",
    "general/no_connection": "Kan geen verbinding maken. Probeer het opnieuw.",
    "general/no_image_found": "Geen afbeelding gevonden.",
    "general/no_internet": "Geen internetverbinding.",
    "general/no_server": "Kan geen verbinding maken met Wuddy's",
    "general/not_connected": "Geen verbinding. Probeer het opnieuw",
    "general/note": "Opmerking",
    "general/nothing_found": "Niets gevonden.",
    "general/options": "Opties",
    "general/or": "of",
    "general/other": "Iets anders",
    "general/page_not_found": "Pagina niet gevonden.",
    "general/premium_only": "Deze functie is alleen beschikbaar voor premium gebruikers.",
    "general/previous": "Vorige",
    "general/private": "Privé",
    "general/remove": "Verwijderen",
    "general/save": "Opslaan",
    "general/servers_down": "Onze servers zijn momenteel niet beschikbaar. Probeer het later opnieuw.",
    "general/settings": "Instellingen",
    "general/title": "Wuddy's - Maak echte vrienden",
    "general/try_again": "Probeer opnieuw",
    "general/unknown": "onbekend",
    "general/you": "jij",

    "media/crop": "Bijsnijden",
    "media/from_library": "Uit bibliotheek",
    "media/invalid_image_type": "De geselecteerde afbeelding is geen JPEG of PNG.",
    "media/load_error": "Het bestand kon niet worden geladen. Probeer het opnieuw.",
    "media/select_image": "Selecteer een afbeelding",
    "media/select_other_image": "Selecteer andere afbeelding",
    "media/take_picture": "Neem een foto",

    "network/no_connection": "Geen netwerkverbinding.",

    "notifications": "Meldingen",

    "push/error": "Pushnotificatie error.",
    "push/not_granted": "Pushnotificaties zijn niet ingeschakeld.",

    "reporting/error/no_description": "Geef een omschrijving op.",
    "reporting/error/no_reason": "Selecteer een reden.",
    "reporting/report": "Melden",
    "reporting/summary/cancel": "Annulering zonder goede reden",
    "reporting/summary/children": "Kindermishandeling",
    "reporting/summary/dangerous": "Schadelijk of gevaarlijke inhoud",
    "reporting/summary/hateful": "Beledigende inhoud",
    "reporting/summary/identity": "Identiteitsfraude",
    "reporting/summary/no_show": "Niet op komen dagen zonder goede reden",
    "reporting/summary/sexual": "Seksuele inhoud",
    "reporting/summary/spam": "Spam of misleiding",
    "reporting/summary/violence": "Gewelddadige inhoud",
    "reporting/suspended": "Geschorst",

    "tips/activity_info/add_member": "Klik op deze knop om een vriend toe te voegen aan de activiteit. Als je een gast bent, worden ze uitgenodigd zodat de organisator ze kan toevoegen.",
    "tips/activity_info/cancel_or_leave": "Je kunt hier een activiteit annuleren of verlaten. Zorg ervoor dat je dit beleefd afhandelt met de andere deelnemers.",
    "tips/activity_info/chat": "Klik op deze knop om deel te nemen aan de chat van deze activiteit.",
    "tips/discover/accept": "Klik op deze knop wanneer je er zeker van bent dat je wilt deelnemen. Je wordt toegevoegd aan de chat en de andere deelnemers ontvangen een melding.",
    "tips/discover/decline": "Klik op deze knop wanneer je niet wilt deelnemen. Deze activiteit zal niet meer aan je getoond worden.",
    "tips/discover/free/explanation": "Is deze activiteit gratis?",
    "tips/discover/friends/explanation": "Hoeveel vrienden mag je meenemen?",
    "tips/discover/gender": "Doelgroep",
    "tips/discover/gender/explanation": "Voor wie is deze activiteit bedoeld?",
    "tips/discover/known_users": "Je kunt zien welke deelnemers je al eerder hebt ontmoet. Vrienden zijn omringd door een oranje rand.",
    "tips/discover/labels": "Activiteiten kunnen labels hebben, zoals:",

    "users/accept_friendship": "Accepteer Vriendschap",
    "users/accept_declined_friendship": "Accepteer Afgewezen Vriendschap",
    "users/add_friend_description": `
        Deel deze link met anderen, zodat ze je een vriendschapsverzoek kunnen sturen.
        Als ze nog geen account hebben, dan wordt hen gevraagd om zich te registreren.
        Je kunt deze verzoeken dan vinden op de 'Ontvangen' verzoeken pagina.`,
    "users/address": "Adres:",
    "users/address_info": "Het onderstaande adres kan gebruikt worden om activiteiten in de buurt te vinden. Je kunt deze later bijwerken om activiteiten in specifieke locaties te ontdekken, bijvoorbeeld wanneer je op vakantie bent.",
    "users/block": "Blokkeren",
    "users/block_confirm": "Weet je zeker dat je deze gebruiker wilt blokkeren? Privéberichten worden geblokkeerd en jullie kunnen niet meer deelnemen aan activiteiten georganiseerd door de ander.",
    "users/blocked": "Geblokkeerd",
    "users/blocked_users": "Geblokkeerde Gebruikers",
    "users/city": "Woonplaats:",
    "users/copy_link": "Kopieer Link",
    "users/could_not_load_user": "Kan deze gebruiker niet laden.",
    "users/country": "Land:",
    "users/date_of_birth": "Geboortedatum:",
    "users/decline_friendship": "Wijs Vriendschap Af",
    "users/edit_preferences": "Bewerk Voorkeuren",
    "users/email": "E-mail:",
    "users/first_name": "Voornaam:",
    "users/female": "Vrouw",
    "users/friend_list_updated": "Je vriendenlijst is bijgewerkt.",
    "users/friend_request_sent": "Vriendschapsverzoek verzonden",
    "users/friends": "Vrienden",
    "users/gender": "Geslacht:",
    "users/gender_preference": "Geslachtsvoorkeur:",
    "users/gender_preference/all": "alles",
    "users/gender_preference/same": "zelfde",
    "users/invite_existing": "Nodig een bestaande vriend uit",
    "users/lang": "Taal:",
    "users/last_name": "Achternaam:",
    "users/link_copied": "Link is gekopieerd.",
    "users/male": "Man",
    "users/max_age_diff": "Maximaal leeftijdsverschil met organisator:",
    "users/meet_new": "Ontmoet nieuwe mensen",
    "users/no_image_selected": "Selecteer eerst een afbeelding.",
    "users/phone": "Telefoon:",
    "users/personal_info": "Persoonlijke Info",
    "users/preferences": "Voorkeuren",
    "users/profile": "Profiel",
    "users/profile_picture": "Profielfoto",
    "users/profile_picture/description": `
        Zorg ervoor dat je gezicht goed zichtbaar is.
        Deze afbeelding is voor iedereen zichtbaar en wordt voornamelijk gebruikt zodat deelnemers aan activiteiten elkaar gemakkelijk kunnen herkennen.`,
    "users/received_requests": "Ontvangen",
    "users/received_requests/nothing_found": "Geen nieuwe vriendschapsverzoeken.",
    "users/remove_account": "Deactiveer Account",
    "users/remove_account/confirmation": "Je account is gedeactiveerd.",
    "users/remove_account/description": `
        <p>Wat jammer dat je bij ons weg wilt gaan.</p>
        <p>
            Je account zal worden gedeactiveerd waardoor je deze nog kunt heractiveren door contact met ons op te nemen.
            Na 90 dagen zal je account permanent worden verwijderd.
            Als je account is gerapporteerd of geschorst, dan kan dit langer duren.
        </p>`,
    "users/search_radius": "Zoekafstand (km):",
    "users/send_friend_request": "Verzend Vriendschapsverzoek",
    "users/sent_requests": "Verzonden",
    "users/share": "Delen",
    "users/share_friend_link": "Deel Vriendenlink",
    "users/share_message/dialog_title": "Deel Wuddy's Vriendenlink.",
    "users/share_message/text": "Je kunt deze link gebruiken om mij een Wuddy's vriendschapsverzoek te sturen.",
    "users/share_message/title": "%s heeft je een Wuddy's vriendschapsverzoek verstuurd.",
    "users/unblock": "Deblokkeren",
    "users/unfriend": "Ontvrienden",
    "users/unfriend_confirm": "Weet je zeker dat je deze persoon wilt ontvrienden?",
    "users/verification_error": "We kunnen je account niet verifiëren. Neem contact op.",
};
