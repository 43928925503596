import { _t } from "../localization";

export class Pagination
{
    element: HTMLElement;
    pages = new Array<HTMLElement>;

    constructor()
    {
        this.element = document.createElement('div');
        this.element.className = 'pages';
        this.element.style.display = 'flex';
        this.element.style.flexDirection = 'column';

        this._body_element = document.createElement('div');
        this._body_element.className = 'content';
        this._body_element.style.flexShrink = '1';
        this.element.appendChild(this._body_element);

        const pagination_element = document.createElement('div');
        pagination_element.className = 'navigation';
        pagination_element.style.flexShrink = '0';
        pagination_element.style.marginTop = '20px';
        pagination_element.style.textAlign = 'center';
        this.element.appendChild(pagination_element);

        this._previous_button = document.createElement('button');
        this._previous_button.innerHTML = '<';
        this._previous_button.style.display = 'inline-block';
        this._previous_button.style.width = '50px';
        this._previous_button.addEventListener('click', () => this.go_to_previous());
        pagination_element.appendChild(this._previous_button);

        this._page_numbering = document.createElement('div');
        this._page_numbering.style.display = 'inline-block';
        this._page_numbering.style.margin = '0 20px';
        pagination_element.appendChild(this._page_numbering);

        this._next_button = document.createElement('button');
        this._next_button.className = 'primary';
        this._next_button.innerHTML = '>';
        this._next_button.style.display = 'inline-block';
        this._next_button.style.width = '50px';
        this._next_button.addEventListener('click', () => this.go_to_next());
        pagination_element.appendChild(this._next_button);
    }

    add_page(content: HTMLElement)
    {
        const page = document.createElement('div');
        page.className = 'page';
        page.style.flexGrow = '1';
        page.appendChild(content);
        this.pages.push(page);
    }

    go_to_next()
    {
        this._current_page++;
        this.open();
    }

    go_to_previous()
    {
        this._current_page--;
        this.open();
    }

    open()
    {
        this._body_element.innerHTML = '';
        this._body_element.appendChild(this.pages[this._current_page - 1]);

        if( this.pages.length < 2 ){
            this._previous_button.style.visibility = 'hidden';
            this._next_button.style.visibility = 'hidden' ;
        }else{
            this._previous_button.style.visibility = this._current_page === 1 ? 'hidden' : 'visible';
            this._page_numbering.innerHTML = this._current_page + ' / ' + this.pages.length;
            this._next_button.style.visibility = this._current_page === this.pages.length ? 'hidden' : 'visible';
        }
    }

    _body_element: HTMLDivElement;
    _current_page = 1;
    _next_button: HTMLButtonElement;
    _page_numbering: HTMLDivElement;
    _previous_button: HTMLButtonElement;
}