import globals from "../../.env";
import { _t } from "../../localization";
import { Pagination } from "../pagination";
import { Popup } from "./popup";

export class Activity_Discovery_Tips_Popup extends Popup
{
    constructor()
    {
        super('discovery_tips', { title: _t('general/info') });

        const page_1 = document.createElement('div');
        page_1.className = 'text-center';
        page_1.innerHTML = `<img src="/assets/images/accept.png"><p>${_t('tips/discover/accept')}</p>`;
        this._pagination.add_page(page_1);

        const page_2 = document.createElement('div');
        page_2.className = 'text-center';
        page_2.innerHTML = `<img src="/assets/images/decline.png"><p>${_t('tips/discover/decline')}</p>`;
        this._pagination.add_page(page_2);

        const page_3 = document.createElement('div');
        page_3.className = 'text-center';
        page_3.innerHTML = `
            <p>
                <img class="framed-image round" src="/assets/images/faces/5.jpg" style="margin-right: 40px">
                <img class="framed-image round friend" src="/assets/images/faces/6.jpg">
            </p>
            <p>
                ${_t('tips/discover/known_users')}
            </p>`;
        this._pagination.add_page(page_3);

        const page_4 = document.createElement('div');
        page_4.innerHTML = `
                <p style="margin: 10px 0">${_t('tips/discover/labels')}</p>
                <span class="activity-label free-label" style="display: inline-block; font-size: 0.7em; width: 150px">${_t('activities/free')}</span>
                <p style="margin: 10px 0">${_t('tips/discover/free/explanation')}</p>
                <span class="activity-label gender-label" style="display: inline-block; font-size: 0.7em; width: 150px;">${_t('tips/discover/gender')}</span>
                <p style="margin: 10px 0">${_t('tips/discover/gender/explanation')}</p>
                <span class="activity-label friends-label" style="display: inline-block; font-size: 0.7em; width: 150px;">${_t('activities/label/one_friend')}</span>
                <p style="margin: 10px 0">${_t('tips/discover/friends/explanation')}</p>`;
        this._pagination.add_page(page_4);

        this._body.append(this._pagination.element);
    }

    open()
    {
        if( !globals.FORCE_TIPS && localStorage.getItem('is_shown/' + this.name) )
            return;
        localStorage.setItem('is_shown/' + this.name, 'true');

        super.open();

        this._pagination.open();
    }

    private _pagination = new Pagination;
}
